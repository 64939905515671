import React, { useState } from "react";
import { Link } from "react-router-dom";


function RecentPost ({allPost}){
    const[products,setProducts] = useState([])

    return (
      <div style={{marginTop:'10px'}}>
        <ul>
          {allPost.map((product, key) => {
            return (
              <li key={key}>
                <h6>
                  <Link
                    state={{ product, allPost: allPost }}
                    to={{ pathname: `/blog/${product.slug}` }}
                  >
                    {product.title.rendered}
                  </Link>
                </h6>
              </li>
            );
          })}
        </ul>
      </div>
    );
  

  
}

export default RecentPost;
