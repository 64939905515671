import React from "react";
import ContactForm from "../components/ContactForm";

import ServicesCardBottom from "../components/ServicesCardBottom";
import MetaTags from "../components/MetaTags";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";
const Title = "Accessibility Testing";
const Description = "Accessibility Testing";
function AccessibilityTesting() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Accessibility Testing</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/services/software-testing-services">Services</a>
                  </li>
                  <li>Accessibility Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="choose-area-two  pt-5 pb-5">
        <div className="container">
          <div className="overview-box">
            <div className="overview-content p-0">
              <div className="content p-0">
                <h2>
                  {" "}
                  By offering comprehensive accessibility testing services, we
                  can guarantee equal access for everyone{" "}
                </h2>
                <p>
                  TestDel provides a suite of accessibility testing services led
                  by experts to help brands identify and address issues in their
                  digital experiences. We validate digital properties against
                  WCAG checkpoints and provide recommendations for inclusive
                  design. TestDel also provides access to people with
                  disabilities to test experiences. Accessibility is a societal
                  responsibility, and TestDel can help ensure inclusivity for
                  people with visual, hearing, cognitive, and motor limitations,
                  which can improve brand image and user satisfaction.
                </p>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Expert assessments
                  </h4>
                  <p>
                    Our experienced accessibility experts assess your digital
                    content against accessibility guidelines, prioritising
                    issues and offering recommendations.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Bug verification
                  </h4>
                  <p>
                    Our team ensures that identified issues are fixed and
                    validates that they conform to accessibility standards.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Accessibility training
                  </h4>
                  <p>
                    We provide customised training to educate teams on the
                    importance of accessibility and how to integrate best
                    practices into regular operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img
                  src={`assets/img/services/accessibility-testing.jpeg`}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services-area ptb-100 bg-F4F7FC">
        <div class="container">
          <div class="section-title">
            <h2>Our Accessibility Testing Services </h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon">
                  <i class="fa fa-code-branch"></i>
                </div>
                <h3>
                  <a href="#">Structured Test Scripts</a>
                </h3>
                <p>
                  At TestDel, we customize our test scripts according to your
                  compliance requirements and the genuine accessibility needs of
                  people with disabilities. We ensure that our test scripts
                  cover every parameter set out in the guidelines to provide a
                  comprehensive accessibility testing service. Our tailored
                  approach ensures that all accessibility issues are identified
                  and addressed.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-00aeff">
                  <i class="fa fa-universal-access"></i>
                </div>
                <h3>
                  <a href="#">
                    Comprehensive accessibility testing and guidance{" "}
                  </a>
                </h3>
                <p>
                  TestDel offers a comprehensive testing service for websites,
                  mobile/desktop apps, and documentation. We conduct a thorough
                  analysis of your products, provide recommendations for
                  improvement, and carry out remediation to ensure compliance
                  with WCAG 2.1, ADA, and 508 standards. Our services result in
                  a significantly improved user experience for all users,
                  including those with disabilities.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-f78acb">
                  <i class="fa fa-globe"></i>
                </div>
                <h3>
                  <a href="#">Website Overview </a>
                </h3>
                <p>
                  At TestDel, our subject matter experts provide web
                  descriptions services to ensure all page elements are
                  accessible. We write optimized alt texts, long descriptions,
                  video captions, and more to make sure that users with
                  disabilities can access and understand the content on your
                  website. With our web descriptions service, you can improve
                  the accessibility of your website and ensure that it provides
                  a positive user experience for everyone.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-cdf1d8">
                  <i class="flaticon-research"></i>
                </div>
                <h3>
                  <a href="#">Emerging Tools and Techniques</a>
                </h3>
                <p>
                  TestDel employs innovative accessibility testing tools and
                  methods to ensure compliance with accessibility guidelines.
                  Our shift-left testing approach ensures early detection and
                  resolution of accessibility issues. Our accessibility testing
                  experts are proficient in handling the best tools in the
                  market, such as A-Checker, Access Color, and Functional
                  Accessibility Evaluator, to name a few.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-c679e3">
                  <i class="fa fa-code-branch"></i>
                </div>
                <h3>
                  <a href="#">Hybrid Approach to End-to-End Testing</a>
                </h3>
                <p>
                  We offers end-to-end testing using a hybrid approach,
                  combining automated and manual functional testing to ensure
                  comprehensive coverage. Our dedicated team of experts provides
                  detailed monitoring from development to deployment to ensure
                  that any accessibility issues are identified and resolved
                  promptly.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fa fa-table"></i>
                </div>
                <h3>
                  <a href="#">Extensive Reports for All Build </a>
                </h3>
                <p>
                  We provides detailed reports for each build, identifying areas
                  of compliance and non-compliance with accessibility
                  guidelines. Our reports offer comprehensive information to
                  help you understand the accessibility issues and prioritize
                  remediation efforts. Our remediation assistance focuses on
                  prioritizing the most critical concerns, ensuring that your
                  digital products are accessible to all users.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fa fa-users"></i>
                </div>
                <h3>
                  <a href="#">Joint Testing Methodology </a>
                </h3>
                <p>
                  At TestDel, we use a joint testing approach to provide a
                  comprehensive accessibility testing service. Our integrated
                  team consists of certified accessibility engineers, including
                  both sighted and non-sighted individuals. They cover all
                  aspects of testing to ensure a real-life experience that meets
                  expectations for all users, including those with visual
                  impairments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-facts-area  pt-5 pb-5">
        <div className="container">
          <div className="section-title">
            <h2 className="mb-3">Benefits of Accessibility Testing</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <div className="icon">
                    <img
                      src={`assets/img/services-icon/Benefits-of-Desktop-Applications--Meets-Commitment.png`}
                    />
                  </div>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Improves user experience </a>
                  </h3>
                  <p>
                    Accessibility testing ensures that your digital products are
                    accessible to all users, including those with disabilities.
                    This improves the overall user experience, increasing user
                    satisfaction and engagement.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
                <div className="col-md-1">
                  <div className="icon">
                    <img
                      src={`assets/img/services-icon/Benefits-of-Desktop-Applications-Guaranteed-Security.png`}
                    />
                  </div>
                </div>

                <div className="col-md-11">
                  <h3>
                    <a href="#">Enhances brand reputation </a>
                  </h3>
                  <p>
                    By demonstrating a commitment to accessibility, you enhance
                    your brand reputation as a socially responsible
                    organization. This can attract new customers and retain
                    existing ones who value inclusivity.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
                <div className="col-md-1">
                  <div className="icon">
                    <img
                      src={`assets/img/services-icon/Benefits-of-Desktop-Applications--Unwavering-Stability.png`}
                    />
                  </div>
                </div>

                <div className="col-md-11">
                  <h3>
                    <a href="#">Reduces legal risks </a>
                  </h3>
                  <p>
                    Many countries have laws and regulations that require
                    digital products to be accessible to people with
                    disabilities. Accessibility testing helps ensure compliance
                    with these laws, reducing the risk of legal action and
                    associated costs.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
                <div className="col-md-1">
                  <div className="icon">
                    <img
                      src={`assets/img/services-icon/Benefits-of-Desktop-Applications--Audience-Reached.png`}
                    />
                  </div>
                </div>

                <div className="col-md-11">
                  <h3>
                    <a href="#">Increases market reach </a>
                  </h3>
                  <p>
                    Knowing who your potential audience is crucial to develop a
                    high-grade product. When TestDel does testing, the end user
                    gets the maximum value and pleasure from the solution.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServicesCardBottom />
      <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default AccessibilityTesting;
