import React from "react";
import { getHostUrl } from "../config";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaTags = (props) => {
  return (
    <Helmet>
      {/* HTML Meta Tags  */}
      <title>{props.title} </title>
      <meta name="title" content={props.title} data-react-helmet="true" />

      <meta
        name="description"
        content={props.description}
        data-react-helmet="true"
      />

      {/* Facebook Meta Tags */}
      <meta
        property="og:url"
        content={getHostUrl("")}
        data-react-helmet="true"
      />
      <meta property="og:type" content="website" data-react-helmet="true" />
      <meta
        property="og:title"
        content={props.title}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content="https://testdel.com/assets/img/company-logo/testdel_logo.png?w=1200&h=630&scale.option=fit"
        data-react-helmet="true"
      />
      <meta property="og:image:type" content="image/png" data-react-helmet="true"/>
      <meta property="og:image:width" content="300" data-react-helmet="true"/>
      <meta property="og:image:height" content="300" data-react-helmet="true"/>


      {/* Twitter Meta Tags  */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        data-react-helmet="true"
      />
      <meta
        property="twitter:domain"
        content={window.location.hostname}
        data-react-helmet="true"
      />
      <meta
        property="twitter:url"
        content={getHostUrl("")}
        data-react-helmet="true"
      />
      <meta
        name="twitter:title"
        content={props.title}
        data-react-helmet="true"
      />
      <meta
        name="twitter:description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta
        name="twitter:image"
        content="https://testdel.com/assets/img/company-logo/testdel_logo.png?w=1200&h=630&scale.option=fit"
        data-react-helmet="true"
      />
      
      
    </Helmet>
  );
};

export default MetaTags;
