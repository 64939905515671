import React from "react";
import Counter from "../components/Counter";
import Team from "../components/Team";
import Partners from "../components/Partners";
import MetaTags from "../components/MetaTags";
import { Link } from "react-router-dom";
const Title = "Technologies";
const Description =
  "Testdel.com customizes solutions for each project, optimizing quality assessment while minimizing risks and enhancing efficiency. Unique approaches for unique projects.";

const Technologies = () => {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Technologies</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Technologies</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      {/* About Us */}
      <section className="choose-area-two">
        <div className="container-fluid">
          <div className="row" Style="align-items:center">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/technologies.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div
                  className="section-title text-left"
                  Style="margin: 0 0 30px"
                >
                  <h2 className="text-left">Technologies</h2>
                </div>
                <p Style="max-width:600px">
                  In order to provide effective and detailed software testing,
                  TestDel utilizes a diverse array of tools and technologies to
                  enhance QA team productivity, simplify activity management,
                  and detect system issues. Tailoring our tool selection to the
                  specific features of the product, customer requirements, and
                  project goals, we optimize processes to ensure a seamless
                  workflow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5 gray-bg">
        <div className="container">
          <h2 className="text-center" Style="padding-bottom:20px">
            TestDel Management Tools
          </h2>
          <div Style="display:flex;flex-wrap:wrap;justify-content:space-between; withe:100%; padding-bottom: 2rem">
            <div Style="max-width:500px; width:100%">
              <h3>TestDel - Bug Tracking Tools</h3>
              <p>
                In order to ensure the successful delivery of your application
                defect-free, it is crucial to have a test lab setup that can
                replicate various hardware and software combinations used by
                potential software users.
              </p>
              <p>
                Mantis, Jira, BugZilla, Pivotal Tracker, Team Foundation Server,
                Redmine, BugHerd Podio, Weblssues, BugNET, The Bug Genie,
                Bugify, Dmn Bugs, Usernap, Webseam, TicTrack, BugMuncher,
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Mantis"
                >
                  ...Read more <span />
                  <span />
                </a>
                <div class="collapse" id="Mantis">
                  Bugrocket, BugShooting, BigCam, Boto - Streamlined Issue
                  Tracking, Snowy Evening FogBugz, Unfuddle, Gemini Tracker,
                  Trac, Lighthouse, 16bugs, DoneDone, Sifter, Goplan, Bontq,
                  Mojo Helpdesk, Track Jumper, Fixx, Code Spaces, YouTrack
                </div>
              </p>
            </div>
            <div Style="max-width:500px; width:100%">
              <h3>Test Management Tools</h3>
              <p>
                At TestDel, we leverage cutting-edge and innovative test
                planning tools to meticulously carry out the software testing
                process. Our dedicated team is committed to delivering top-notch
                quality products by utilizing the latest industry techniques and
                methodologies.
              </p>
              <p>
                TestLink, MS Project, Confluence, Mediawiki, Assembla, TestRail,
                Microsoft Test Manager, Zephyr, Microsoft Excel, Zoho CRM
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="overview-area pt-5 pb-5">
        <div className="container">
          <h2 className="text-center" Style="padding-bottom:20px">
            TestDel Testing tools
          </h2>
          <div Style="display:flex; flex-wrap:wrap; justify-content:space-between;">
            <ul Style="max-width:550px; 
            width:100%;">
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">
                    Test Automation Tools And Frameworks
                  </h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Automation"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Automation">
                  TestFlight, iFunBox, Xcode, iTunes, DroidExplorer, Android
                  SDK, ES Task Manager, Fake GPS, Perfecto Mobile ,
                  DeviceAnywhere, Reflector, BlackBerry Simulator, HockeyApp,
                  iExplorer, iPhone Configuration Utility, System Console, SYS
                  Activity Manager, Windows Phone Developer Tools, iPad Peek,
                  Marlin Mobile, TestObject, Testdroid, Mobitaz, BirdFlight,
                  Crashlytics, AppThwack, MonkeyTalk, Xamarin Test Cloud, Adobe
                  Edge Inspect, Jamo Solutions, The Beta Family, Scirocco Cloud,
                  Crittercism, Zubhium, Giqwalk, Test Studio for iOS, Mobile
                  Website Readiness Test, Gomez Cross-Device Website, mobiReady,
                  Google Mobilizer, iPhoney, Opera Mini Simulator, W3C mobileOK
                  Checker, dotMobi, Keynote, Vysor
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Database Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Database"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Database">
                  Navicat, SQL Server
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">API Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#API"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="API">
                  SoapUI, PostMan in GChrome
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Compatibility Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Compatibility"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Compatibility">
                  Spoonium
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Communications Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Communications"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Communications">
                  F-Secure SSH Client, Google Voice, Slack, Trello
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Design Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Design"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Design">
                  Snagit, Photoshop
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Link And HTML Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Link"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Link">
                  Xenu's Link Sleuth, A1 Website Analyzer, Qualidator, SortSite,
                  LinkAlarm, LinkScan, W3C Link Checker, REL Link Checker Lite,
                  DeppTrawl, Audit.Blossom, Link Checker Pro, linkTiger, Web
                  link Validator, 2bone Free Online Link Checker, Inspyder
                  InSite
                </p>
              </li>
            </ul>
            <ul Style="max-width:550px; 
            width:100%;">
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Mobile Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Mobile"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Mobile">
                  TestFlight, iFunBox, Xcode, iTunes, DroidExplorer, Android
                  SDK, ES Task Manager, Fake GPS, Perfecto Mobile,
                  DeviceAnywhere, Reflector, BlackBerry Simulator, HockeyApp,
                  iExplorer, iPhone Configuration Utility, System Console, SYS
                  Activity Manager, Windows Phone Developer Tools, iPad Peek,
                  Marlin Mobile, TestObject, Testdroid, Mobitaz, BirdFlight,
                  Crashlytics, AppThwack, MonkeyTalk, Xamarin Test Cloud, Adobe
                  Edge Inspect, Jamo Solutions, The Beta Family, Scirocco Cloud,
                  Crittercism, Zubhium, Gigwalk, Test Studio for iOS, Mobile
                  Website Readiness Test, Gomez Cross-Device Website, mobiReady,
                  Google Mobilizer, iPhoney, Opera Mini Simulator, W3C mobileOK
                  Checker, dotMobi, Keynote, Vysor
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Security Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Security"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Security">
                  Tamper Data, HackBar, Add N Edit Cookies, Modify Headers,
                  ShowIP, Acunetix Web vulnerability Scanner, Paros, Nessus,
                  MustLive Remote Shell, SQL Shell, Metasploit Framework,
                  SSLstrip, w3af, Outpost Firewall Pro, Netsparker, ZAP, Nikto
                  Web Scanner, Vega Vulnerability Scanner, Wireshark,
                  Interceptor-NG, Sucuri Security, WebsiteDefender, Golem
                  Technologies, SiteLock, Comodo Sitelnspector, Websecurify,
                  Qualys FreeScan
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Utility Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Utility"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Utility">
                  VPN Client, Proxifier, TeamViewer 8, PuTTY, Fiddler Web
                  Debugger, DU Meter, ProcDump + PSTools, ADExplorer, ADRestore,
                  Firebug, Jing, Arpspoof, OpenVPN Connect, Google Firebase,
                  GitLab
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Useful Tools For Testing</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Useful"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Useful">
                  Axure RP, Ace Project, Gns3
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Virtualization Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Virtualization"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Virtualization">
                  VMware Workstation, VirtualBox
                </p>
              </li>
              <li className="technologies">
                <div Style="display: flex; align-items: center; justify-content:space-between">
                  {" "}
                  <h6 Style="font-size:20px">Performance Testing Tools</h6>
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Performance"
                  >
                    <i className="fas fa-plus"></i>
                    <span />
                  </a>
                </div>
                <p class="collapse" id="Performance">
                  Mercury LoadRunner, Wapt, Grinder, MS Web Application Stress
                  Tool, Pylot, JMeter
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 gray-bg">
        <div className="container">
          <h2 className="text-center" Style="padding-bottom:20px">
            TestDel Management Tools
          </h2>
          <p Style="text-align:center">
            By possessing a diverse infrastructure of various OS types and
            versions, TestDel offers extensive test coverage to ensure effective
            testing. We prioritize and select OS verification based on the
            specifics of the target market and the location of end-users of the
            software under test.
          </p>
          <div Style="display:flex;flex-wrap:wrap;justify-content:space-between; withe:100%; padding: 2rem 0">
            <div Style="max-width:500px; width:100%">
              <h3>Operation Systems</h3>
              <p>
                ios, Mac OS, Windows OS, Linux OS, BSD OS, Android, Windows
                Mobile, Palm OS, Multi-platform
              </p>
            </div>
            <div Style="max-width:500px; width:100%">
              <h3>Mobile OS</h3>
              <p>iOS, Android, Windows Mobile</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Technologies;
