import React from 'react';

import ContactForm from '../components/ContactForm';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Security Testing Services in UK";
const Description="Our security testing service enables performance testing across operating systems, network, application and database.";
function SecurityTesting() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
        {/* Start Page Title Area */}
        <div className="page-title-area page-title-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Security Testing</h1>
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li> <a href="/what-we-offer">What We Offer</a></li>
                  <li>Security Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
     </div>
      {/* End Page Title Area */}
      {/* Start Why Choose Us Area */}
      <section className="why-choose-area pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                 <h3>We identify software security holes and vulnerabilities  </h3>
                <p>
                TestDel offers customer security testing services for systems and applications to protect organisational data and personal information, and to ensure a satisfactory security system standard. </p>
                <div className="features-text mt-3">
                  <p>
                    <i className="flaticon-tick" /> Check the protection of your application against existing real-world attacks using various manual or automated techniques.</p>
                </div>
                <div className="features-text mt-2">
                  <p>
                    <i className="flaticon-tick" /> Expose protection architecture flaws in your application. </p>
                </div>
                <div className="features-text mt-2">
                  <p>
                    <i className="flaticon-tick" /> Identify vulnerabilities caused by implementation errors. </p>
                </div>
                <div className="features-text mt-2">
                  <p>
                    <i className="flaticon-tick mt-2" /> Reveal shortcomings resulting from the application's partnership with the rest of the IT infrastructure.</p>
                </div>
                <div className="features-text mt-2">
                  <p>
                    <i className="flaticon-tick" /> Establish the trust of end users with greater overall application protection. </p>
                </div>
              
              
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
                <img src={`assets/img/what-we-offer/security-img.png`} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-area pt-5 pb-5 gray-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
              <img src={`assets/img/what-we-offer/Security-Testing-TestDel--Help-you-mitigate-risk-process.png`}/>
          
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                <span className="sub-title">HOW WE TEST </span>
                <h3>TestDel Help you mitigate risk </h3>
                <p>We provide our customers with detailed information about security testing processes and problems found along with their reproduction measures. We may also provide recommendations about how to make the device secure and prevent future risks, based on our expertise in security research.</p>
              </div>
   
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
    <div className="container text-center">
      <h2>Tools used by our team</h2>
      <img src={`assets/img/what-we-offer/security-testing.png`}/>
      </div>     
      </section>

      {/* End Why Choose Us Area */}
           </div>
  );
}

export default SecurityTesting;
