import React from 'react';
import Axios from 'axios';
import {  findIndexByKeyValue, ValidateEmail } from '../utils/functions';
import { getHostUrl } from '../config';
import Select from 'react-select';
import countries from '../components/Countries.json'

class CareerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      number:'',
      file:'',
      countryName: '',
      countryCode: '',
      isSubmitted: false,
      errors: {
        first: '',
        last: '',
        number:'',
        org: '',
        email: '',
      },
      selectedOption: null,

    };
  }

  getGeoInfo = () => {
    Axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        let key = findIndexByKeyValue(countries,'code',data.country_code)

        this.setState({
            countryName: data.country_name,
            countryCode: data.country_calling_code,
            selectedOption:key
        });
    }).catch((error) => {
        console.log(error);
    });
};

  componentDidMount() {
    this.getGeoInfo();
  }
  onFNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.first = '';
      this.setState({ firstname: event.target.value, errors: errors });
    } else {
      errors.first = 'Enter Only Character';
      this.setState({ errors });
    }
  }

  onLNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.last = '';
      this.setState({ lastname: event.target.value, errors: errors });
    } else {
      errors.last = 'Enter Only Character';
      this.setState({ errors });
    }
  }

  onEmailChange(event) {
    let errors = this.state.errors;
    errors.email = ValidateEmail(event.target.value)
      ? ''
      : 'Please enter valid email address';
    this.setState({
      email: event.target.value,
      errors,
    });
  }


  onNumberChange(event) {
    const re = /^[0-9\b]+$/;
    let errors = this.state.errors;
    if (event.target.value === '') {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    }
    if (event.target.value === '' || re.test(event.target.value)) {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    } else {
      errors.number = 'Enter Only Integer Number';
      this.setState({ errors });
    }

    if (event.target.value.length > 0) {
      if (event.target.value.length >= 10) {
        errors.number = '';
        this.setState({ errors });
        this.setState({ number: event.target.value, errors: errors });
      } else {
        errors.number = 'Enter Min 10 Digit Number';
        this.setState({ errors: errors });
      }
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ 
      selectedOption:selectedOption,
      countryCode:selectedOption.code,
      countryName:selectedOption.name
     })
  }

  onMsgChange(event) {
    let errors = this.state.errors;
    if (event.target.value) {
      errors.msg = '';
      this.setState({ msg: event.target.value, errors: errors });
    } else {
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({ errors: errors, msg: event.target.value });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      number: '',
      msg: '',
      file: '',
      errors: {
        first: '',
        last: '',
        number: '',
        org: '',
        msg: '',
      },
    });
  }

  validateForm = () => {
    let valid = true;
    let errors = this.state.errors;
    if (!this.state.firstname) {
      valid = false;
      errors.first = 'First name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.lastname) {
      valid = false;
      errors.last = 'Last name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.email) {
      valid = false;
      errors.email = 'Email is mandatory';
      this.setState({
        errors,
      });
    } else {
      if (!ValidateEmail(this.state.email)) {
        valid = false;
        errors.email = 'Please enter valid email address';
        this.setState({
          errors,
        });
      }
    }
    return valid;
  };

  onFileChange(event) {
    let errors = this.state.errors;
    if(event.target.files[0].type=='application/pdf' || event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
      if(event.target.files[0].size<=4083842){
        this.setState({file: event.target.files[0]})
        errors.file = '';
        this.setState({
         errors
       });
      }else{
        document.getElementById('filesId').value = "";
       errors.file = 'Maximum file size should be 4mb';
       this.setState({
         errors,
         file:''
       });
      }
   }else{
    document.getElementById('filesId').value = "";
    errors.file = 'Allow only PDF and DOC File Allowed';
    this.setState({
      errors,
      file:''
    });
   }
 }

  handleSubmit(e) {
    if (this.validateForm()) {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('firstname', this.state.firstname);
      formData.append('lastname', this.state.lastname);
      formData.append('email', this.state.email);
      formData.append('countryname', this.state.countryName);
      formData.append('countrycode', this.state.countryCode);
      formData.append("number", this.state.number);

      let country = localStorage.getItem("country_code");
      formData.append("country", country);


      Axios.post(getHostUrl('/api/user/join-team'), formData, {
      }).then((response)=>{
      console.log(response.success);

      if (response.status ==200){
        this.setState({isSubmitted: true});
          this.resetForm();
      }else{
        alert("Message failed to send.")
      }
      })
    }
  }

  render() {
    return !this.state.isSubmitted ? (
      <div>
        <div className="analysdis-form">
          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mail-text">
                  <span>First name</span>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    className="form-control mailing"
                    required
                    value={this.state.firstname}
                    onChange={this.onFNameChange.bind(this)}
                  />
                  <small className="text-danger">
                    {" "}
                    {this.state.errors.first}
                  </small>
                </div>
                <div className="form-group mail-text">
                  <span>Last name</span>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    className="form-control mailing"
                    required
                    value={this.state.lastname}
                    onChange={this.onLNameChange.bind(this)}
                  />
                  <small className="text-danger">
                    {" "}
                    {this.state.errors.last}
                  </small>
                </div>
                <div className="form-group mail-text">
                  <span>Email</span>
                  <span className="text-danger">*</span>
                  <input
                    type="email"
                    className="form-control mailing"
                    required
                    value={this.state.email}
                    onChange={this.onEmailChange.bind(this)}
                  />
                  <small className="text-danger">
                    {" "}
                    {this.state.errors.email}
                  </small>
                </div>
                <span>Phone Number</span>
                <div className="row country-row">
                  <div className="form-group coutry-picker">
                    <Select
                      value={this.state.selectedOption}
                      placeholder="Country"
                      theme={(theme) => ({
                        ...theme,
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          text: "#9d9fad",
                          primary25: "#9d9fad",
                          primary75: "#9d9fad",
                          primary: "blue",
                          primary50: "#9d9fad",
                        },
                      })}
                      onChange={this.handleChange}
                      getOptionLabel={(data) => data.dial_code}
                      getOptionValue={(data) => data.code}
                      options={countries}
                    ></Select>
                  </div>
                  <div className="form-group phone-picker">
                    <input
                      type="text"
                      inputProps={{ maxLength: 12 }}
                      className="form-control phone-input"
                      value={this.state.number}
                      onChange={this.onNumberChange.bind(this)}
                      placeholder="Phone Number"
                      required
                    />
                    <small className="text-danger">
                      {" "}
                      {this.state.errors.number}
                    </small>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="file"
                    id="filesId"
                    onChange={this.onFileChange.bind(this)}
                  />
                  <br />
                  <small>{"(pdf, docx file types less than 4 MB)"}</small>
                  <br />
                  <small className="text-danger">
                    {" "}
                    {this.state.errors.file}
                  </small>
                </div>
              </div>
            </div>
            <br />
            <div className="form_policy consent-text">
              TestDel does not sell, rent or otherwise make available any
              personal data submitted by visitors to our site to any third
              parties. By submitting this form, I agree to TestDel's{" "}
              <a href="privacy-policy" className="text-primary" target="_blank">
                Privacy Policy
              </a>
              .
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="default-btn company-logo-color col-md-4"
                value="Send"
                onClick={this.handleSubmit.bind(this)}
              >
                Send
                <span />
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>
    ) : (
      <div id="msgSubmit" class="alert alert-success text-center">
        Thanks you're considering a career with TestDel !
        <br/>
        Your contact information has been obtained, and we'll be in touch to arrange a
        meeting.
      </div>
    );
  }

}

export default CareerForm;
