import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import "./home.scss";
import Widget from "../../components/Widget/Widget";
import Featured from "../../components/Featured/Featured";
import Chart from "../../components/Message/Message";
import axios from "axios";
import { getHostUrl } from "../../config";

function Home() {
  const[dashboard,setDashboard] = useState({
    contact_us:'0',
    subscriber:'0',
    quote:'0',
    automation:'0',
  })

  React.useEffect(()=>{
    getDashboard()
  },[])

  function getDashboard (){
    axios.get(getHostUrl('/api/user/getDashboard'),{ // receive two parameter endpoint url ,form data 
    }).then((response)=>{
    console.log(response.success);
    if (response.status ==200 && response.data.success){
      setDashboard(response.data.dashboard)
    }else{
      
    }
  })
  }
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="Contacted" value={dashboard.contact_us}/>
          <Widget type="Subscriber" value={dashboard.subscriber}/>
          <Widget type="Quote" value={dashboard.quote}/>
          <Widget type="Automation" value={dashboard.automation}/>
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div>
      </div>
    </div>
  );
};

export default Home;
