import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'


function ServicesCardBottom() {
 
  
        return (
          <section className="overview-area pt-5 pb-5">
            <div className="container">
              <div className="overview-box it-overview">
                <div className="overview-content">
                  <div className="content">
                    <h2>What can we test?</h2>
                    <ul className="features-list">
                      <li>
                        <Link to="/web-applications-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Web Applications Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/mobile-applications-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Mobile Applications Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/website-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Website Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/desktop-applications-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Desktop Applications
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/accessibility-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Accessibility Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/testing-documentation">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Test Documentation
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cloud-solutions-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Cloud Solutions Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/internet-of-things-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Internet of Things Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/performance-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Performance Testing
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/robotic-process-automation">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Robotic Process Automation (RPA)
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/games-testing">
                          <span>
                            <i className="bx bxs-badge-check" />
                            Games Testing
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="overview-image">
                  <div className="image">
                    <img
                      src={`assets/img/services/it-service1.png`}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
}
 
 
export default ServicesCardBottom;