import React from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState } from "react";

const Datatable = ({col,row}) => {
  const [data, setData] = useState(row);

  const click =(value)=>{
    
    alert(value)
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        let param = params.row
        return (
          <div className="cellAction">
            <Link   to={{ 
            pathname: '/users/test', 
            state: param
          }}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View Message</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <DataGrid
        disableColumnMenu={true}
        getRowId={(value)=>value._id}
        className="datagrid"
        rows={row}
        columns={col.concat(actionColumn)}
        pageSize={8}
        rowsPerPageOptions={[8]}
      />
    </div>
  );
};

export default Datatable;
