import React from "react";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";
import ServicesCardBottom from "../components/ServicesCardBottom";
import Counter from "../components/Counter";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "BusinessModel";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function BusinessModel() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
        {/* Start Main Banner Area */}
        <div className="it-banner pb-5">
        <div className="container-fluid border-bottom pt-5 pb-4">
           <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Business Model</h2>
                <ul><li><a href="/">Home</a></li><li>Business Model</li></ul>
              </div>
            </div>
          </div>
        </div>
           </div>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container mt-50">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content">
                      <h2>Creative testing solutions to improve your Quality!</h2>
                      <p>We organise the workflow according to the business model with a view to ensure perfect cooperation and the creation of suitable customer relationships which will guarantee an improvement in revenue generation and the fulfilment of the project. </p>
                      <div className="banner-btn">
                        <a href="get-a-free-quote" className="default-btn mr-3">
                          Request A Consultation <span />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="it-banner-image owl-carousel owl-theme">
                      <div className="-image">
                        <img src={`assets/img/Creative-Agency.png`} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Shape Images */}
          <div className="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
          <div className="shape-img3"><img src="assets/img/shape/3.svg" alt="image" /></div>
          <div className="shape-img4"><img src={`assets/img/shape/4.png`} alt="image" /></div>
          <div className="shape-img5"><img src={`assets/img/shape/5.png`} alt="image" /></div>
          <div className="shape-img6"><img src={`assets/img/shape/6.png`} alt="image" /></div>
          <div className="shape-img7"><img src={`assets/img/shape/7.png`} alt="image" /></div>
          <div className="shape-img8"><img src={`assets/img/shape/8.png`} alt="image" /></div>
          <div className="shape-img9"><img src={`assets/img/shape/9.png`} alt="image" /></div>
          <div className="shape-img10"><img src={`assets/img/shape/10.png`} alt="image" /></div>
          <div className="shape-img11"><img src={`assets/img/shape/11.png`} alt="image" /></div>
          <div className="shape-img12"><img src={`assets/img/shape/12.png`} alt="image" /></div>
        </div>
        {/* End Main Banner Area */}

        <section className="pricing-area pt-5 -b-5">
        <div className="container" >
        <div className="section-title">
              <h2>Flexible Cooperation Models
</h2>
              <p>Our testing procedures are thorough, targeted and perfectly aligned with the real world demands that will arise as you approach your go-live date.</p>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2">
              <div className="row p-2 bg-white shadow">
            <div className="col-lg-3 col-md-6 col-sm-6 p-0">
              <div className="single-pricing-box">
                  <img src={`assets/img/icons-workflow.png`}/>
          <h3>WorkFlow</h3>
                <ul className="price-features-list">
                  <li className="text-dark"><b> Size of Project</b></li>
                  <li  className="text-dark"><b>Requirements</b></li>
                  <li  className="text-dark"><b> Flexibility</b></li>
                  <li  className="text-dark"><b>client's Control</b></li>
                  <li  className="text-dark"><b>Methodology</b></li>
                  </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 p-0">
              <div className="single-pricing-box red">
              <img src={`assets/img/icons-us-dollar.png`}/>
              <h3>Fixed Price</h3>
                <ul className="price-features-list text-center">
                  <li> small & medium</li>
                  <li> defined</li>
                  <li> - </li>
                  <li> little</li>
                  <li> waterfall</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 p-0 ">
              <div className="single-pricing-box orange">
              <img src={`assets/img/icons-schedule.png`}/>
          <h3>Time Material</h3>
                <ul className="price-features-list text-center">
                  <li> medium & large</li>
                  <li> evolving</li>
                  <li> &#10003; </li>
                  <li> significant</li>
                  <li> agile</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 p-0 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="single-pricing-box orange">
              <img src={`assets/img/icons-crowd.png`}/>
          <h3>Dedicated Team</h3>
                <ul className="price-features-list text-center">
                  <li> large</li>
                  <li> evolving</li>
                  <li> &#10003; </li>
                  <li> full</li>
                  <li> agile</li>
                </ul>
              </div>
            </div>
          </div>
              </div>
            </div>
          
        </div>
        <div className="shape-img2"><img src="assets/img/shape/2.svg" alt="image" /></div>
        <div className="shape-img3"><img src="assets/img/shape/3.svg" alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`} alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`} alt="image" /></div>
        <div className="shape-img6"><img src={`assets/img/shape/6.png`} alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`} alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`} alt="image" /></div>
      </section>
        {/* Start Services Area */}
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="section-title">
              <h2>Engagement Models</h2>
              <p>We ensure high flexibility in cooperation through the following engagement models to ensure our customers arrange effective testing processes. We select the model which would be most appropriate for a particular project based on project details and specified aims.</p>
            </div>
            <div className="row">
            <div className="col-lg-4 col-sm-6">
                <div className="service-card-one gray-bg">
                  <div className="icon">
                    <i className="fa fa-users" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="managed-testing-services">Dedicated Team </a>
                  </h3>
                  <p>We create a partnership based on the corresponding business model for customers seeking a permanent dedicated QA team. through a wide base of resources and facilities as well as qualified QA experts.
                    <p><br/></p>
                    <p><br/></p>
                  </p>
                </div>
              </div>
        
            
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one gray-bg">
                  <div className="icon">
                    <i className="fas fa-dollar-sign" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="qa-consulting">Fixed Price</a>
                  </h3>
                  <p>Based on the available specification, we are prepared to provide QA and testing services within the defined timeframe according to the predetermined and fixed price for a given scope of work. We discuss specifications and additional aspects together with the customer after review of project criteria.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one gray-bg">
                  <div className="icon">
                  <i className="fas fa-clock"/>
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="testing-documentation">Time and Material</a>
                  </h3>
               <p>We recommend the collaboration based on time and material model for long-term projects with changing requirements and varying scope of work. Because of our versatility, we can adapt time and human resources to changing project specifications and requirements.

               <p><br/></p>
               </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Area */}
        <Counter/>
        {/* Start Projects Area */}
        <section className="projects-area ptb-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Case Study</span>
              <h2>We’ve Done Lot’s Of Work, Let’s Check Some From Here</h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="projects-slides owl-carousel owl-theme">
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/1.png`} alt="image" />
                <a href="case-web-application-testing" target="_blank"> 
                <div className="projects-content">
                  <h3>Web application Testing</h3>
                  <span className="category">Application</span>
                </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/2.png`} alt="image" />
                <a href="case-website-testing" target="_blank">
                <div className="projects-content">
                  <h3>Website Testing</h3>
                  <span className="category">Website</span>
                </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/3.png`} alt="image" />
                <a href="case-automated-document-editing-method" target="_blank">
                <div className="projects-content">
                  <h3>Automated document editing method</h3>
                  <span className="category">Automated</span>
                </div>
                </a>
              
              </div>

              <div className="single-projects-box">
                <img src={`assets/img/projects-image/6.png`} alt="image" />
                <a href="case-desktop-application-testing" target="_blank">
                <div className="projects-content">
                  <h3>Desktop Application Testing</h3>
                  <span className="category">Desktop</span>
                </div>
                </a>
               
             
              </div>


              <div className="single-projects-box">
                <img src={`assets/img/projects-image/4.png`} alt="image" />
                <a href="case-mobile-app-testing" target="_blank">
                <div className="projects-content">
                  <h3>Mobile App Testing</h3>
                  <span className="category">Mobile App</span>
                </div>
                </a>
             
              </div>

              <div className="single-projects-box">
                <img src={`assets/img/projects-image/5.png`} alt="image" />
                <a href="case-test-automation" target="_blank">
                <div className="projects-content">
                  <h3>Test Automation</h3>
                  <span className="category">Automation</span>
                </div>
                </a>
              </div>

            </div>
          </div>
        </section>
        {/* End Projects Area */}
            <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default BusinessModel;
