import React from "react";

import MetaTags from "../components/MetaTags";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Case Study";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function CaseStudy() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">TestDel Use Cases</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Use Cases</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      {/* Project area */}
      <div className="works-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>Companies we tested for</h2>
            <p>
            We have been working with some of the largest banking, retail, pharmaceutical and Utility companies since 2015, covering a wide variety of testing and certification services. We are very excited to <strong>share with you some of our case studies</strong>.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work1.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">application</a>
                  </span>
                  <h3>
                    <a target="_blank" href="/case-web-application-testing">
                      Web application Testing
                    </a>
                  </h3>
                  <a className="work-btn" target="_blank" href="/case-web-application-testing">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work2.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">Website</a>
                  </span>
                  <h3>
                    <a target="_blank" href="/case-website-testing">Website Testing</a>
                  </h3>
                  <a className="work-btn" target="_blank" href="/case-website-testing">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work3.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">Automated</a>
                  </span>
                  <h3>
                    <a target="_blank" href="/case-automated-document-editing-method">
                      Automated document editing method
                    </a>
                  </h3>
                  <a
                    className="work-btn"
                    target="_blank"  href="/case-automated-document-editing-method"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work6.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">Desktop</a>
                  </span>
                  <h3>
                    <a href="/case-desktop-application-testing" target="_blank">
                    Desktop Application Testing
                    </a>
                  </h3>
                  <a className="work-btn" target="_blank" href="/case-desktop-application-testing">
                    Read More
                  </a>
                </div>
              </div>
              </div>
              <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work4.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">Mobile App</a>
                  </span>
                  <h3>
                    <a target="_blank" href="/case-mobile-app-testing">
                    Mobile App Testing
                    </a>
                  </h3>
                  <a className="work-btn" target="_blank" href="/case-mobile-app-testing">
                    Read More
                  </a>
                </div>
              </div>
              </div>
              <div className="col-lg-6 col-sm-6">
              <div className="work-card">
                <img src={`assets/img/works/work5.png`} alt="image" />
                <div className="content">
                  <span>
                    <a href="#">Automation</a>
                  </span>
                  <h3>
                    <a href="/case-test-automation" target="_blank">
                    Test Automation
                    </a>
                  </h3>
                  <a className="work-btn" target="_blank" href="/case-test-automation">
                    Read More
                  </a>
                </div>
              </div>
          </div>
        </div>
        </div>
      </div>
      {/* End project area */}
    </div>
  );
}

export default CaseStudy;
