import React, { Component, Fragment } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  Redirect,
} from "react-router-dom";

/*import createHistory from 'history/createBrowserHistory'; */
import "./App.css";
import ScrollIntoView from "./components/ScrollIntoView";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Home";
import AboutUs from "./pages/AboutUs";
import Blogs from "./components/Blogs";
import SinglePost from "./components/SinglePost";
import JoinTeam from "./pages/JoinTeam";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import NotFoundPage from "./components/NotFoundPage";
import RequestQuote from "./components/RequestQuote";

import CloudSolutionsTesting from "./services/CloudSolutionsTesting";
import DesktopApplicationTesting from "./services/DesktopApplicationTesting";
import GamesTesting from "./services/GamesTesting";
import IoTTestingServices from "./services/IoTTestingServices";
import MobileApplicationsTesting from "./services/MobileApplicationsTesting";
import TestingDocumentation from "./whatweoffer/TestingDocumentation";
import WebApplicationsTesting from "./services/WebApplicationsTesting";
import WebsiteTestingService from "./services/WebsiteTestingService";

import QAConsulting from "./whatweoffer/QAConsulting";
import SecurityTesting from "./whatweoffer/SecurityTesting";
import TestEnvironmentManagement from "./whatweoffer/TestEnvironmentManagement";
import TestDataManagement from "./whatweoffer/TestDataManagement";
import FunctionalTesting from "./whatweoffer/FunctionalTesting";
import NonFunctionalTesting from "./whatweoffer/NonFunctionalTesting";
import ManagementTestingServices from "./whatweoffer/ManagementTestingServices";
import OutsourceSoftwareTesting from "./whatweoffer/OutsourceSoftwareTesting";
import CaseStudy from "./pages/CaseStudy";
import CaseWebapplcation from "./cases/WebapplicationTesting";
import CaseWebsiteTesting from "./cases/WebsiteTesting";
import CaseAutomateddocument from "./cases/Automateddocument";
import CaseDesktopApplicationTesting from "./cases/DesktopApplicationTesting";
import CaseTestAutomation from "./cases/TestAutomation";
import CaseMobileAppTesting from "./cases/MobileAppTesting";
import OurLab from "./pages/OurLab";
import WhatWeOffer from "./pages/WhatWeOffer";
import Services from "./pages/Services";
import CookiesPolicy from "./pages/CookiesPolicy";
import BusinessModel from "./pages/BusinessModel";
import BecomeTester from "./pages/lp/BecomeTester";
import Faqs from "./pages/Faqs";
import TestAutomationServices from "./services/TestAutomationServices";
// import LiveChat from './components/LiveChat';
import Login from "./pages/Login";
import AdminHome from "./pages/Home/Home";
import Subscriber from "./pages/Users/Subscriber";
import Single from "./pages/Single/Single";
import ComposeMail from "./pages/Email/Compose";
import Automation from "./pages/Users/Automation";
import Quote from "./pages/Users/Quote";
import Contact from "./pages/Users/Contact_Us";
import RoboticsAutomationServices from "./services/RoboticsAutomationServices";
import DeviceEnquiry from "./pages/Users/Device";
import { isFormatSupported } from "./utils/functions";
import packageJson from "../package.json";
import axios from "axios";
import PerformanceTesting from "./services/PerformanceTesting";
import AccessibilityTesting from "./services/AccessibilityTesting";
import WorkdayTesting from "./pages/WorkdayTesting";
import ServiceNowTesting from "./pages/ServiceNowTesting";
import SapTesting from "./pages/SapTesting";
import EtlTesting from "./pages/EtlTesting";
import CRMERPTesting from "./pages/CRMERPTesting";
import BigDataTesting from "./pages/BigDataTesting";
import BlockchainTesting from "./pages/BlockchainTesting";
import Methodology from "./pages/Methodology";
import Technologies from "./pages/Technologies";
import HowItWork from "./pages/HowItWork";

function App() {
  const [token, setToken] = React.useState("");
  React.useEffect(() => {
    let token = JSON.parse(localStorage.getItem("admin"));
    if (token) {
      setToken(token);
    }
  }, [token]);

  React.useEffect(() => {
    getGeoInfo();
  }, []);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let country_code = response.data.country_code;
        localStorage.setItem("country_code", country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    isFormatSupported()
      .then((res) => {
        localStorage.setItem("avifSupported", "YES");
      })
      .catch((error) => {
        localStorage.setItem("avifSupported", "NO");
      });
  }, []);

  React.useEffect(() => {
    caching();
  }, []);

  const caching = () => {
    let version = localStorage.getItem("version");
    if (version != packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  };

  return !token ? (
    <div className="App">
      <Fragment>
        <BrowserRouter basename={"/"} key={Math.random()}>
          <Header />
          {/* <LiveChat /> */}
          <ScrollIntoView>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/who-we-are" element={<AboutUs />} />
              <Route path="/our-lab" element={<OurLab />} />
              <Route path="/case-study" element={<CaseStudy />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/page/:id" element={<Blogs />} />
              <Route exact path="/blog/:blogPost" element={<SinglePost />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/admin/login" element={<Login />} />
              <Route path="/get-a-free-quote" element={<RequestQuote />} />
              <Route path="/join-the-team" element={<JoinTeam />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/what-we-offer" element={<WhatWeOffer />} />
              <Route
                path="/services/software-testing-services"
                element={<Services />}
              />
              <Route path="/business-model" element={<BusinessModel />} />
              <Route path="/qa-consulting" element={<QAConsulting />} />
              <Route
                path="/test-environment-management"
                element={<TestEnvironmentManagement />}
              />
              <Route
                path="/test-data-management"
                element={<TestDataManagement />}
              />
              <Route
                path="/functional-testing"
                element={<FunctionalTesting />}
              />
              <Route path="/security-testing" element={<SecurityTesting />} />
              <Route
                path="/non-functional-testing"
                element={<NonFunctionalTesting />}
              />
              <Route
                path="/managed-testing-services"
                element={<ManagementTestingServices />}
              />
              <Route
                path="/test-automation-services"
                element={<TestAutomationServices />}
              />
              <Route
                path="/outsource-software-testing"
                element={<OutsourceSoftwareTesting />}
              />
              <Route
                path="/web-applications-testing"
                element={<WebApplicationsTesting />}
              />
              <Route
                path="/accessibility-testing"
                element={<AccessibilityTesting />}
              />
              <Route
                path="/cloud-solutions-testing"
                element={<CloudSolutionsTesting />}
              />
              <Route
                path="/desktop-applications-testing"
                element={<DesktopApplicationTesting />}
              />
              <Route
                path="/robotic-process-automation"
                element={<RoboticsAutomationServices />}
              />
              <Route path="/games-testing" element={<GamesTesting />} />
              <Route
                path="/internet-of-things-testing"
                element={<IoTTestingServices />}
              />
              <Route
                path="/mobile-applications-testing"
                element={<MobileApplicationsTesting />}
              />
              <Route
                path="/testing-documentation"
                element={<TestingDocumentation />}
              />
              <Route
                path="/website-testing"
                element={<WebsiteTestingService />}
              />
              <Route
                path="/case-web-application-testing"
                element={<CaseWebapplcation />}
              />
              <Route
                path="/case-automated-document-editing-method"
                element={<CaseAutomateddocument />}
              />
              <Route
                path="/case-desktop-application-testing"
                element={<CaseDesktopApplicationTesting />}
              />
              <Route
                path="/case-mobile-app-testing"
                element={<CaseMobileAppTesting />}
              />
              <Route
                path="/case-test-automation"
                element={<CaseTestAutomation />}
              />
              <Route
                path="/case-website-testing"
                element={<CaseWebsiteTesting />}
              />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/become-tester" element={<BecomeTester />} />
              <Route path="*" element={<NotFoundPage />} status={404} />
              <Route
                path="/performance-testing"
                element={<PerformanceTesting />}
              />
              <Route path="/big-data-testing" element={<BigDataTesting />} />
              <Route
                path="/blockchain-testing"
                element={<BlockchainTesting />}
              />
              <Route path="/crm-erp-testing" element={<CRMERPTesting />} />
              <Route path="/elt-testing" element={<EtlTesting />} />
              <Route path="/sap-testing" element={<SapTesting />} />
              <Route
                path="/service-now-testing"
                element={<ServiceNowTesting />}
              />
              <Route path="/workday-testing" element={<WorkdayTesting />} />
              <Route path="/technologies" element={<Technologies />} />
              <Route path="/methodology" element={<Methodology />} />
              <Route path="how-it-work" element={<HowItWork/>}/>
            </Routes>
          </ScrollIntoView>
        </BrowserRouter>
      </Fragment>
      <Footer />
    </div>
  ) : (
    <div className="App">
      <Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/subscriber" element={<Subscriber />} />
            <Route path="/admin/contact-us" element={<Contact />} />
            <Route path="/admin/quote" element={<Quote />} />
            <Route path="/admin/automation-service" element={<Automation />} />

            <Route path="/users/test" element={<Single />} />
            <Route path="/admin/compose" element={<ComposeMail />} />
            <Route path="/admin/device-enquiry" element={<DeviceEnquiry />} />
          </Routes>
        </BrowserRouter>
      </Fragment>
    </div>
  );
}

export default App;
