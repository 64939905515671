import React from 'react';

import MetaTags from '../components/MetaTags';
import ContactForm from "../components/ContactForm";
import Counter from "../components/Counter";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Test Environment Management";
const Description="Test Environment Management";
function TestEnvironmentManagement() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
       {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1 >Test Environment Management</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> <a href="/what-we-offer">What We Offer</a></li>
                  <li>Test Environment Management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="overview-area ptb-100">
        <div className="container">
          <div className="overview-box it-overview">
            <div className="overview-content">
              <div className="content">
                <h2>Leave the setup and management of your test environment to us</h2>
                <p>
                TestDel provides complete end-to-end QA environment management service with a dedicated management resource to analyse an entire program from start to finish. We tackle organizations’ key testing challenges by utilizing smaller asset footprints to support test projects. We ensure the test environments for the ‘live’ production situation, making the test fully valid.
         </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      	Environments Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Planning and Integration
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Reporting 
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      One-page overview 
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      	Environment optimization
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Release Management
                    </span>
                  </li>
                </ul>
                <a
                  className="default-btn"
                  data-toggle="collapse"
                  href="#morecontent"
                >
                  Read More <span />
                </a>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img
                  src={`assets/img/what-we-offer/Test-Environment-Management-services.png`}
                  alt="image"
                />
              </div>
            </div>
            <div class="collapse" id="morecontent">
              <div class="card card-body">
                <p>
                We will collect existing environmental landscapes and evaluate them against project requirement. As per the project requirements, we will remediate identified gaps including test tenants, deployment and releases, recovery procedures access control is in line with agreed security set up and business as usual planning. Our comprehensive dashboard will be project manager one-page overview of all environment availability, usage, and future plans. Our test strategies will be designed as per risk factors, dependencies, business processes complexity, priority and impact in using different environments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div className="container pb-5">
      <h2 className="text-center">Full Lifecycle Delivery And Support</h2>
                <div  className="row-align-center text-center">
           <img src={`assets/img/what-we-offer/testing-on.png`}/>
          </div>
          </div>
      <section className="why-choose-area pb-5 pt-5 gray-bg">
        <div className="container">
          <div className="row row-align-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content p-0">
                <h2>Test Data Management </h2>
                <p>The growth of new software development models like the agile development process has given software development companies powerful tools for responding to events quickly and has helped projects evolve through collaboration between self-organizing, cross-functional teams. To make the most of agile processes, programs need an effective and efficient testing approach for governing test data. Many developments, testing, and quality assurance (QA) teams struggle to identify, create and maintain the required test data. Projects often lack confidence in test data preparation and data usage within the testing domain, and it may not be clear who will create the data and how to use, administer it efficiently. That is where TestDel data management services come</p>
              </div>
              <a className="default-btn" data-toggle="collapse" href="#morecontent">
                    Read More <span />
                  </a>
             
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
                <img
                  src={`assets/img/what-we-offer/data-managment.png`}
                  alt="image" 
                />
              </div>
            </div>
            <div class="collapse" id="morecontent">
  <div class="card card-body">
   Test data management is the process of creating realistic test data for non-production purposes such as development, testing, training or QA. Research shows that projects are not successful due to a lack of data quality. We design test data management strategy not only to ensure greater development and testing efficiencies but helps projects to identify and correct defects early in the development process when they are cheapest and easiest to fix. </div>
</div>
          </div>
        </div>
      </section>
      
      <section className="faq-area pt-5 pb-5">
        <div className="container">
            <h3>With the introduction and enforcement of GDPR it has never been more important to ensure your test data is anonymised. Testdel will help you in :</h3>
            <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
            <div className="content-box h-255">
            
            <h4> <i class="bx bxs-badge-check"></i> Test data preparation</h4>
           
            <p className="accordion-content">Involves the identification and production of data by cloning or subsetting data from the live environment or by developing test data generation scripts and provisioning them for multiple testing environments.</p>
            </div>
    
            <div className="content-box h-255">
            
            <h4> <i class="bx bxs-badge-check"></i> Subset production data</h4>
                    <p className="accordion-content">We design subsetting to ensure realistic, referentially intact test data from across a distributed data landscape without added costs or administrative burden.</p>
            </div>
                   
                    <div className="content-box h-255">
            
                    <h4> <i class="bx bxs-badge-check"></i> Discovering</h4>
                   
                    <p className="accordion-content">We know that Data is scattered across systems and sits in different formats. In addition, different rules may be applied to data depending on its type and location.</p>
                    </div>
            
                
                  </div>
                 

                  <div className="col-lg-4 col-md-6">
                  <div className="content-box h-255">
            
                 <h4> <i class="bx bxs-badge-check"></i>  Mask or de-identify</h4>
                   
                    <p className="accordion-content" Style="display:block;">We help the project in masking production data to secure sensitive data especially, client and employee information. We de-identifying confidential data to ensure a realistic look and feel to complete business objects, such as customer orders, across test systems.</p>
                    </div>
            
                    <div className="content-box h-255">
            
                    <h4>  <i class="bx bxs-badge-check"></i>  Automate test data</h4>
                   
                    <p className="accordion-content">The ability to identify data anomalies and inconsistencies during testing is essential to the overall quality of the application. The only way to truly achieve this goal is to deploy an automated capability for comparing the baseline test data against results from successive test runs.</p>
                    </div>
            
                    <div className="content-box h-255">
            
                   <h4> <i class="bx bxs-badge-check"></i> Refresh test data</h4>
                   
                    <p className="accordion-content">During the testing process, test data often diverge from the baseline, resulting in a less than optimal test environment, but we recommend refreshing test data which can improve testing efficiencies. We help the projects in preparing synthetic data generation for testing new functionality.</p>
                 
                    </div>
            
                 
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="content-box h-255">
              <h4> <i class="bx bxs-badge-check"></i> Release management</h4>
                   
                    <p className="accordion-content">Enterprise Release Management with the principles, procedures and tools of agile governance to ensure a smooth release implementation from requirement to go-live.</p>
                    </div>
                    <div className="content-box h-255">
              
                    <h4> <i class="bx bxs-badge-check"></i> Regression Pack</h4>
                   
                    <p className="accordion-content h-255">Undertaking of critical scenarios regression test execution to reduce risk.</p>
                    
                    </div>
                    <div className="content-box h-255">
              
                    <h4> <i class="bx bxs-badge-check"></i> Test Reporting</h4>
                   
                    <p className="accordion-content">A managed cloud-hosted platform allowing you to follow timescales, self-select and schedule critical on-demand services.</p>
                 
                    </div>
          </div>
        </div>
        </div>
      </section>
     
      <Counter/>
     <ContactForm/>
          </div>
  );
}

export default TestEnvironmentManagement;
