import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from './MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Software testing and QA services company";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";

class NotFoundPage extends React.Component{
    render(){
        return (
          <div>
            <MetaTags title={Title} description={Description} />

            {/* Start Error Area */}
            <section className="error-area ptb-100">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="error-content">
                      <img src={require(`../assets/img/404.png`)} />

                      <h3>Page Not Found</h3>
                      <p>
                        The page you are looking for might have been removed had
                        its name changed or is temporarily unavailable.
                      </p>
                      <a href="/" className="default-btn">
                        Go to Home{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Error Area */}
          </div>
        );
    }
}
export default NotFoundPage;