import React from "react";
import CareerForm from "../components/CareerForm";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";
const Title = "Join The Team";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function JoinTeam() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />

      {/* Start Page Title Area */}
      <div className="page-title-area join-team-bg pt-0">
        <div className="d-table">
          <div className="d-table-cell ">
            <div className="container">
              <div className="row">
                <div className="col-md-7 pt-5">
                  <div className="">
                    <h2>JOIN THE COMPANY</h2>
                    <h4>WHERE TESTERS ARE TREATED AS BUSINESS PARTNER</h4>
                  </div>
                  <p>
                    If you want to work in a collaborative environment where
                    opportunities are offered, skills are stretched and
                    excellence is rewarded, you are at the right place.
                  </p>
                  <p>All Testdel team members share these characteristics:</p>

                  <p>- A self-starter with a ‘can-do’ attitude</p>
                  <p>
                    - Creative and quality conscious ready to work in a
                    Fast-Paced Team Environment
                  </p>
                  <p>
                    - Excellent communication skills, especially sharing
                    knowledge and findings with the team. &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;Work hard play hard too 🙂
                  </p>
                  <a
                    href="javascript:void();"
                    className="default-btn mt-3"
                    data-toggle="modal"
                    data-target="#joinCareerModal"
                  >
                    Send us an Email <span />
                  </a>
                  <a href="faqs" className="ml-3 default-btn mt-3">
                    Faq's <span />
                  </a>
                </div>
                <div className="col-md-5 pt-5">
                  <img src={`assets/img/join-team.png`} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      <section className="choose-area-two pt-5 pb-5 gray-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2>WHY WORK WITH US?</h2>
                </div>
                <p>
                  We offer our tester a unique working environment, We make sure
                  individual contribution is visible and recognized. You will
                  work through your personal development plan, which will be
                  aligned to your chosen career path. You will receive dedicated
                  training days every year so you can enhance industry-current
                  skills.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/teams.png`} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="join-about gray-bg">
        <div className="container-fluid pt-0 pb-5 ">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="bx bx-time mt-3" />
                </div>
                <h3>
                  <a href="#">14 Days Paid Leave</a>
                </h3>
                <p>
                  You'll enjoy 2 weeks of annual paid leave plus all national
                  Holidays and allocation of work.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-00aeff">
                  <i className="bx bx-laptop mt-3" />
                </div>
                <h3>
                  <a href="#">Company System</a>
                </h3>
                <p>
                  All new team members will receive a company desktop or laptop
                  for on-site and remote working.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-f78acb">
                  <i className="bx bx-mobile mt-3 " />
                </div>
                <h3>
                  <a href="#">Onsite Training</a>
                </h3>
                <p>
                  Basic tester training and help preparing for ISTQB software
                  testing exams.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-cdf1d8">
                  <i className="bx bx-money mt-3" />
                </div>
                <h3>
                  <a href="#">Competitive Salary</a>
                </h3>
                <p>
                  We offer the best salary based on your experience and Academic
                  qualification.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="join-area pt-5 pb-5 ">
        <div className="container">
          <div className="section-title">
            <h2>Open Positions</h2>
          </div>
          <div className="tab join-list-tab">
            <ul className="tabs">
              <li>
                <a href="#">
                  <span>
                    TEST ENGINEER – NETWORK SECURITY (AUTOMATION, MANUAL){" "}
                  </span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Automation Tester</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Software Test Lead</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Software Test Analyst</span>
                </a>
              </li>
            </ul>
            <div className="tab_content">
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                      <h2>Location: London , United Kingdom </h2>
                      <h2>Responsibilities include:</h2>
                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Requires continuous
                          research to ensure software features meet identified
                          standards and product functional requirements
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Develop, execute and
                          automate functional test plans to verify product
                          capabilities
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Execution of test
                          cases and reporting test results with artefacts using
                          Quality Center (QC),JIRA
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Work closely with
                          engineers across teams to ensure quality for the
                          features
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Build in-depth
                          knowledge of product architecture and develop the
                          ability to root-cause issues
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Proactively develop
                          tools / scripts / templates to improve efficiency /
                          productivity of team
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Coordinate activities
                          with client test and development teams on a need basis
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Requirements Analysis
                          and preparation of test plan, Test scripts -Able to
                          host test plan reviews for assigned features, as well,
                          as to contribute to test plan reviews held by other
                          team members.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Attend independently
                          and provide comments for the functional requirements
                          and/or those relating to commercial
                          specifications/RFCs for FS reviews
                        </li>
                      </ul>
                      <h2>Required Skills</h2>

                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Position requires
                          proficient troubleshooting and problem-solving skills
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Fluent in various scripting languages for test
                          automation (Python, TCL)
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Team-oriented; strong
                          interpersonal skills and ability to understand
                          customers’ needs, expectations and perspective
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Demonstrated ability
                          to multitask on multiple projects
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Demonstrated
                          communications skills, both verbal and written, to
                          effectively communicate/collaborate with remote QA and
                          Development teams
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Strong commitment to
                          product excellence and quality
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Quickly comes up to
                          speed in the understanding of advanced
                          concepts/methodology and contributes to improving and
                          extending the impact and effectiveness of those
                          concepts or new approaches to the current project
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Hands-on Experience on
                          Network Security like TLS, RADIUS and relevant
                          security tools
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Hands-on Experience on
                          carrier ethernet technologies would be desirable
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Experience with
                          traffic generators must, preferably with Ixia
                        </li>
                      </ul>
                      <h2>Python experience is must</h2>

                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Experience in other
                          languages like TCL, Selenium and GUI technologies will
                          be a big plus.
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Reference ID: TD/03/2021
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Part-time hours: 40
                          per week
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Expected Start Date:
                          01/04/2021
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Related keywords:
                          software tester, manual tester, software testing,
                          software engineer
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Job Type: Permanent
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Salary:
                          £35,000.00-£40,000.00 per year
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Posted on: 07/01/2021
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                      <h2>Location: Chandigarh, India</h2>

                      <h2>Automation Tester Responsibilities </h2>
                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Understanding and
                          Analysing the Application Under Test in terms of
                          Object Identification.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Creating Test
                          scenarios and Collecting Test Data.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Identifying end to end
                          scenarios and code modularity.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Implementing Test
                          Automation framework and developing automation
                          infrastructure.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Creating reusable
                          components.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Creating and enhancing
                          Test Cases (Test Scripts) using Element locators,
                          WebDriver methods, Java programming concepts, and
                          other Annotations.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Error Handling, adding
                          comments.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Creating Data-driven
                          Tests and running through the framework.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Cross Browser Testing
                          (Executing test cases against various browsers like
                          Firefox, Google Chrome, IE and Safari etc.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Parallel Test
                          Execution.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Defining and exporting
                          Test Results.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Analysing Test Results
                          and Reporting Defects.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Tracking Defects and
                          Select Test cases for Re &amp; Regression Testing.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Modifying Test
                          Automation Resources and Maintenance of Resources.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                      <h2>Location: Chandigarh, India</h2>

                      <h2>Test lead Responsibilities </h2>
                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" />
                          Responsible for managing testing activities and test
                          teams, at a project level, for new and existing
                          internal or external applications.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Oversees validating
                          that business specifications have been implemented as
                          requested, and that existing functionality continues
                          to perform.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Responsible for
                          overall coordination of test activities and test
                          strategies, and identifying quality risks in regards
                          to new and existing applications.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Participates in
                          planning meetings and workgroups and is able to
                          interact with testers, developers, business users, PMO
                          and Project Sponsors in order to promote project.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Test estimation and
                          planning.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> QA environment
                          management.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Testing experience
                          with large, complex, highly integrated applications.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Experience working
                          with onshore and offshore models.
                        </li>
                      </ul>
                      <h2>
                        <i className="flaticon-tick" /> As the Software test
                        Analyst you will have the following essential skills and
                        experience:
                      </h2>
                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Managing multiple
                          teams, multiple projects
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Managing teams from
                          different geographic locations including on shore, off
                          shore, near shore
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Experience with
                          writing and executing Test Strategies, Test Plans,
                          Test Cases, Test scripts
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Software Consulting experience
                        </li>
                        <li>
                          Excellent written and verbal communication skills
                        </li>
                      </ul>
                      <h2>Additional skills:</h2>
                      <ul className="features-list">
                        <li>
                          <i className="flaticon-tick" /> Desirable to have HR
                          Module and financial experience
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Desired to do actual
                          scripting
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                      <h2>Location: Chandigarh, India</h2>

                      <h2>Software Test Analyst Responsibilities </h2>

                      <ul>
                        <li>
                          <i className="flaticon-tick" />
                          Creation and execution of test scripts, recording
                          outcome of tests, escalation of defects and
                          identifying opportunities for product improvement.
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Hands on testing of all functional modules;
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Working in a cross functional team
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Liaising with the other tester and implementation
                          staff to facilitate the testing of software;
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Liaising with members of the development team in order
                          to provide Test feedback and over specific test
                          outcomes e.g. for exploration and resolution of
                          defects identified;
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Testing of interfaces and integration with other
                          modules, products or interface test harnesses;
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Preparation and execution of test plans and test
                          scripts. Amend or update test materials to ensure
                          lessons learned are reflected in future testing
                          activities;
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Perform static testing and impact assessment of Design
                          and Change Request documentation; Ability to
                          understand software specification/requirements and to
                          interpret acceptance criteria and derive tests
                          accordingly;
                        </li>
                      </ul>
                      <h2>
                        <i className="flaticon-tick" /> As the Software test
                        Analyst you will have the following essential skills and
                        experience:
                      </h2>
                      <ul>
                        <li>
                          <i className="flaticon-tick" /> Experience doing
                          Functional Testing, Regression Testing, Integration
                          Testing, System Testing and Exploratory Testing
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Experience translating
                          functional requirements and/or user stories into test
                          cases
                        </li>
                        <li>
                          <i className="flaticon-tick" /> At least 1-year
                          experience testing web applications
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Excellent communication skills
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Experience using test case management and bug tracking
                          tools
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Experience providing
                          objective evidence of test passes and failures (screen
                          snaps, documenting actual and expected result, etc.)
                        </li>
                        <li>
                          <i className="flaticon-tick" />
                          Team player
                        </li>
                      </ul>
                      <h2>
                        Higher salary will be offered to those candidates who
                        also have experience in:
                      </h2>
                      <ul>
                        <li>
                          <i className="flaticon-tick" />
                          Automation Testing including Selenium and C#
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                      <h2>Location: Chandigarh, India</h2>

                      <p>
                        Are you a writer who is both creative and analytical?
                        Are you logically motivated to ask the “how” and “why” ?
                        not just the “what”? Reporting to TestDel CEO and
                        Founder remotely from firm’s Chandigarh Office, the
                        Content Marketing Writer will be an essential member of
                        the marketing team, while also working closely with
                        subject matter experts across various industry practices
                        including but not limited to the Industrials, Financials
                        , HR and IT systems to create a broad range of thought
                        leadership and marketing content. Critical to this role
                        is the capability and adaptability to develop long- and
                        short-form content in a fast-paced, dynamic environment.
                      </p>
                      <h2>Responsibilities:</h2>
                      <ul>
                        <li>
                          <i className="flaticon-tick" /> Collaborate with
                          TestDel Founder and CEO to develop and write white
                          papers and other proprietary thought leadership.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Write and edit a wide
                          variety of other marketing content, including web and
                          publicity email copy, blog posts, social media,
                          by-lined articles, award entries, speaker proposal
                          abstracts and infographics
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Manage production of
                          white papers and other marketing content, including
                          working with outside designers on print and digital
                          layout and facilitating proofreading and overall
                          quality control of final product.
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Collaborate with other
                          team members on promotional content to support the
                          firm’s content distribution strategy across multiple
                          channels, including email, social media, paid media,
                          and web copy
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Manage content
                          projects from inception to completion
                        </li>
                        <li>
                          <i className="flaticon-tick" /> Provide other general
                          marketing program management support as requested
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fun-facts-area pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <i className="flaticon-clock" />
                <h5 className="text-white mt-2">Quick Starts</h5>
                <div className="back-text">Q</div>
              </div>
              <div className="p-2 hover-content border-dashed">
                <p className="text-white">
                  No long estimation of the project, we can start testing within
                  1-3 days and join the project on any of its Lifecycycle
                  stages.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <i className="flaticon-clock" />
                <h5 className="text-white mt-2">Managed testing</h5>
                <div className="back-text">M</div>
              </div>
              <div className="p-2 hover-content border-dashed">
                <p className="text-white">
                  Our Delivery Managers take on overall control and management
                  of QA teams and are responsible for communications with
                  customers.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <i className="flaticon-clock" />
                <h5 className="text-white mt-2">Ready Team</h5>
                <div className="back-text">R</div>
              </div>
              <div className="p-2 hover-content border-dashed">
                <p className="text-white">
                  With ready-to-start team, assembled under project specs, save
                  on extra operational management costs and spend zero minutes
                  to recruit, train, set up in-house testing unit.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <i className="flaticon-clock" />
                <h5 className="text-white mt-2">Test Lab </h5>
                <div className="back-text">T</div>
              </div>
              <div className="p-2 hover-content border-dashed">
                <p className="text-white">
                  We possess extensive testing infrastructure (350+ mobile
                  devices: Android, iOS, 40+ testing tools). You save money with
                  us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      {/* Modal */}
      <div
        className="modal fade"
        id="joinCareerModal"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Send us an Email</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-3">
              <CareerForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinTeam;
