import React from "react";
import MetaTags from '../components/MetaTags';
import Axios from 'axios';
import { ValidateBussinessEmail } from '../utils/functions';
import { getHostUrl } from '../config';

const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Our Lab ";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
export default class Ourlab extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    firstname: '',
    organisation: '',
    email: '',
    countryName: '',
    countryCode: '',
    subscribe:false,
    isSubmitted: false,
    errors: {
      first: '',
      last: '',
      org: '',
      email: '',
    },
  };
}
getGeoInfo = () => {
  Axios.get('https://ipapi.co/json/')
    .then((response) => {
      let data = response.data;
      this.setState({
        countryName: data.country_name,
        countryCode: data.country_calling_code,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

componentDidMount() {
  this.getGeoInfo();
}
onFNameChange(event) {
  let errors = this.state.errors;
  if (event.target.value.match('^[a-zA-Z ]*$') != null) {
    errors.first = '';
    this.setState({ firstname: event.target.value, errors: errors });
  } else {
    errors.first = 'Enter Only Character';
    this.setState({ errors });
  }
}


onEmailChange(event) {
  let errors = this.state.errors;
  errors.email = ValidateBussinessEmail(event.target.value)
    ? ''
    : 'Please enter your business email address';
  this.setState({
    email: event.target.value,
    errors,
  });
}


onOrganisationChange(event) {
  this.setState({ organisation: event.target.value });
}

resetForm() {
  this.setState({
    name: '',
    email: '',
    number: '',
    msg: '',
    file: '',
    errors: {
      first: '',
      last: '',
      number: '',
      org: '',
      msg: '',
    },
  });
}

validateForm = () => {
  let valid = true;
  let errors = this.state.errors;
  if (!this.state.firstname) {
    valid = false;
    errors.first = 'First name is mandatory';
    this.setState({
      errors,
    });
  }
  if (!this.state.organisation) {
    valid = false;
    errors.org = 'Company is mandatory';
    this.setState({
      errors,
    });
  } 

  if (!this.state.email) {
    valid = false;
    errors.email = 'Email is mandatory';
    this.setState({
      errors,
    });
  } else {
    if (!ValidateBussinessEmail(this.state.email)) {
      valid = false;
      errors.email = 'Please valid business email address';
      this.setState({
        errors,
      });
    }
    
  }


  return valid;
};

handleSubmit(e) {
  if (this.validateForm()) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('firstname', this.state.firstname);
    formData.append('lastname', '');
    formData.append('email', this.state.email);
    formData.append('organisation', this.state.organisation);
    formData.append('countryname', this.state.countryName);
    formData.append('countrycode', this.state.countryCode);
    formData.append('subscribe', this.state.subscribe);
    formData.append('enquiry_type', 'device');
    let country = localStorage.getItem("country_code");
    formData.append("country", country);
    Axios.post(getHostUrl('/api/user/sendquote'), formData, {
    }).then((response)=>{
    console.log(response.success);

    if (response.status ==200){
      this.setState({isSubmitted: true});
        this.resetForm();
    }else{
      alert("Message failed to send.")
    }
    })
  }
}

  render() {
    return (
      <div>
        <MetaTags title={Title} description={Description} />
        {/* Start Main Banner Area */}
        <div className="it-banner">
          <div className="container-fluid border-bottom pt-5 pb-4">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h1>Device Lab</h1>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Device Lab</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container mt-5 mb-5">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <div className="hero-banner-content">
                      <span className="sub-title">TESTDEL TESTING LAB </span>
                      <h2>
                        Largest software testing device libraries in the UK ,
                        USA and India
                      </h2>
                      <p>
                        Our software testing centre has been developed to
                        optimise our specialized testing capabilities and
                        provide you with a scalable, safe, and cost-effective
                        custom solution.All our manpower works from secure model
                        offices in USA, UK and India . We constantly upgrade and
                        maintain a wide range of hardware that allows us to
                        check directly on devices and operating systems with
                        which your users are most likely to work.
                      </p>
                      <div className="btn-box">
                        <a href="contact-us" className="default-btn">
                          Contact Us <span />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="it-banner-image owl-carousel owl-theme">
                      <div className="animate-image">
                        <img
                          src={require(`../assets/img/it-banner/animate-img.png`)}
                          alt="image"
                        />
                      </div>
                      <div className="animate-image">
                        <img
                          src={require(`../assets/img/it-banner/animate-img2.png`)}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Shape Images */}
          <div className="shape-img2">
            <img src={`../assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`../assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img7">
            <img src={`assets/img/shape/7.png`} alt="image" />
          </div>
          <div className="shape-img8">
            <img src={`assets/img/shape/8.png`} alt="image" />
          </div>
          <div className="shape-img9">
            <img src={`assets/img/shape/9.png`} alt="image" />
          </div>
          <div className="shape-img10">
            <img src={`assets/img/shape/10.png`} alt="image" />
          </div>
          <div className="shape-img11">
            <img src={`assets/img/shape/11.png`} alt="image" />
          </div>
          <div className="shape-img12">
            <img src={`assets/img/shape/12.png`} alt="image" />
          </div>
        </div>
        {/* End Main Banner Area */}

        {/* Start Featured Services Area */}
        <section className="featured-services-area ptb-100">
          <div className="container">
            <div className="section-title">
              <h2>Technical Capabilities</h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <div className="icon">
                    <i className="flaticon-analytics" />
                  </div>
                  <h3>Speed</h3>
                  <p>
                    Our office is connected to the Internet through an ultra
                    fast, low latency optic fibre network.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <div className="icon color-facd60">
                    <i className="flaticon-research" />
                  </div>
                  <h3>Efficient Research</h3>
                  <p>
                    TestDel test networks have the capability to conduct
                    real-time impairment and review of packets.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <div className="icon">
                    <i className="flaticon-data" />
                  </div>
                  <h3>Data centre on site </h3>
                  <p>
                    TestDel's own in-house data centre allows far more precise
                    monitoring and analysis.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 ">
                <div className="single-featured-box">
                  <div className="icon color-1ac0c6">
                    <i className="flaticon-mail" />
                  </div>
                  <h3>Coupled </h3>
                  <p>
                    The Test systems are configured with multiple methods of
                    network connectivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Featured Services Area */}
        {/* Start About Area */}
        <section className="about-area ptb-100 pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-image">
                  <img src={`assets/img/about-img1.png`} alt="image" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-content">
                  <span className="sub-title">SECURITY </span>
                  <h3>
                    TestDel has invested in cutting edge labs to provide secure
                    test lab
                  </h3>
                  <p>
                    Testdel test lab supports critical security requirements,
                    while the on-site lab resides inside the company’s VPN,
                    ensuring the security of the highest degree. Our devices are
                    centralized in a demilitarized network area to our clients
                    with great peace of mind.
                  </p>
                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-6">
                      <div className="faq-accordion">
                        <ul className="accordion">
                          <li className="accordion-item">
                            <a
                              className="accordion-title active"
                              href="javascript:void(0)"
                            >
                              <i className="fas fa-check" />
                              Safe Servers
                            </a>
                            <p
                              className="accordion-content"
                              Style="display:block;"
                            >
                              To ensure the security of your data we have
                              protected FTP server access and a safe media
                              management room for data processing and
                              replication.
                            </p>
                          </li>
                          <li className="accordion-item">
                            <a
                              className="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i className="fas fa-check" />
                              Safe access
                            </a>
                            <p className="accordion-content">
                              Entry to our model office facility is secured in
                              high-security areas by electronic key-card access
                              controls, cypher-locks and biometric full-hand
                              geometry locks.
                            </p>
                          </li>

                          <li className="accordion-item">
                            <a
                              className="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i className="fas fa-check" />
                              Isolated Networks
                            </a>
                            <p className="accordion-content">
                              Our software testing facilities include many
                              isolated networks to restrict individual testing
                              efforts into predetermined labs or computer
                              classes.
                            </p>
                          </li>
                          <li className="accordion-item">
                            <a
                              className="accordion-title"
                              href="javascript:void(0)"
                            >
                              <i className="fas fa-check" />
                              24-Hour monitoring
                            </a>
                            <p className="accordion-content">
                              Our workshop has 24-hour monitoring and motion
                              detection of closed-circuit TV security cameras
                              and multi-zone warning systems
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-img3">
            <img src={`../assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={`../assets/img/shape/2.svg`} alt="image" />
          </div>
        </section>
        {/* End About Area */}
        {/* Start Services Area */}
        <section className="services-area ptb-100 bg-F4F7FC">
          <div className="container-fluid">
            <div className="section-title">
              <span className="sub-title">FLEXIBILITY AND EFFICIENCY</span>
              <h3>
                We assist in improving the quality of our customer's products
                and solutions
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon">
                    <i className="fa fa-cog" />
                  </div>
                  <h3>
                    <a href="#">Custom test systems </a>
                  </h3>
                  <p>
                    To demonstrate your unique user base, we use
                    state-of-the-art inventory and test systems to allow quick
                    confirmation of the detected defects.
                  </p>
                  <br></br>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-00aeff">
                    <i className="flaticon-research" />
                  </div>
                  <h3>
                    <a href="#">Various Environment Testing </a>
                  </h3>
                  <p>
                    Our systems are equipped with multiple operating systems and
                    software configurations that allow us to test and validate
                    software in a broad range of environments.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-f78acb">
                    <i className="flaticon-analytics" />
                  </div>
                  <h3>
                    <a href="#"> Efficient Communication </a>
                  </h3>
                  <p>
                    All our testers work in close proximity to each other,
                    supporting contact and shared observation of on-screen
                    deviations.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-cdf1d8">
                    <i className="fa fa-video" />
                  </div>
                  <h3>
                    <a href="#">Screen & Video Capture </a>
                  </h3>
                  <p>
                    To allow us to graphically record "difficult" bugs,
                    computers in our software testing workshop are equipped with
                    screen capture and video capture softwares.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Area */}
        {/* Start Projects Area */}
        <section className="projects-area ptb-100">
          <div className="container">
            <div className="section-title mb-0">
              <h3>
                We stock all major platforms, browser combinations, OS and
                Multiple hardware
              </h3>
              <p>
                Take a look at selection of devices and technology we have
                available for testing.
              </p>
            </div>
            <img src={`assets/img/company-icon.png`} />
          </div>
        </section>
        {/* End Projects Area */}
        {/* Start Subscribe Area */}
        <section className="subscribe-area bg-F4F7FC">
          <div className="container-fluid p-0">
            <div
              className="subscribe-inner-area jarallax "
              data-jarallax='{"speed": 0.3}'
            >
              <div className="subscribe-content pb-5">
                <span className="sub-title">
                  CONSULT OUR EXPERIENCED TEAM FOR YOUR TESTING REQUIREMENTS
                </span>
                <h2>Check out our full list of devices.</h2>
                <form
                  className="newsletter-form p-0"
                  Style="max-width:300px !important"
                  data-toggle="validator"
                >
                  <button
                    type="submit"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    className="bg-light text-dark"
                  >
                    See our List of devices
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="shape-img2">
            <img src={`../assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`../assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img6">
            <img src={`assets/img/shape/6.png`} alt="image" />
          </div>
        </section>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            {!this.state.isSubmitted ? (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Enter your details to receive the download!
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body p-4">
                  <p>* Denotes required fields</p>
                  <div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name *</label>
                      <input
                        value={this.state.firstname}
                        onChange={this.onFNameChange.bind(this)}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      <small className="text-danger">
                        {" "}
                        {this.state.errors.first}
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Company *</label>
                      <input
                        value={this.state.organisation}
                        onChange={this.onOrganisationChange.bind(this)}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      <small className="text-danger">
                        {" "}
                        {this.state.errors.org}
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Bussiness Email address *
                      </label>
                      <input
                        value={this.state.email}
                        onChange={this.onEmailChange.bind(this)}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      <small className="text-danger">
                        {" "}
                        {this.state.errors.email}
                      </small>
                    </div>
                    <h6>Join Our Mailing List</h6>
                    <small className="text-muted">
                      We never share your details with 3rd party marketing
                      companies. Read our{" "}
                      <a href="privacy-policy">Privacy Notice</a>
                    </small>
                    <div className="form-group mt-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        onChange={() =>
                          this.setState({ subscribe: !this.state.subscribe })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Select this check box to join our mailing list.
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn default-btn"
                      onClick={this.handleSubmit.bind(this)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div id="msgSubmit" class="alert alert-success text-center">
                We appreciate you obtaining our device list. You've received a
                copy in your inbox.
              </div>
            )}
          </div>
        </div>
      </div>
    );}
}


