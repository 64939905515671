import React from "react";

import MetaTags from "../components/MetaTags";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Functional Testing Services UK | Functional Test Tools";
const Description =
  "TestDel offers functional software testing services for your website, mobile apps and software products. We safeguard bug free software application.";
function FunctionalTesting() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Functional Testing</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> <a href="/what-we-offer">What We Offer</a></li>
                  <li>Functional Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
     
      </div>
      {/* End Page Title Area */}
      {/* Start Why Choose Us Area */}
      <section className="why-choose-area pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                <h2>We ensure your technology works in the real world</h2>
                <p>
                  Functional test delivery ensures that an application is tested
                  against its requirements specifications.
                </p>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" /> End to End validation{" "}
                  </h4>
                  <p>
                    TestDel conducts usability testing to ensure end users can
                    leverage the full functionality of software solutions
                    flawlessly.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" /> Test system as real life{" "}
                  </h4>
                  <p>
                  TestDel Tester fully imitate the actual usage of system in real life
                    for detecting early-stage software errors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
               <img src={`assets/img/what-we-offer/Functional-test-delivery1.png`} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-area pt-5 pb-5 gray-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
               <img src={`assets/img/what-we-offer/Functional-Test-Delivery.png`}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                <span className="sub-title">FUNCTIONAL TEST DELIVERY</span>
                <h2>We work with you to: </h2>
                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" /> Conduct a confidential
                    process involving two or three clients and TestDel senior
                    leaders to understand your “As is and To be” state
                    to transform strategies.
                  </p>
                </div>
                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" /> Provide senior leadership
                    POC covering process, coverage, deliverables, executions
                    plan, critical gaps, and road map with recommended action
                    items to increase your test capability in-line with industry
                    best practice.
                  </p>
                </div>

                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" /> Test management service
                    providing a dedicated expert to lead and deliver all aspects
                    of test management on your programme from initiation till Go
                    Live.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End Why Choose Us Area */}
      <section className="overview-area pt-5 pb-5 ">
        <div className="container">
          <div className="overview-box it-overview">
            <div className="overview-content">
              <div className="content">
                <h2>Functional Testing Process</h2>
                <p>
                We will give our considerable expertise at all stages of the process and work with you on your testing requirements. We also appointed trained staff who are responsible for particular testing related roles to ensure that each part of the process goes according to standards and best practises.
                </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Analyse Requirement
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Determine test data
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Design test strategy
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Prepare and Execute tests
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Defect triage
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Prepare Defect report
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
               <img src={`assets/img/what-we-offer/outsource-software-testing.png`} alt="image"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="fa fa-window-restore" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>Compatibility Testing </h3>
                <p>
                Ensure the consistency of the software or website does not deteriorate using different configurations.
                </p>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="flaticon-analytics" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>Regression Testing </h3>
                <p>
                Retesting the same test cases over and again to validate that no side-effects appear in existing functionalities.
                </p>
                  </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-data" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>Usability Testing </h3>
                <p>
                Execute to check whether the solution is user-friendly or not and to assist in detecting severe bugs.
                </p>
             
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-task" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>API Testing </h3>
                <p>
                For validating normal and abnormal operations and exception handling capabilities, lead to service disruptions.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-search-alt" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>Business Intelligence Testing </h3>
                <p>
                BI applications tested to verify consumption of input , correct outputs, front-end reporting and data errors.
                </p>
                
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-bar-chart" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
                <h3>Localization Testing</h3>
                <p>
                To verify that the solution content and user interface meet specified users’ language and location.
                </p>
              
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" pt-5 pb-5" Style="background-color:#f6f7fb;">
        <div className="container">
          <div className="section-title mb-0">
          <h2>Integrations</h2>
          <p>Keep your existing workflow and Integrate with the tools you already use.</p>
          </div>
          <img src={`assets/img/what-we-offer/functional1.png`}/>
          
        </div>
      </section>
        </div>
  );
}

export default FunctionalTesting;
