import React from 'react';
import Axios from 'axios';
import { getHostUrl } from '../config';
import { ValidateBussinessEmail } from '../utils/functions';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      number: '',
      file: '',
      msg: '',
      countryName: '',
      countryCode: '',
      isSubmitted: false,
      errors: {
        name: '',
        number: '',
        msg: '',
        countryName: '',
        countryCode: '',
      },
      selectedOption: null,
    };
  }
  getGeoInfo = () => {
    Axios.get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getGeoInfo();
  }
  render() {
    return (
      <div>
        {/* Contact Form Section  */}
        <div className="contact-form-section pt-5 pb-70 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="analysis-img">
                  <img src={`../assets/img/seo-analysis.png`} alt="Image" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="analysis-form">
                  <div className="section-title text-left">
                    <span className="sub-title">Get In Touch</span>
                    <h2 className="m-0">Take The First Step</h2>
                    <p>Let’s talk about how TestDel can associate with you</p>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.onNameChange.bind(this)}
                            placeholder="Your Name"
                            required
                          />
                          <small className="text-danger">
                            {' '}
                            {this.state.errors.name}
                          </small>
                        </div>

                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            value={this.state.email}
                            onChange={this.onEmailChange.bind(this)}
                            placeholder="Bussiness Email"
                            required
                          />
                          <small className="text-danger">
                            {' '}
                            {this.state.errors.email}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            inputProps={{ maxLength: 12 }}
                            className="form-control"
                            value={this.state.number}
                            onChange={this.onNumberChange.bind(this)}
                            placeholder="Phone Number"
                            required
                          />
                          <small className="text-danger">
                            {' '}
                            {this.state.errors.number}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <textarea
                            value={this.state.msg}
                            required
                            onChange={this.onMsgChange.bind(this)}
                            className="form-control">
                            Your Messege
                          </textarea>
                          <small className="text-danger">
                            {' '}
                            {this.state.errors.msg}
                          </small>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                        <input type="file" onChange={this.onFileChange.bind(this)} />
                        <small className="text-danger"> {this.state.isError4}</small>
                        <p> <small>{"(pdf, docx file types less than 4 MB)"}</small></p>
                      </div> */}
                    <div className="form_policy">
                      TestDel does not sell, rent or otherwise make available
                      any personal data submitted by visitors to our site to any
                      third parties. By submitting this form, I agree to
                      TestDel's{' '}
                      <a
                        href="privacy-policy"
                        className="text-primary"
                        target="_blank">
                        Privacy Policy
                      </a>
                      .
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="default-btn"
                        value="Send"
                        onClick={this.handleSubmit.bind(this)}>
                        Send
                        <span />
                      </button>
                    </div>
                  </div>
                  <br />
                  {this.state.isSubmitted && (
                    <div id="msgSubmit" class="alert alert-success text-center">
                      Thank you we have received your email and will get back to
                      you as soon as possible.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Form Section */}
      </div>
    );
  }

  onNameChange(event) {
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      this.setState({ name: event.target.value });
      let errors = this.state.errors;
      errors.name = '';
      this.setState({ errors });
    } else {
      let errors = this.state.errors;
      errors.name = 'Enter Only Character';
      this.setState({ errors });
    }
  }


  onEmailChange(event) {
    let errors = this.state.errors;
    errors.email = ValidateBussinessEmail(event.target.value)
      ? ''
      : 'Please enter your business email address';
    this.setState({
      email: event.target.value,
      errors,
    });
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == 'application/pdf' ||
      event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      this.setState({ file: event.target.files[0] });
      this.setState({ isError4: '' });
    } else {
      this.setState({ isError4: 'Allow only PDF and DOC File' });
    }

    if (event.target.files[0].size <= 4083842) {
      this.setState({ file: event.target.files[0] });
      this.setState({ isError4: '' });
    } else {
      this.setState({ isError4: 'Maximum file size 4mb' });
    }
  }

  onNumberChange(event) {
    const re = /^[0-9\b]+$/;
    const number = event.target.value;
    let errors = this.state.errors;
    if (event.target.value === '') {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    }
    if (event.target.value === '' || re.test(event.target.value)) {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    } else {
      errors.number = 'Enter Only Integer Number';
      this.setState({ errors });
    }

    if (event.target.value.length > 0) {
      if (event.target.value.length >= 10) {
        errors.number = '';
        this.setState({ errors });
        this.setState({ number: event.target.value, errors: errors });
      } else {
        errors.number = 'Enter Min 10 Digit Number';
        this.setState({ errors: errors });
      }
    }
  }
  onMsgChange(event) {
    let errors = this.state.errors;
    if (event.target.value) {
      errors.msg = '';
      this.setState({ msg: event.target.value, errors: errors });
    } else {
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({ errors: errors, msg: event.target.value });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      number: '',
      msg: '',
      errors: {
        name: '',
        number: '',
        msg: '',
        countryName: '',
        countryCode: '',
      },
    });
  }
  validateForm = () => {
    let valid = true;
    let errors = this.state.errors;
    if (!this.state.name) {
      valid = false;
      errors.name = 'Name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.email) {
      valid = false;
      errors.email = 'Email is mandatory';
      this.setState({
        errors,
      });
    } else {
      if (!ValidateBussinessEmail(this.state.email)) {
        valid = false;
        errors.email = 'Please valid business email address';
        this.setState({
          errors,
        });
      }
    }
    if (!this.state.msg) {
      valid = false;
      errors.msg = 'Message is mandatory';
      this.setState({
        errors,
      });
    } else if (this.state.msg && this.state.msg.length <= 15) {
      valid = false;
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({
        errors,
      });
    }
    return valid;
  };
  handleSubmit(e) {
    if (this.validateForm()) {
      e.preventDefault();
      const formData = new FormData();
      // formData.append('filename', this.state.file);
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('number', this.state.number);
      formData.append('msg', this.state.msg);
      formData.append('countryname', this.state.countryName);
      formData.append('countrycode', this.state.countryCode);
      let country = localStorage.getItem("country_code");
      formData.append("country", country);
      
      Axios.post(getHostUrl('/api/user/sendmail'), formData, {
        // receive two parameter endpoint url ,form data
      }).then((response) => {
        console.log(response.success);
        if (response.status == 200) {
          this.setState({ isSubmitted: true });
          this.resetForm();
        } else {
          alert('Message failed to send.');
        }
      });
    }
  }
}

export default ContactForm;
