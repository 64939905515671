import React, { useState } from "react";
import "./list.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navbar"
import Datatable from "../../components/Datatable/Datatable"
import axios from "axios";
import { getHostUrl } from "../../config";

function Automation  (){
  const [user,setUser] =  useState([])

  React.useEffect(()=>{
    getSubscriber()
  },[])

  const getSubscriber = ()=> {
    const formData ={
        type:'automation'
        }
   axios.post(getHostUrl('/api/user/getMailUsList'), formData, {
    }).then((response)=>{
      if (response.status ==200 && response.data.success){
        setUser(response.data.enquiry)
      }else{
        alert("Message failed to send.")
    }
    })
  }
   const userColumns = [
    { field: "name", headerName: "Name", width: 140 },
    {
      field: "email",
      headerName: "Email",
      width: 220,
    },
    {
      field: "phonenumber",
      headerName: "Phone Number",
      width: 180,
    },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 150,
    },
    {
      field: "country",
      headerName: "Country",
      width: 140
    }
  ];
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable row={user} col={userColumns}/>
      </div>
    </div>
  )
}

export default Automation