import React from "react";
import MailingForm from "./MailingForm";
function Footer() {
  const year = new Date().getFullYear();
  return (
    <div>
      {/* Start Footer Area */}
      <section className="footer-area ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Who We Are</h3>
                <ul className="footer-quick-links">
                  <li>
                    <a href="/who-we-are">
                      <i className="fas fa-arrow-right"></i> About Us
                    </a>
                  </li>
                  <li>
                    <a href="/our-lab">
                      <i className="fas fa-arrow-right"></i> Our Lab
                    </a>
                  </li>
                  <li>
                    <a href="/case-study">
                      <i className="fas fa-arrow-right"></i> Case Study
                    </a>
                  </li>
                  <li>
                    <a href="/business-model">
                      <i className="fas fa-arrow-right"></i> Business Model
                    </a>
                  </li>
                  {/* <li>
                    <a href="/who-we-are#our-team">
                    <i className="fas fa-arrow-right"></i>  Our Team
                    </a>
                  </li> */}
                  <li>
                    <a href="/blogs">
                      <i className="fas fa-arrow-right"></i> Blog
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us">
                      <i className="fas fa-arrow-right"></i> Contact Us
                    </a>
                  </li>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <a
                      style={{ marginRight: "10px" }}
                      target="_blank"
                      href="https://www.goodfirms.co/company/testdel-uk-ltd"
                    >
                      <img
                        style={{ width: "60px" }}
                        src="https://assets.goodfirms.co/badges/normal-badge/software-testing-companies.svg"
                        title=" Company"
                        alt="TestDel_Company"
                      />
                    </a>
                    <a
                      style={{ marginLeft: "10px" }}
                      target="_blank"
                      href="https://clutch.co/profile/testdel-uk#summary"
                    >
                      <img
                        style={{ width: "50px" }}
                        src="https://testdel.com/assets/img/company-logo/clutch.png"
                        title=" Company"
                        alt="TestDel_Company"
                      />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-2">
                <h3>What We offer</h3>
                <ul className="footer-quick-links">
                  <li>
                    <a href="/managed-testing-services">
                      <i className="fas fa-arrow-right"></i> Managed Testing
                      Services
                    </a>
                  </li>
                  <li>
                    <a href="/test-environment-management">
                      <i className="fas fa-arrow-right"></i> Test Environment
                      Management
                    </a>
                  </li>
                  <li>
                    <a href="/test-automation-services">
                      <i className="fas fa-arrow-right"></i> Test Automation
                      Services
                    </a>
                  </li>
                  <li>
                    <a href="/qa-consulting">
                      <i className="fas fa-arrow-right"></i> QA Consulting
                      Services
                    </a>
                  </li>

                  <li>
                    <a href="/testing-documentation">
                      <i className="fas fa-arrow-right"></i> Test Documentation
                    </a>
                  </li>
                  <li>
                    <a href="/security-testing">
                      <i className="fas fa-arrow-right"></i> Security Testing
                    </a>
                  </li>
                  <li>
                    <a href="/functional-testing">
                      <i className="fas fa-arrow-right"></i> Functional Testing
                    </a>
                  </li>
                  <li>
                    <a href="/non-functional-testing">
                      <i className="fas fa-arrow-right"></i> Non-Functional
                      Testing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-2">
                <h3>Services</h3>
                <ul className="footer-quick-links">
                  <li>
                    <a href="/web-applications-testing">
                      <i className="fas fa-arrow-right"></i> Web Applications
                      Testing
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-applications-testing">
                      <i className="fas fa-arrow-right"></i> Mobile Applications
                      Testing
                    </a>
                  </li>
                  <li>
                    <a href="/website-testing">
                      <i className="fas fa-arrow-right"></i> Website Testing
                    </a>
                  </li>
                  <li>
                    <a href="/desktop-applications-testing">
                      <i className="fas fa-arrow-right"></i> Desktop
                      Applications Testing
                    </a>
                  </li>
                  <li>
                    <a href="/cloud-solutions-testing">
                      <i className="fas fa-arrow-right"></i> Cloud Solutions
                      Testing
                    </a>
                  </li>
                  <li>
                    <a href="/accessibility-testing">
                      <i className="fas fa-arrow-right"></i> Accessibility
                      Testing
                    </a>
                  </li>
                  <li>
                    <a href="/internet-of-things-testing">
                      <i className="fas fa-arrow-right"></i> Internet Of Things
                      Testing
                    </a>
                  </li>
                  <li>
                    <a href="/performance-testing">
                      <i className="fas fa-arrow-right"></i> Performance Testing
                    </a>
                  </li>
                  <li>
                    <a href="/robotic-process-automation">
                      <i className="fas fa-arrow-right"></i> RPA Consultancy
                    </a>
                  </li>
                  <li>
                    <a href="/games-testing">
                      <i className="fas fa-arrow-right"></i> Games Testing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="single-footer-widget pl-2">
                <h3>Contact Us</h3>
                <ul className="footer-contact-info">
                  <li className="p-0">
                    {" "}
                    <p className="text-white">
                      <strong>UK</strong>
                      <br />
                      21 Woodfield Road, Hounslow<br />
                      Middlesex TW4 6LL, +44 2079936054
                    </p>
                  </li>
                  <li className="p-0">
                    <p className="text-white">
                      <strong>USA</strong>
                      <br /> 27181 SE 27TH ST, SAMMAMISH <br /> WA 98075, +1
                      425-200-6001
                    </p>
                  </li>
                  <li className="p-0">
                    <p className="text-white">
                      <strong>India</strong>
                      <br /> SCO 146-147, Sector 34
                      <br /> Chandigarh, +91 7291014242
                    </p>
                  </li>
                  <li className="p-0">
                    <ul className="social">
                      <li>
                        <a
                          target="_blank"
                          href="https://twitter.com/testdelgroup"
                          aria-label="testdelgroup twitter link"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/testdelgroup"
                          aria-label="testdelgroup facebook link"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/testdelgroup"
                          aria-label="testdelgroup instagram link"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/company/testdel/about"
                          aria-label="testdelgroup linkedin link"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://in.pinterest.com/testdelgroup"
                          aria-label="testdelgroup pinterest link"
                        >
                          <i className="fab fa-pinterest" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void();"
                          Style="font-size:18px;"
                          data-toggle="modal"
                          data-target="#joinModal"
                        >
                          Join our mailing list
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area p-2">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 col-md-6">
                <p>TestDel © {year}. ® All Rights Reserved</p>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <ul>
                  <li>
                    <a href="contact-us">Get in Touch</a>
                  </li>
                  <li>
                    <a href="privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="joinModal"
          data-backdrop="static"
          data-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Join our mailing list!</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body px-3">
                <MailingForm />
              </div>
            </div>
          </div>
        </div>
      </>
      {/* End Footer Area */}
      <div className="go-top">
        <i className="fas fa-arrow-up" />
      </div>
    </div>
  );
}

export default Footer;
