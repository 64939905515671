import React from 'react';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'

function Partners() {
  return (
    <div>
      {/* Start Partner Area */}
      <div className="partner-area ptb-100 pt-0">
          <div className="container">
            <div className="partner-slides owl-carousel owl-theme">
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/1.png`} alt="image" />
                  <img src={`assets/img/partner-image/1.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/2.png`} alt="image" />
                  <img src={`assets/img/partner-image/2.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/3.png`} alt="image" />
                  <img src={`assets/img/partner-image/3.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/4.png`} alt="image" />
                  <img src={`assets/img/partner-image/4.png`} alt="image" />
                </a>
              </div>

              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/5.png`} alt="image" />
                  <img src={`assets/img/partner-image/5.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/6.png`} alt="image" />
                  <img src={`assets/img/partner-image/6.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/7.png`} alt="image" />
                  <img src={`assets/img/partner-image/7.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/8.png`} alt="image" />
                  <img src={`assets/img/partner-image/8.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/9.png`} alt="image" />
                  <img src={`assets/img/partner-image/9.png`} alt="image" />
                </a>
              </div>
              {/* <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/10.png`} alt="image" />
                  <img src={`assets/img/partner-image/10.png`} alt="image" />
                </a>
              </div> */}
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/partner-image/11.png`} alt="image" />
                  <img src={`assets/img/partner-image/11.png`} alt="image" />
                </a>
              </div>
              
            </div>
          </div>
        </div>
        {/* End Partner Area */}
       
     </div>
  );
}

export default Partners;
