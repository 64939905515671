import React from "react";
import ContactForm from "../components/ContactForm";
import Counter from "../components/Counter";
import MetaTags from "../components/MetaTags";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Test Documentation";
const Description = "Test Documentation";
function TestingDocumentation() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Testing Documentation</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> <a href="/what-we-offer">What We Offer</a></li>
                  <li>Testing Documentation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="overview-area pt-5 pb-5 border-bottom">
        <div className="container">
          <div className="overview-box it-overview">
            <div className="overview-content">
              <div className="content">
                <h2>Testing Documentation is an important part of the testing process</h2>
                <p>
                Testing Documentation is an important part of the testing process. Comprehensive and well planned QA testing artefacts help to keep track of every step of testing processes, including test planning, test execution, and result assessment.
                </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Test plan
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Testing strategy
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Test scenarios
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Test cases
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Test Execution reports
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Test Exit Report
                    </span>
                  </li>
                </ul>
                <a
                  className="default-btn"
                  data-toggle="collapse"
                  href="#morecontent"
                >
                  Read More <span />
                </a>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img
                  src={`assets/img/what-we-offer/Testing-Documentation.png`}
                  alt="image"
                />
              </div>
            </div>
            <div class="collapse" id="morecontent">
              <div class="card card-body">
                <p>
                The testing cycle is a vital stage in the development of a successful application. In the end, testing will determine how positively the application will be launched to make sure application is bug-free, testing should be fully documented to provide resource control, allocation, and monitoring. Our testers make sure that every test case, bugs or execution report are documented. During the initiation phase, we do a detailed analysis, develop a relevant test strategy and provide a detailed overview of every error we have detected. In this way, we provide our client with a clear representation of what occurs in the project and what are the team priorities in the short and long run. While writing the documents we keep in mind that documents can be used repeatedly throughout for further development or phase.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5">
        <div className="container">
          <h2 className="mb-3 text-center">Testing Documents we prepare</h2>
          <p className="text-center">
            Appropriate documentation guarantees effective QA and checking.
            TestDel supports the relevant QA documentation for each project that
            describes and outlines the relevant testing activities.
          </p>

          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-check-shield" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Test Planning and Design</h3>
                <p>
                A test strategy is a complete planning document which contains the scope, approach, resources, schedule, entry/exit criteria and success criteria. We outline data requirements, create <div class="collapse" id="testplanning">
                test schedules and create perfect conditions for successful application performance.
                  </div><br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#testplanning"
                  >
                    Read More <span />
                  </a>
                
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-search-alt" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Requirements Traceability Matrix</h3>
                <p>
                  We deliver RTM documents to our client to verify that the
                  software is developed as per the mentioned requirements.
                  Business owners and the testing teams 
                  <div class="collapse" id="Traceability">
                  can easily keep
                  track of verified functionality and maintain testing
                  consistency from requirement traceability matrix.
                  </div>
               <br />{" "}
                  
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Traceability"
                  >
                    Read More <span />
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-bullseye" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Writing Test Cases From User Stories</h3>
                <p>
                Based on the requirements, customer expectations and coordination with project managers, market analysts and the production team.
                <br />{" "}
                 <div className="collapse" id="Writing">
                 We write test scenarios and test cases giving information about test data, execution preconditions, test steps, expected results.
                   </div> <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Writing"
                  >
                    Read More <span />
                  </a>
               
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-bug" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Bug Reports</h3>
                <p>
                  We create detailed bug reports of any flaw in a software
                  system which fails to perform its expected function. We then give priority of
                  bugs after discussing   <div class="collapse" id="Bug"> the defects with a business analyst or
                  end users. The project team will have full access to all the
                  bugs reported so they have a timely report on project problems
                  and found solutions.</div>
                  <br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Bug"
                  >
                    Read More <span />
                  </a>
                
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-check" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Test Checklists</h3>
                <p>
                  At TestDel, we make comprehensive testing specifications to
                  visually communicate to our clients whether the product meets
                  requirements set in the  <div class="collapse" id="Checklists">
                  statement of
                  work, whether all components work as intended, and whether the
                  software is responsive, intuitive and as per industry
                  standards.   </div><br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Checklists"
                  >
                    Read More <span />
                  </a>
                 
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-bar-chart-alt-2" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>User Acceptance Report</h3>
                <p>
                  Our team compiles User Acceptance Reports to document the
                  final stage of testing to explain various details and
                  activities about the testing performed, <div class="collapse" id="Report">
                    bugs reported, plans for fixing and releasing of bugs which
                    can be used for various decision meeting with respective
                    stakeholders like senior management, client etc.
                  </div>
                  <br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Report"
                  >
                    Read More <span />
                  </a>
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-facts-area gray-bg pt-5 pb-5">
        <div className="container">
          <h3 className="mb-3">Benefits of Test Documentation</h3>
          <div className="row">
            <div className="col-lg-12 col-sm-6 col-md-6 ">
              <div className="row border m-1 p-3">
                <div className="col-md-1" Style="align-self: center;">
                  <img src={`assets/img/services-icon/Increase-Traffic.png`} />
                </div>
                <div className="col-md-11">
                  <div>
                    <h6>Project certainty</h6>
                    <p>
                      A satisfied user means success for software. High
                      usability level increases the users’ productivity that
                      leads to business development and profit-boosting.With
                      well-defined documentation, organizations can fully
                      understand the work TestDel has completed. Thorough
                      documentation of past performance makes future processes
                      predictable and reliable.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-sm-6 col-md-6">
              <div className="row border m-1 p-3">
                <div className="col-md-1" Style="align-self: center;">
                  <img src={`assets/img/services-icon/The-Increase-in-confidence.png`} />
                </div>
                <div className="col-md-11">
                  <div>
                    <h6>Cost-efficient maintenance</h6>
                    <p>
                      Test documentation reduces or removes any uncertainties
                      about the testing activities, removes ambiguity which often
                      arises when it comes to the allocation of tasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6 col-md-6">
              <div className="row border m-1 p-3">
                <div className="col-md-1" Style="align-self: center;">
                  <img src={`assets/img/services-icon/To-Determine-priorities.png`} />
                </div>
                <div className="col-md-11">
                  <div>
                    <h6>Mutual trust and confidence</h6>
                    <p>
                      Test documentation reduces or removes any uncertainties
                      about the testing activities, removes ambiguity which often
                      arises when it comes to the allocation of tasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6 col-md-6">
              <div className="row border m-1 p-3">
                <div className="col-md-1" Style="align-self: center;">
                  <img src={`assets/img/services-icon/Quality-improving-and-monitoring.png`} />
                </div>
                <div className="col-md-11">
                  <div>
                    <h6>Continuous quality improvement</h6>
                    <p>
                      Testing reports clearly show the reliability of the
                      product. They fully display the functionality, its
                      possibilities, and imperfections. Documentation not only
                      offers a systematic approach to software testing, but it
                      also acts as training material to end users during user
                      acceptance testing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Counter/>
      <ContactForm />
    </div>
  );
}

export default TestingDocumentation;
