
import React from "react";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";
import ServicesCardBottom from "../components/ServicesCardBottom";
import Counter from "../components/Counter";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "What We Offer";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function WhatWeOffer() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
        {/* Start Main Banner Area */}
        <div className="it-banner">
        <div className="container-fluid border-bottom pt-5 pb-4">
           <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>What We Offer</h1>
                <ul><li><a href="/">Home</a></li><li>What We Offer</li></ul>
              </div>
            </div>
          </div>
        </div>
           </div>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container mt-50">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content">
                      <h2>QA department without the associated costs and headaches.</h2>
                      <p>TestDel provides comprehensive QA services as well as QA analysis to your company. Our primary goal as a UK top quality assurance firm is to provide exactly what we promise, in line with your budgets, technology and timeline. TestDel offers a wide range of quality assurance services for a variety of sectors, assisting businesses to ensures their products enter the market bug-free and with minimal testing setup costs and effort.
</p>
                      
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="it-banner-image mb-3 owl-carousel owl-theme">
                      <div className="animate-image">
                        <img src={require(`../assets/img/it-banner/10.png`)}  alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Shape Images */}
          <div className="shape-img2"><img src={`assets/img/shape/2.svg`}  alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
        <div className="shape-img11"><img src={`assets/img/shape/11.png`}  alt="image" /></div>
        <div className="shape-img12"><img src={`assets/img/shape/12.png`}  alt="image" /></div>
        </div>
        {/* End Main Banner Area */}
        {/* Start Services Area */}
        <section className="faq-area pt-5 pb-5 gray-bg">
        <div className="container">
          
          <div className="section-title">
            <h2>By testing type</h2>
            <p>A systematic approach with new ways of operating, including using automated test procedures, shift-left strategies for earlier discovering bugs and tools for automating and virtualizing test execution.</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
            <div className="content-box">
            
            <h4> <i class="bx bxs-badge-check"></i> Functional Testing</h4>
           
            <p className="accordion-content">We evaluate the system for adherence with functional requirements/specifications to assure the software is fit for purpose and end can do user do what is expected.</p>
            </div>
    
            <div className="content-box">
            
            <h4> <i class="bx bxs-badge-check"></i> Performance Testing</h4>
                    <p className="accordion-content">We measure your system performance under a certain workload to assess and evaluate system operating speed, scalability, and stability as per your KPIs, metrics and SLA’s.
 
 </p>
            </div>
                   
                    <div className="content-box">
            
                    <h4> <i class="bx bxs-badge-check"></i> Regression Testing</h4>
                   
                    <p className="accordion-content">We confirm that a recent code update has not adversely affected existing functionality or key customer journey to ensure that a system meets the defined standards.</p>
                    </div>
            
                
                  </div>
                 

                  <div className="col-lg-4 col-md-6">
                  <div className="content-box">
            
                 <h4> <i class="bx bxs-badge-check"></i>  Penetration Testing</h4>
                   
                    <p className="accordion-content" Style="display:block;">We analyse whether it is possible to acquire unauthorised access to corporate data and personal information in order to secure them and assure the system’s security.</p>
                    </div>
            
                    <div className="content-box">
            
                    <h4>  <i class="bx bxs-badge-check"></i>  UX / Usability Testing</h4>
                   
                    <p className="accordion-content">We verify measuring how easy and user-friendly a mobile app or application by observing real users as they attempt to complete tasks on it to ensure it matches user expectations.</p>
                    </div>
            
                    <div className="content-box">
            
                   <h4> <i class="bx bxs-badge-check"></i> GUI Testing</h4>
                   
                    <p className="accordion-content">We inspect graphical user interface such as menus, checkbox, buttons, colors, fonts, sizes, icons, content, and images of your application to avoid any user problems or undesirable experiences.</p>
                 
                    </div>
            
                 
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="content-box">
              <h4> <i class="bx bxs-badge-check"></i> Compatibility Testing</h4>
                   
                    <p className="accordion-content">We test if a solution works effectively in multiple browsers, operating systems, networks, devices, and so on to ensure that solutions for varied sectors work flawlessly under various scenarios.</p>
                    </div>
                    <div className="content-box">
              
                    <h4> <i class="bx bxs-badge-check"></i> Localization Testing</h4>
                   
                    <p className="accordion-content">We analyse solutions from both a multilingual and a cultural standpoint to ensure that the content and user interface of a solution are appropriate for the user’s language and location.</p>
                    
                    </div>
                    <div className="content-box">
              
                    <h4> <i class="bx bxs-badge-check"></i> Integration Testing</h4>
                   
                    <p className="accordion-content">We evaluate the compliance of a system or component in the interaction between integrated units to verify system modules do not interfere with each other in specific combinations.</p>
                 
                    </div>
          </div>
        </div>
        </div>
      </section>
  
        <section className="pt-100 pb-70 ">
          <div className="container">
            <div className="section-title">
              <h2>QA Services</h2>
              <p>Combining organised management and full adaptation to the needs of consumers with in-depth expertise of manual
and automation testing, TestDel helps to reduce the expense of producing the products and thereby raise revenues.
</p>
            </div>
            <div className="row">
            <div className="col-lg-4 col-sm-6">
                <div className="service-card-one bg-white center">
                  <div className="icon">
                    <i className="bx bx-laptop" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="managed-testing-services">Managed Testing Services</a>
                  </h3>
                  <p>A systematic approach with new ways of operating, including using automated test procedures, shift-left strategies for earlier discovering bugs and tools for automating and virtualizing test execution.</p>
                  <a href="managed-testing-services" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
           
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one bg-white center">
                  <div className="icon">
                    <i className="bx bx-conversation" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="test-environment-management">Test Environment Management</a>
                  </h3>
                  <p>We tackle organizations’ key testing challenges by utilizing smaller asset footprints to support test projects. We ensure the test environments are representative of the ‘live’ production situation, making the test fully valid.</p>
                  <a href="test-environment-management" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
           
                </div>
              </div>
            
            
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one bg-white center">
                  <div className="icon">
                    <i className="bx bx-mobile-alt" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="qa-consulting">QA Consulting</a>
                  </h3>
                  <p>TestDel offers QA consulting services by reviewing research and development processes, assessing team readiness to test and identifying product defects to ensure that the chosen QA approach meets quality criteria.</p>
                  <a href="qa-consulting" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
           
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one bg-white center">
                  <div className="icon">
                    <i className="bx bx-cart" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="testing-documentation">Test Documentation</a>
                  </h3>
                  <p>STesting Documentation is an important part of the testing process. TestDel well planned QA testing artefacts help to keep track of every step of testing processes, including test planning, test execution, and result assessment.</p>
                  <a href="testing-documentation" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
           
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one bg-white center">
                  <div className="icon">
                    <i className="bx bx-list-check" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="functional-testing">Functional Testing</a>
                  </h3>
                  <p>TestDel executes functional testing to ensure users can access the full functionality of the software solutions flawlessly. We validate that the software code behaves and produces outputs as specified in the requirements.</p>
                  <a href="functional-testing" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                   
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="service-card-one  bg-white center">
                  <div className="icon">
                    <i className="bx bxs-megaphone" />
                    <i className="bx bxs-badge-check check-icon" />
                  </div>
                  <h3>
                    <a href="non-functional-testing">Non-Functional Testing</a>
                  </h3>
                  <p>TestDel performed Non-functional tests under variant and unforeseen conditions to ensures users can access that your application is stable, robust and is able to handle load under extreme conditions.</p>
                  <a href="non-functional-testing" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                   
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Area */}
        <Counter/>
       {/* Start Projects Area */}
       <section className="projects-area ptb-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Case Study</span>
              <h2>We’ve Done Lot’s Of Work, Let’s Check Some From Here</h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="projects-slides owl-carousel owl-theme">
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/1.png`} alt="Web application Testing" />
                <a href="case-web-application-testing" target="_blank"> 
                <div className="projects-content">
                  <h3>Web application Testing</h3>
                  <span className="category">Application</span>
                </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/2.png`} alt="Website Testing" />
                <a href="case-website-testing" target="_blank">
                <div className="projects-content">
                  <h3>Website Testing</h3>
                  <span className="category">Website</span>
                </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img src={`assets/img/projects-image/3.png`}  alt="Automated document editing method" />
                <a href="case-automated-document-editing-method" target="_blank">
                <div className="projects-content">
                  <h3>Automated document editing method</h3>
                  <span className="category">Automated</span>
                </div>
                </a>
              
              </div>

              <div className="single-projects-box">
                <img src={`assets/img/projects-image/6.png`} alt="Desktop Application Testing" />
                <a href="case-desktop-application-testing" target="_blank">
                <div className="projects-content">
                  <h3>Desktop Application Testing</h3>
                  <span className="category">Desktop</span>
                </div>
                </a>
               
             
              </div>


              <div className="single-projects-box">
                <img src={`assets/img/projects-image/4.png`}  alt="Mobile App Testing" />
                <a href="case-mobile-app-testing" target="_blank">
                <div className="projects-content">
                  <h3>Mobile App Testing</h3>
                  <span className="category">Mobile App</span>
                </div>
                </a>
             
              </div>

              <div className="single-projects-box">
                <img src={`assets/img/projects-image/5.png`}  alt="Test Automation" />
                <a href="case-test-automation" target="_blank">
                <div className="projects-content">
                  <h3>Test Automation</h3>
                  <span className="category">Automation</span>
                </div>
                </a>
              </div>

            </div>
          </div>
        </section>
        {/* End Projects Area */}
       
      <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default WhatWeOffer;
