import React from "react";
import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Sidebar = () => {
  const navigate = useNavigate();

  function logout (){
  localStorage.clear()
  navigate("/admin/login");
  window.location.reload(false);
  }
  return (
    <div className="admin-sidebar">
      <div className="admin-top">
          <span className="admin-logo">TestDel</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/admin/home" style={{ textDecoration: "none" }}>
          <li>
            <DashboardIcon className="icon" />
            <span>Dashboard</span>
          </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/admin/contact-us" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Contact us</span>
            </li>
          </Link>
          <br/>
          <Link to="/admin/quote" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Quote</span>
            </li>
          </Link>
          <br/>
          <Link to="/admin/automation-service" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Automation</span>
            </li>
          </Link>
          <br/>
          <Link to="/admin/device-enquiry" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Device Enquiry</span>
            </li>
          </Link>
          <br/>
          <Link to="/admin/subscriber" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Subscriber</span>
            </li>
          </Link>
          <p className="title">ACTION</p>
          <Link to="/admin/compose" style={{ textDecoration: "none" }}>
            <li>
              <SendOutlinedIcon className="icon" />
              <span>Send mail</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li onClick={()=>logout()}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>

    </div>
  );
};

export default Sidebar;
