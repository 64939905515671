import React, { useState } from "react";
import "./list.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navbar"
import Datatable from "../../components/Datatable/Datatable"
import axios from "axios";
import { getHostUrl } from "../../config";

function Subscriber  (){
  const [subscriber,setSubscriber] =  useState([])

  React.useEffect(()=>{
    getSubscriber()
  },[])

  const getSubscriber = (e)=> {
      axios.get(getHostUrl('/api/user/subscriber'), {
      }).then((response)=>{
      if (response.status ==200 && response.data.success){
        setSubscriber(response.data.subscriber)
      }else{
        alert("Message failed to send.")
    }
    })
  }
   const userColumns = [
    { field: "name", headerName: "Name", width: 140 },
    {
      field: "email",
      headerName: "Email",
      width: 220,
    },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 150,
    },
    {
      field: "country",
      headerName: "Country",
      width: 140
    }
  ];
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable row={subscriber} col={userColumns}/>
      </div>
    </div>
  )
}

export default Subscriber