import React from 'react';

import ContactForm from '../components/ContactForm';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Non Functional Testing & Performance Testing Services in UK";
const Description="Our non functional testing service enables performance testing across operating systems, network, application and database.";
function NonFunctionalTesting() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
        {/* Start Page Title Area */}
        <div className="page-title-area page-title-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Non Functional Testing</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> <a href="/what-we-offer">What We Offer</a></li>
                  <li>Non Functional Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
     </div>
      {/* End Page Title Area */}
      {/* Start Why Choose Us Area */}
      <section className="why-choose-area pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                 <h2>We test usability, efficiency, maintainability & portability </h2>
                <p>
                To test the readiness of a system as per non-functional parameters which are never addressed by functional testing.
                </p>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" /> Scalability 
                  </h4>
                  <p>
                  TestDel scales, resources and devices to meet client expectations. Our test lab has over 250 mobile, tablets, desktop systems.
                    </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" /> On Demand 
                  </h4>
                  <p>
                  Hire us for the days that you like. From limited single tester contributions to comprehensive ongoing QA programmes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
                <img src={`assets/img/why-choose-img2.png`} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-area pt-5 pb-5 gray-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-image">
                <img src={`assets/img/what-we-offer/Non-Functional-Testing1.png`}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="why-choose-content">
                <span className="sub-title">NON FUNCTIONAL TEST DELIVERY</span>
                <h2>We work with you to: </h2>
                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" />Conduct a confidential process involving two or three clients and TestDel senior leaders to understand your project and a future state approach to transformation strategies.
                  </p>
                </div>
                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" /> Provide senior leadership POC covering process, coverage, deliverables, executions plan, critical gaps, and roadmap with recommended actions to increase your test capability in line with industry best practice.
                  </p>
                </div>

                <div className="features-text">
                  <p>
                    {" "}
                    <i className="flaticon-tick" /> 	Test management service providing a dedicated expert to lead and deliver all aspects of test management on your programme from initiation till Go Live.

</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End Why Choose Us Area */}
      <section className="overview-area pt-5 pb-5 ">
        <div className="container">
          <div className="overview-box it-overview">
            <div className="overview-content">
              <div className="content">
                <h2>Designing, engineering and delivering cutting edge shared services</h2>
                <p>
                Non-functional tests are performed to verify the attributes of the system such as memory leaks, performance or robustness of the system. TestDel provides services that proactively ensure IT solutions are robust, scalable, predictable and meet future business usage and throughout demand.
                </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Improve software quality 
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Increase test coverage
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Reduce cycle time                         
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      installation and monitoring
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Reduction of production risk
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Produce measurements
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img src={`assets/img/what-we-offer/outsource-software-testing.png`}
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-check-shield" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Compatibility Testing</h3>
                <p>
                Ensures that the product/software application executes as desired across a broad set of user/client systems and configurations.. <div class="collapse" id="testplanning">
                We test backward compatibility-ability to operate with previous software versions-and forward compatibility-ability to operate with future software versions. We test software solutions to verify whether they are compatible with a browser, operating system, software, hardware, networks, mobile devices, Database to ensure that solution is fit for purpose. 
                  </div><br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#testplanning"
                  >
                    Read More <span />
                  </a>
                
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-search-alt" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Performance Testing </h3>
                <p>
                By conducting performance tests, we do not detect bugs, we find bottlenecks of the system performance before the market release.. 
                  <div class="collapse" id="Traceability">
                  Performance Testing is done to provide stakeholders with information about their application regarding speed, stability, and scalability. More importantly, Performance Testing uncovers what needs to be improved before the product goes to market. Without Performance Testing, software is likely to suffer from issues such as: running slow while several users use it simultaneously, inconsistencies across different operating systems and poor usability.
                  </div>
               <br />{" "}
                  
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Traceability"
                  >
                    Read More <span />
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="service-card-one gray-bg">
                <div className="icon">
                  <i className="bx bx-bullseye" />
                  <i className="bx bxs-badge-check check-icon" />
                </div>
               <h3>Load Testing </h3>
                <p>
                Determines a system behaviour under real-life load conditions when multiple users access it simultaneously to verify your software handle what it is.. <div class="collapse" id="Writing">
                Designed to handle Load testing is a kind of Performance Testing which determines a system’s performance under real-life load conditions. This testing helps determine how the application behaves when multiple users access it simultaneously. Our testing services ensure that a given function, program, or system can simply be tested to handle what it is designed to handle.

                  </div><br />{" "}
                  <a
                    className="text-primary"
                    data-toggle="collapse"
                    href="#Writing"
                  >
                    Read More <span />
                  </a>
               
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="gray-bg pt-5 pb-5">
        <div className="container">
           <h2>Test Automation </h2>
          <p>We provide automation service that improves software quality by increasing test coverage and reducing cycle time. We will do tool analysis and automation set up and provide automation solutions that will support test activities across different stages of the testing life cycle.</p>
          <div className="row">
            <div className="col-lg-6 col-md-12">
             <h5>Benefits of Test Automation</h5>
             <p>Test Automation is the best way to increase the effectiveness, efficiency, and coverage of software testing. Most of our clients are either involved in continuous improvements in software or have to implement new releases recommended by DevOps. This requires continuous testing activity. The success of this continuous repeated testing heavily depends on automation testing to obtain immediate feedback on the business risks associated with a software release or new feature implementation. Test Automation helps feedback on the status of software quality early, reducing costs not only connected with testing but also development. For Instance, test automation reduces time dedicated to regression testing by over 50%, meaning achieving quality in a shorter time with reduced costs which increases the customer comfort level. </p>
             </div>
            <div className="col-lg-6 col-md-12">
              
            <img src={`assets/img/what-we-offer/test-process.png`} width="450"/>
            </div>
            <div className="col-lg-12">
            <h5>Test Automation at TestDel</h5>
             <p>We have successfully delivered over 25 automation projects. If the regular testing tools aren’t appropriate for your project, we have the skills either to modify them or to design a custom testing tool, especially for you. We have highly skilled engineers with excellent technical knowledge and experience in using latest software standards, tools, platforms, frameworks and technologies and we invest continuously in training and education, to be able to respond to any new technology challenges and demands from our clients. We are agile and ready to align based on project specificity, so our partner’s needs are always met with quality and best resources. We know both the art of matching people and the science of technology.</p>
           
            </div>
          </div>
        </div>
      </section>
      <div className="partner-area ptb-100 ">
      <div className="section-title mb-0">
          <h2>Tools We Utilize</h2>
          </div>
          <div className="container pb-3">
            <div className="partner-slides owl-carousel owl-theme">
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/cucumber.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/cucumber.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/katalon.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/katalon.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/seleniu.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/seleniu.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/Visaul-studio-code.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/Visaul-studio-code.png`} alt="image" />
                </a>
              </div>

              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/telerik.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/telerik.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/newrelic.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/newrelic.png`} alt="image" />
                </a>
              </div>
              <div className="single-partner-item">
                <a href="#">
                  <img src={`assets/img/what-we-offer/icon/intellitest.png`} alt="image" />
                  <img src={`assets/img/what-we-offer/icon/intellitest.png`} alt="image" />
                </a>
              </div>
            
              
            </div>
          </div>
        </div>


     </div>
  );
}

export default NonFunctionalTesting;
