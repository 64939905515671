import React from "react";

import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Our Faqs ";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";

function Faqs() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Main Banner Area */}
      <div className="it-banner">
        <div className="container-fluid border-bottom pt-5 pb-4">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h1>Frequently Asked Questions</h1>
                  <p>
                    (FAQs) regarding the Recruitment Standards, Application
                    Procedure, and Tier 2 Sponsorship
                  </p>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Faq's</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Shape Images */}
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
        <div className="shape-img11">
          <img src={`assets/img/shape/11.png`} alt="image" />
        </div>
        <div className="shape-img12">
          <img src={`assets/img/shape/12.png`} alt="image" />
        </div>
      </div>
      {/* End Main Banner Area */};
      <div className="faq-area pt-3 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-6">
              <h4 className=" my-5">1. Application Procedure</h4>
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item ">
                    <div
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-check" />
                      Can you tell me what to anticipate during the hiring
                      process?
                    </div>
                    <p
                      className="accordion-content active"
                      Style="display:block;"
                    >
                      <ul className="mb-3">
                        <li>
                          Upload or Send us your resume via email, and respond
                          to our test questions.
                        </li>
                        <li>
                          Take the test of our contextual strength to find out
                          how strong you are in your current scenario.
                        </li>
                        <li>Participate in our online interview.</li>
                        <li>
                          Join our group discussions, which will involve a
                          detailed explanation of our organization and role in a
                          one-on-one interview.
                        </li>
                      </ul>
                      Each step is dependent on the previous one's success.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Is it possible to get feedback at each level of the hiring
                      process?
                    </div>
                    <p className="accordion-content">
                      All candidates who attend our interview sessions receive
                      telephone or email feedback. We are unable to provide
                      feedback on the initial phases due to the vast number of
                      applications received. Please check your email
                      periodically for messages from our recruitment staff, as
                      we will let you know the conclusion of each phase of our
                      process as soon as possible.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      When I accept my offer, what happens next?
                    </div>
                    <p className="accordion-content">
                      The onboarding team will request the following documents
                      after you have accepted your offer:
                      <ul>
                        <li>
                          Please provide your bank account information so that
                          we can pay you!
                        </li>
                        <li>A filled out medical check-up</li>
                        <li>
                          Copies of your identification documents (passport or
                          national identity card )
                        </li>
                        <li>Certificates of Tax registration documents</li>
                        <li>
                          Security clearing certificate (we will let you know
                          the process).
                        </li>
                      </ul>
                      To reduce disruption in the onboarding process, please
                      deliver your paperwork to the team as soon as we request
                      it. It may appear like there is a lot to be doing, but
                      don't worry — the onboarding team will be there to help
                      you along the process and answer any concerns you may
                      have. Our Security Inspection team will also approach you
                      and work with you to obtain security clearance depending
                      on your country of residence. This is a non-intrusive
                      process, but it is required to verify that all of our new
                      joiners are qualified to work on our critical client
                      projects.
                    </p>
                  </li>

                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Do you accept CVs via the TestDel website?
                    </div>
                    <p className="accordion-content">
                      Yes, but It will be helpful if you please use our website
                      or Job portal to apply for the position you're interested
                      in.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      When will I be able to reapply if I am not selected?
                    </div>
                    <p className="accordion-content">
                      You have 12 months from the date of your initial request
                      to reapply.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      How can I get in touch with someone from TestDel's
                      recruitment team?
                    </div>
                    <p className="accordion-content">
                      Please email the TestDel recruitment team at
                      hr@testdel.com , if you can't find what you're looking for
                      on these sites.
                    </p>
                  </li>
                </ul>
              </div>
              <h4 className=" my-5">2. Recruitment Standards</h4>
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Can I apply to TestDel as an international student?
                    </div>
                    <p className="accordion-content">
                      Many of our positions require a security clearance,
                      therefore you must have lived in the UK for at least five
                      years and have spent no more than six months outside of
                      the country. In addition, you must have permanent leave to
                      remain. However. we do consider the international student
                      for some part-time vacancies.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      How do I let you know about my disability and the
                      accommodations I'll need during the selection process?
                    </div>
                    <p className="accordion-content">
                      Please include all pertinent information on your
                      application form. Please email hr@testdel.com , if you'd
                      like to chat with someone about your requirements.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Is there an assurance that I'll get good experience to
                      enhance my career prospects while working with TestDel
                    </div>
                    <p className="accordion-content">
                      You join TestDel as a permanent, full-time employee on the
                      first day. We work with many industries and in vast
                      dimensions. Your TestDel career will proceed and flourish
                      after few years of working with us.
                    </p>
                  </li>

                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Will I always be working in my current location?
                    </div>
                    <p className="accordion-content">
                      Most TestDel projects are based at client premises in
                      order to meet our clients' demands and facilitate
                      collaborative working. This means you'll be working away
                      from our office most of the time, and you'll be reimbursed
                      for all reasonable expenditures incurred while you're on
                      the client site.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      What other experiences do you appreciate outside typical
                      work experience within a consultant firm in the context of
                      the present environment? ­
                    </div>
                    <p className="accordion-content">
                      <ul>
                        <li>
                          Given that we live in such a digital age, applicants
                          should take advantage of online learning to improve
                          their knowledge while working with us{" "}
                        </li>
                        <li>
                          Applicants should stay up-to-date with industry
                          trends, highlight their studies and hobbies in their
                          applications, and connect their skills to the
                          programme and organization.
                        </li>
                        <li>
                          Applicants with little or no job experience should
                          look for modules within their degrees, extracurricular
                          activities, volunteer work, part-time work, and other
                          activities that can be connected with the Accelerate
                          Programme.
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </div>
              {/* <h2 className=" mt-5 mb-3">How to Get Tier 2 Sponsorship Jobs in the UK</h2>
              <p >As a foreign citizen, you may be wondering how to get a Tier 2 sponsoring job in the UK. Finding and securing Tier 2 sponsorship employment in the UK is the most difficult task for foreign citizens. First, keep in mind that you cannot apply for any job or firm; instead, you must apply to companies that have sponsor licenses for a Tier 2 visa. </p>
             <p >If you are from outside the UK, you must obtain a job offer from a licensed company in the UK to gain entry into the country for work, or if you are a student currently studying in the UK, you must obtain a job offer from a licensed company before your student visa expires, and your salary must meet the salary requirement for the job. The Tier 2 occupation list in the United Kingdom includes a diverse range of skilled occupations such as teaching, accounting, healthcare, and information technology. </p>
             <h4 className=" my-5">1. How Does it Execute to Obtain Sponsorship?</h4>
             <p>
             To work in the U.K., you must have a job offer from a company that is authorized to hire competent workers from outside the nation. You'll be granted a Certificate of Sponsorship (CoS) as evidence that the organization believes you're qualified and capable of completing the allocated assignment successfully and professionally. Finding such a job in the UK, although, is not as simple as it may appear. You may need to apply to one or more companies offering CoS to workers coming from outside the UK for work in order to find such prospects. Your immigration official can also assist you in obtaining sponsored employment in the United Kingdom. As a result, make sure you keep in touch with your attorney throughout the application procedure.
             </p>
             <h4 className=" my-5">2. How long does a Tier 2 visa take to obtain?</h4>
             <p>
             Your application may take up to 3 months to execute if you are applying from outside the UK. In order to resume work in the UK, you should apply at least three months ahead of time.  Your resuming date is shown on your sponsorship certificate. If you apply from outside the UK, you may receive a verdict on your Tier 2 visa application in as little as three weeks.
     </p>
             <h4 className=" my-5">3. How long may you stay in the UK with a Tier 2 Visa?
</h4>
             <p>
             You can stay and work in the UK for up to five years on a Tier 2 Visa, or for the length of time specified in your employment contract.

For those who are unsure where to look for Tier 2 sponsor positions, the following section is crucial:
      </p>
      
             <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Apply as soon as possible.
                    </div>
                    <p className="accordion-content">
                    Did you realize that many businesses begin hiring people a year ahead of time? Companies typically begin the employment process in the summertime of the next year. As a result, apply as soon as possible to maximize your chances of being chosen for the following year. If you are a student seeking work in the U.K., you can start seeking employment as early as your third year. If you have already earned your Master's degree, you should look for work as soon as you arrive in the country. 


                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Be aware of the platform where you can find companies willing to sponsor you.

                    </div>
                    <p className="accordion-content">
                    There are numerous internet platforms where you may locate organizations that sponsor students and those who wish to work in the country. Websites like CV-Library, and Indeed can assist you in locating open positions. Remember to look up those companies in the list of licensed sponsors. You can also use websites to organize and filter the Tier 2 list by industry. You won't have to sift through possibilities by hand (there are over 29000 companies registered on the Home Office's sponsored companies register).

                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Analysis and research on the Job Application Process in the U.K.
                    </div>
                    <p className="accordion-content">
                    The job application procedure in the United Kingdom is lengthy and consists of five steps. These are the processes that prominent enterprises and registered businesses take when employing employees.

You won't be able to successfully prepare for each stage unless you first learn about the requirements. These steps are as follows:
<ul>
  <li><strong>Writing a CV and cover letter:</strong> This phase entails preparing and submitting a unique application tailored to the industry or organization. So, at this stage, make sure you don't transmit anything generic.
</li>
<li><strong>Online tests:</strong> You can use online tests to practice for the real thing.
</li>

<li><strong>Teleconference call:</strong> You will be required to participate in an interviewing procedure that may take place over the phone or via video call.
</li>
<li><strong>Evaluation centre:</strong> You can also start preparing yourself by completing assignment centre tasks before attempting the real thing.
</li>

<li><strong>Last interview:</strong> Do your best during the final interview because your chances of getting approved for a job visa application are heavily reliant on this step.
</li>
</ul>

                    </p>
                  </li>

                  <li className="accordion-item ">
                    <div className="accordion-title" href="javascript:void(0)">
                      <i className="fas fa-check" />
                      Build rapport and be in contact with your immigration attorney.


                    </div>
                    <p className="accordion-content">
                    If finding sponsored job openings on your own appears challenging, you should get legal counsel from an immigration attorney. Not only can your lawyer advise you on possible sponsored job prospects, but he or she can also assist you with the full job application process.

                    </p>
                  </li>
                 </ul>
              </div>
              
              <h4 className=" my-5">4. What to Consider About Before Filling Out and Submitting a Job Application?
</h4>
<ul>
  <li>Don't apply for any job at random just because it's available. You can only work for companies that are regulated and provide a Certificate of Sponsorship (CoS) to skilled workers from outside the UK.</li>
  <li>A list of Tier 2 registered companies and sponsors can be found on the Home Office website.</li>
  <li>Before your study visa expires, you must have a work offer letter from a licensed employer.</li>
  <li>Your salary must correspond to the market rate for the position you've been offered.</li>

</ul>
<p>Keep in mind this when compiling your CV and drafting a cover letter for a job application: make sure it is competent and appropriate for the job and company.</p>

<p>TestDel welcomes applications from all candidates and will look to provide sponsorship to those who require a visa to live and work in the UK, subject to meeting all the necessary Home Office requirements.</p>

<p>We at Testdel always look for new talent in the software testing domain. We are also approved If you hold a valid student or work visa we may be able to switch to a Tier 2 (General) visa sponsored by TestDel from within the UK. TestDel has a set number of Certificate of Sponsorship applications that can be made each year. If this limit is reached or other Home Office requirements can not be met, it may prevent us from being able to sponsor and therefore employ you.
</p>



<h4>Tier 2 (General) visa requirements</h4>
<p>There are minimum salary requirements that need to be met in order to be eligible for a Tier 2 (General) visa. Full details on visa restrictions can be found on the Home Office website and immigration rules are subject to change.
</p>
<p>The above information is correct as of September 2020.</p>

<h4>Offer of employment</h4>
<p>Any employment offer made by TestDel is contingent upon you obtaining and maintaining the right to work and reside legally in the UK. You are required to notify us immediately if your immigration status changes after you have submitted your application.
</p>
<h4>Resident Labour Market Test (RLMT)</h4>
<p>The RLMT requires us to demonstrate that there are no suitably qualified settled workers to fill the vacancy and we need to satisfy this to meet Home Office requirements. If we are unable to do this we will not be able to offer sponsorship.

</p>

<h4>
Own right to work in the UK</h4>
<p>
We welcome applications from candidates already eligible to work in the UK or those that can apply for a visa granting permission to work in the UK (e.g. dependent visas, ancestry, etc.) that do not require sponsorship from TestDel. You will be asked to share proof of this prior to receiving an offer of employment.
</p>
<p>In addition, we would like to make you aware that if you are made an offer of employment with TestDel you will need to remain on your current visa / permit. If for any reason your visa status changes, we will be unable to offer you sponsorship in the future. In addition, the firm would not fund any subsequent non-sponsored visa applications - you will be solely responsible for paying and maintaining your own right to work in the UK. In some instances, we may not be able to offer you a permanent contract.
</p>
<p>If you're unsure of your right to work in the UK without sponsorship, visit the Home Office website for more information: <a href="https://www.gov.uk/government/organisations/uk-visas-and-immigration" target="_blank">https://www.gov.uk/government/organisations/uk-visas-and-immigration</a>
</p>


*/}
             
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
