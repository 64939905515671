import React from "react";

import MetaTags from "../components/MetaTags";
import ContactForm2 from "../components/ContactForm2";
import Counter from "../components/Counter";
import Partners from "../components/Partners";
import ServicesCardBottom from "../components/ServicesCardBottom";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Test Automation Services UK ";
const Description =
  "TestDel offers functional software testing services for your website, mobile apps and software products. We safeguard bug free software application.";
function TestAutomationServices() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />;
      <>
        <div className="page-title-area page-title-bg2">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Test Automation Services</h2>
                  <ul>
                    <li>
                      <a href="https://www.testdel.com/">Home</a>
                    </li>
                    <li>
                      {" "}
                      <a href="/services/software-testing-services">Services</a>
                    </li>
                    <li>Test Automation Services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-img2">
            <img src="assets/img/shape/2.svg" alt="image" />
          </div>
          <div className="shape-img3">
            <img src="assets/img/shape/3.svg" alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img7">
            <img src={`assets/img/shape/7.png`} alt="image" />
          </div>
          <div className="shape-img8">
            <img src={`assets/img/shape/8.png`} alt="image" />
          </div>
          <div className="shape-img9">
            <img src={`assets/img/shape/9.png`} alt="image" />
          </div>
          <div className="shape-img10">
            <img src={`assets/img/shape/10.png`} alt="image" />
          </div>
        </div>
        <section className="overview-area py-2">
          <div className="container">
            <div className="overview-box it-overview">
              <div className="overview-content">
                <div className="content">
                  <h2> On-Demand Automation Testing Services </h2>
                  <p>
                    Our Experienced automation test engineers help your company
                    succeed by automating test releases, shortening
                    time-to-market, lowering costs, increasing test coverage,
                    testing across various platforms, and improving end-product
                    quality. Many projects have been saved from failure because
                    to our software test automation approach, which addresses QA
                    bottlenecks at breakneck speed.
                  </p>
                  <ul className="features-list">
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        Web App Test Automation
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        Mobile App Test Automation
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        Desktop App Test Automation
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        Continuous Testing
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        Performance and Load Testing
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bx bxs-badge-check" />
                        API Testing
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="overview-image">
                <div
                  className="form-content mt-0 mb-5 mt-5"
                  style={{ backgroundColor: "#000" }}
                >
                  <div className="form-header ">
                    <h2 className="text-white form-title">
                      Your Go-Live date is safe <br />
                      with us
                    </h2>
                  </div>
                  <ContactForm2 />
                  <span id="success" />
                </div>
              </div>
              <div className="collapse" id="morecontent">
                <div className="card card-body">
                  <p>
                    {" "}
                    Delivering an end-to-end service for a long time helps us to
                    create competitive value by transforming research processes
                    and environments. Our structured managed service is designed
                    to include the best-in-class, scalable testing services that
                    reduce your overall ownership costs. Thanks to our
                    investment and experience in methodologies, systems,
                    offshore/nearshore model, quality improvement and creativity
                    which offer the right solution for your business. In
                    addition to addressing the functional complexities of your
                    requirements, our structured test team allow you to
                    concentrate on the entire customer journey with a
                    concentrate on usability, reliability, performance, security
                    and end-user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-area pt-5 pb-5 gray-bg">
          <div className="container">
            <div className="section-title">
              <h2>Assessment of Readiness</h2>
              <p>
                Assisting you in determining the best technique, tooling, and
                skills required to successfully develop a test automation plan
                in order to support your Agile and DevOps adoption
              </p>
            </div>
            <div className="text-center">
              <h2 className>Tool We use</h2>
              <h4 className>Frameworks for automation and testing:</h4>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <div className="shadow ess-box  p-3">
                  <h3 className>Java </h3>
                  <ul className="p-0" style={{ listStyle: "none" }}>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>WebUI:</b>{" "}
                      TestNG/JUnit + Maven/Gradle + Selenium + Allure + Cucumber
                      (optional, if needed)
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>Mobile:</b>
                      &nbsp;Appium + Maven/Gradle + Allure + Cucumber (if
                      needed)
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>API:</b> Rest
                      Assured + Allure
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="shadow ess-box p-3 mb-3">
                  <h3 className>C#</h3>
                  <ul className="p-0" style={{ listStyle: "none" }}>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>WebUI:</b> NUnit +
                      Selenium + Allure
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b> Mobile:</b>{" "}
                      Appium + NUnit (any additional frameworks based on
                      Appium); .NetCore, XamarinUiTests (for Xamarin projects)
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>API:</b> .Net Core
                      + RestSharp+ Newtonsoft.Json
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="shadow ess-box  p-3">
                  <h3 className>JavaScript/TypeScript</h3>
                  <ul className="p-0" style={{ listStyle: "none" }}>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>WebUI:</b>{" "}
                      WebDriverIO + Allure; TestCafe; Cypress.io; Protractor
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>Mobile:</b>{" "}
                      WebdriverIO + Allure; Protractor
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>API:</b> Node.js +
                      Cucumber + Allure/Cucumber Reporter
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className=" shadow ess-box p-3">
                  <h3 className>Python</h3>
                  <ul className="p-0" style={{ listStyle: "none" }}>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>WebUI:</b>
                      &nbsp;PyTest + Selenium
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>Mobile:</b> Appium
                      + Allure
                    </li>
                    <li>
                      {" "}
                      <i className="bx bxs-badge-check" /> <b>API:</b> requests
                      + Behave + PytestBDD + Allure
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="py-5">
          <h4 style={{ textAlign: "center" }}>
            OUR TEAM MEMBERS PROUDLY WORKED WITH
          </h4>
          <div>
            <Partners />
          </div>
        </div>
        <section className="services-area bg-F4F7FC pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon">
                    <img
                      src={require(`../assets/img/testing-services/automation-services.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Automated testing tools</a>
                  </h3>
                  <p>TestComplete, EggPlant, Ranorex Studio, Ghost Inspector</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-00aeff">
                    <img
                      src={require(`../assets/img/testing-services/cell-phone.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Cloud device farms</a>
                  </h3>
                  <p>BrowserStack, SauceLabs, AWS Device Farm, Kobiton</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-f78acb">
                    <img
                      src={require(`../assets/img/testing-services/continuous-updating.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Continuous Testing tools</a>
                  </h3>
                  <p>
                    TeamCity, GitLab CI, GitHub Actions, Jenkins, Travis CI,
                    Bamboo, CircleCI.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-cdf1d8">
                    <img
                      src={require(`../assets/img/testing-services/report_document.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Reporting Frameworks</a>
                  </h3>
                  <p>Mocha, ReportNG,&nbsp;Cucumber HTML Reporter</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-c679e3">
                    <img
                      src={require(`../assets/img/testing-services/back-end.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Integrated Development Environment (IDE)</a>
                  </h3>
                  <p>
                    AndroidStudio, Visual Studio, Eclipse, IntelliJ IDEA, etc.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <div className="icon bg-eb6b3d">
                    <img
                      src={require(`../assets/img/testing-services/build.png`)}
                    />
                  </div>
                  <h3>
                    <a href="#">Build Management Tools</a>
                  </h3>
                  <p>MSBuild, Maven, Gradle, ApacheAnt</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="why-choose-area pb-5 pt-5">
          <div className="container ">
            <div className="row row-align-center">
              <div className="col-lg-6 col-md-12 pt-5">
                <h2>Test Automation Advantages</h2>
                <ul className="p-0" style={{ listStyle: "none" }}>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" />
                    Manual testers can save time and money by automating their
                    processes
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Improve the quality of
                    the software programme
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Regression testing
                    takes less time
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Manual tasks no longer
                    required
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Reuse test procedures
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Early detection of
                    issues in the software development life cycle
                  </li>
                  <li>
                    {" "}
                    <i className="bx bxs-badge-check" /> Greater test coverage,
                    accuracy and scope{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12">
                <img
                  src={`assets/img/what-we-offer/Manage-services-m.png`}
                  alt="image"
                  width="100%"
                />
              </div>
            </div>
            <div className="row  py-5">
              <div className="why-choose-area mb-3 text-center ">
                <h3 className="text-center">Automation Process</h3>
                <p>
                  Testdel delivers a cost-effective solution–a clever blend of
                  Manual and Automation QA–to eliminate the risks and ensure the
                  continual quality of your product. This decreases the total
                  cost of the project and improves automation efficiency by
                  preventing QA Automation Engineers from repeating work. In
                  exchange, you'll get cost- and time-effective automation that
                  works.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 ">
                <div className="card box-auto">
                  <div className="number-box">
                    <h1>1</h1>
                  </div>
                  <h3>PREPARATION</h3>
                  <p>
                    Plan test tools, code language, frameworks, test cases to
                    automate, estimation Compare and assess automation software
                    Proof-of-concept demonstration
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card box-auto">
                  <div className="number-box">
                    <h1>2</h1>
                  </div>
                  <h3>STAGE OF EXECUTION</h3>
                  <p>
                    Develop test scripts, test Data Test execution Set up the
                    groundwork for automation by implementing a testing
                    framework Combine frameworks and tools Continuous testing
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card box-auto">
                  <div className="number-box">
                    <h1>3</h1>
                  </div>
                  <h3>SUPPORT</h3>
                  <p>
                    Examine and update current automation projects Prepare
                    script run instructions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <ServicesCardBottom />
    </div>
  );
}

export default TestAutomationServices;
