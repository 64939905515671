import React from 'react';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Automated document editing method";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function Automateddocument() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
       {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Automated document editing method</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="case-study">Use Cases</a>
                  </li>
                  <li>Automated document editing method</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`} alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </div>
      {/* End Page Title Area */}
         {/* Start Project Details Area */}
         <section className="project-details-area ptb-100">
        <div className="container">
          <div className="row">
         
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work3.png`} alt="image" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work3_1.png`} alt="image" />
                </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="projects-details-desc">
                <h2>Client </h2>
                <p>The contemporary governmental IT-Infrastructure is built to conduct operations involving a wide number of internal IT-systems. In order to allow business processes to access multiple systems, Microsoft SharePoint is used to connect internal systems. 
The business process can not be executed when there are defects or issues in the SharePoint , even though each sub-system is functioning correctly. This is why it is critically important to check SharePoint functionality. We were approached by government agency, which records physical bodies in the insurance system and issues the supporting documentation.
</p>
<div className="text-center mb-5 p-3 border gray-bg ">
<h3 >Got a project in mind?</h3>
<p>There is no better place than TestDel to get a QA solution. 
Find out what our team can do for you and drop us a line. 
</p>
<a href="get-a-free-quote" className="btn mt-0 default-btn">Get Quote</a>
</div>

<p>When there are defects in the Sharepoint, the business process can’t be executed. That’s why SharePoint testing  is critically important.</p>
<h2> Technical challenges with implementation of the test procedure.</h2>
           
                <div className="features-text">
                  <p>The project was difficult, because the customer has set the following criteria for the testing instrument:</p>
                <ul Style="list-style: decimal;">
                    <li><p>Sending batch requests to the Sharepoint testing with the property to provide up to 50 parallel threads. This operation imitates the process of providing help with filling in tax forms, which is called information format and logical control (FLC). While executing FLC, the system makes multiple requests to other systems and business processes.</p></li>
              <li><p>The programming language was Python, because the instrument maintainer on the customer side mostly work with Python. o Web-interface should be understandable for test specialists who have got neither the automated testing experience nor the UNIX administration knowledge. The interface should allow auto-test development, execution and update, as well as choosing the test environment.</p></li>
            <li><p>The probability of regular and quick delivery for the customer network and the system developer with limited workload. This necessity is triggered by periodic changes to the SharePoint, as well as the changes to accessed systems.</p></li>
                </ul>
                </div>
                <h2>Testdel  solution</h2>
             
                <div className="features-text">
                    <p>The project deployment variant selected is based on the DevOps Container Visualization method and is implemented using visual studio from microsoft. Docker is a framework for development, deployment and execution that allows the application to be packed in the virtual container, including its environment and dependencies, which can be ported to and executed on any device where Microsoft visual studio is installed.</p>
                  <p>This solution allowed the portability of the automation instrument to be achieved. The instrument can be deployed even without the Internet connection with just one instruction.</p>
                </div>
                <h2> Results Achieved </h2>
              
                <div className="features-text">
                  <p>The solution built allows one-click deployment of the automated test instrument and all necessary components from scratch. A customer side specialist selects the appropriate tests and test environment in the web interface, then they start the test run. All tests are built via the graphical user interface and modified. It autogenerates the specific portion of each test data. Earlier the customer had to manually update the test data for each run, which needed several hours of manual work. </p>
                <strong><i className="flaticon-tick" /> Developed by Performance Lab, the solution allowed the customer to:</strong>
                <ul>
                    <li>  <p>  Optimizing the testing cycle by automating and conducting tests; reducing costs and minimising the risk of human error when installing and upgrading the customer application testing infrastructure.</p> </li>
            <li><p> By executing many simple scripts, customer specialists can deploy and upgrade the testing infrastructure easily, without developing / editing the instructions for administration. The job which used to take one day now takes less than half an hour.</p></li>
                </ul>
                </div>
                <div className="project-details-info">
                  <div className="single-info-box">
                    <h4>Client</h4>
                    <span>Mutual Fund     </span>
                  </div>
                  <div className="single-info-box">
                    <h4>Category</h4>
                    <span>Finance</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Date</h4>
                    <span>2017</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Share</h4>
                    <ul className="social">
                      <li><a href="#" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Project Details Area */}
</div>
  );
}

export default Automateddocument;
