import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Banner from "./components/Banner";
import Counter from "./components/Counter";
import Partners from "./components/Partners";
import MetaTags from "./components/MetaTags";
import { getHostUrl } from "./config";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";

const Title = "Software Testing and QA Services Company - TestDel";

const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";

const Loader = () => <div>Loading...</div>;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      dataRoute: "https://wpblog.testdel.com/wp-json/wp/v2/posts",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <MetaTags
          title={Title}
          description={Description}
          url={getHostUrl(window.location.pathname)}
        />
        <Banner />

        {/* Start Featured Services Area */}
        <section className="featured-services-area pt-5 pb-5">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Our Services</span>
              <h2>Our Featured Services</h2>
              <p>
                Ensure quality never gets left out of your critical
                applications. Providing an extensive range of fully managed test
                services from our USA, UK and India test labs.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <div className="icon">
                    <i className="bx bx-calculator" />
                  </div>
                  <h3>Functional Testing</h3>
                  <p>
                    It ensures quality of the <br /> application to enable
                    informed decisions on the sustainability of its release to
                    production.
                  </p>
                  <a href="/functional-testing" className="default-btn">
                    Functional Testing
                    <span />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <div className="icon color-facd60">
                    <i className="bx bx-calculator" />
                  </div>
                  <h3>Non-Functional</h3>
                  <p>
                    Non-functional tests performed to verify the attributes of
                    the system such as memory leaks, performance or robustness
                    of the system.
                  </p>
                  <a href="/non-functional-testing" className="default-btn">
                    Non-Functional Testing <span />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div className="single-featured-box">
                  <div className="icon color-1ac0c6">
                    <i className="bx bx-lock" />
                  </div>
                  <h3>Security Testing</h3>
                  <p>
                    Our tester identifies the threats application under test and
                    measures its potential vulnerabilities, to ensure that the
                    application does not stop functioning.
                  </p>
                  <a href="/security-testing" className="default-btn">
                    Security Testing <span />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Featured Services Area */}
        <div className="pt-0 pb-5">
          <div className="section-title">
            <h2 className="text-center">Our Team Served</h2>
          </div>
          <Partners />
        </div>

        {/* Start Overview Area */}
        <section className="overview-area ptb-100 pt-0">
          <div className="container">
            <div className="section-title">
              <h2>let's focus on quality</h2>
              <p>
                TestDel is a leading international company that provides a full
                range of software testing services.
              </p>
            </div>
            <div className="overview-box">
              <div className="overview-content">
                <div className="content">
                  <h2>Here's How We Can Help Transform Your QA Experience</h2>
                  <div className="features-text">
                    <h4>
                      <i className="flaticon-tick" /> Customer Satisfaction
                    </h4>
                    <p>
                      Customer satisfaction is a metric used to quantify the
                      degree to which a customer is happy with a product,
                      service, or experience. We help our clients to improve
                      their quality assurance strategies to ensure; they deliver
                      exceptional business results.
                    </p>
                  </div>
                  <div className="features-text">
                    <h4>
                      <i className="flaticon-tick" />
                      Flexible cooperation
                    </h4>
                    <p>
                      You can trust our team of experienced and highly qualified
                      professionals from reputed technology and management
                      institutions who are domain experts from various
                      industries including Retail, Aviation, Utility, and
                      Banking.
                    </p>
                  </div>
                </div>
              </div>
              <div className="overview-image">
                <div className="image">
                  <img src={`assets/img/features-image/6.png`} alt="Testdel " />
                </div>
              </div>
            </div>
            <div className="overview-box">
              <div className="overview-image">
                <div className="image">
                  <img src={`assets/img/features-image/5.png`} alt="image" />
                </div>
              </div>
              <div className="overview-content">
                <div className="content right-content">
                  <h2>
                    Benefits you have with Testdel software testing services
                  </h2>
                  <p>
                    Providing an extensive range of fully managed test services
                    from our India and UK based test labs. Ensuring quality
                    never gets left out of your critical applications.
                  </p>
                  <ul className="features-list">
                    <li>
                      <span>
                        <i className="fas fa-check" /> Efficient work
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> Faster delivery
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> Secure test lab
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> QA On-Demand
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> Full Transparency
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> Flexible cooperation
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="fas fa-check" /> Customer satisfaction
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="overview-box">
              <div className="overview-content">
                <div className="content">
                  <h2>We Are The Next Generation Of The Testing World</h2>
                  <p>
                    Do you want to ensure quicker product launches and higher
                    quality software implementations by outsourcing your QA and
                    software development activities to one of the best software
                    testing company?
                  </p>
                  <div className="features-text">
                    <h4>
                      <i className="flaticon-tick" />
                      Unique Test Accelerator Framework
                    </h4>
                    <p>
                      We are expert in providing end-to-end software testing and
                      Quality Assurance services for many industries. Our highly
                      experienced software test engineers are experts in all
                      types of software testing.
                    </p>
                  </div>
                  <div className="features-text">
                    <h4>
                      <i className="flaticon-tick" />
                      Reduce Cost of Quality
                    </h4>
                    <p>
                      We build our reliable and highly cost-effective software
                      testing services around skilled, rapid exploratory testing
                      methodologies and we offer end-to-end product release
                      testing services, automation testing, web application
                      testing, mobile app testing, usability testing and many
                      more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="overview-image">
                <div className="image">
                  <img src={`assets/img/features-image/4.png`} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Overview Area */}
        {/* Start Features Area */}
        <section className="features-area ptb-100 pt-0">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">OUR SOLUTIONS</span>
              <h4>
                We offer holistic, end-to-end testing solutions that reduce
                costs & increase efficiency to maintain continuity of business.
              </h4>
            </div>
            <div className="tab features-list-tab">
              <ul className="tabs">
                <li>
                  <a href="#" className="bg-fa7070">
                    <i className="flaticon-digital-marketing" />
                    <span>Web Applications</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="bg-00aeff">
                    <i className="flaticon-architecture" />
                    <span>Mobile Applications</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="bg-c679e3">
                    <i className="flaticon-digital-marketing" />
                    <span>Website </span>
                  </a>
                </li>
                <li>
                  <a href="#" className="bg-eb6b3d">
                    <i className="flaticon-analytics" />
                    <span>Desktop Application</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="flaticon-data" />
                    <span>IoT </span>
                  </a>
                </li>
                <li>
                  <a href="#" className="bg-f78acb">
                    <i className="flaticon-research" />
                    <span>Games </span>
                  </a>
                </li>
              </ul>
              <div className="tab_content">
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>
                          TestDel is an end-to-end software solutions company
                          founded by leaders from QA and testing industry.
                        </h2>
                        <p>
                          We specialize in ensuring that your application is
                          free of issues. We want your users to have a
                          friction-less experience. Talk to us today to know
                          which one of our services will be the best fit for
                          your needs.
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Working remotely
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Seamless
                              Integration
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Obtain earlier
                              feedback
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> No Contractual
                              Minimums
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Using AI and
                              Automation
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Dedicated Team
                            </span>
                          </li>
                        </ul>
                        <a
                          href="/web-applications-testing"
                          class="btn default-btn"
                        >
                          Web Applications Testing
                        </a>
                      </div>
                    </div>
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/1.png`}
                          alt="Web Application"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/2.png`}
                          alt="Mobile Applications Testing"
                        />
                      </div>
                    </div>
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>Mobile Applications Testing</h2>
                        <p>
                          Creating high-quality mobile applications that the
                          market is demanding today is a huge challenge. TestDel
                          testers ensure that applications function correctly
                          and perform well across different mobile devices.
                          TestDel plays a very important role when testing an
                          application developed by an external provider or when
                          it’s necessary to test an app’s quality before handing
                          it over to the end users.
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Functionality
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Mobile Security
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Performance and UX
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Regression Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Load Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Compatibility
                              Testing
                            </span>
                          </li>
                        </ul>
                        <a
                          href="/mobile-applications-testing"
                          class="btn default-btn"
                        >
                          Mobile Applications Testing
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>Professional Website Testing</h2>
                        <p>
                          Our testers use finest bug fix techniques so
                          conversion rates of your website achieve the highest
                          level. We deliver full website testing service for any
                          type and size of business. We adopt automated, manual
                          website and various approaches to find bugs.
                          Collaborating with us means we will test your website
                          to achieve fast responsive, intuitive and security
                          goals to increase your organization revenue.
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" />
                              Usability Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Functionality
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" />
                              Database Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Security Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Compatibility
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" />
                              Performance Testing
                            </span>
                          </li>
                        </ul>
                        <a href="/website-testing" class="btn default-btn">
                          Website Testing
                        </a>
                      </div>
                    </div>
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/3.png`}
                          alt="Professional Website Testing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/8.png`}
                          alt="Desktop Applications Testing"
                        />
                      </div>
                    </div>
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>Desktop Applications Testing</h2>
                        <p>
                          TestDel is the team of award-winning certified,
                          testing professionals who care about quality. We are
                          customer passionate and put your business priorities
                          first. To make our partnership even more productive we
                          ensure our clients with transparency of the testing
                          process, and strictly follow SLA We will make sure
                          your software works on the most standard types of
                          desktops and operating systems.
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Functionality
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Load Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Security Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Usability Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Regression Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Compatibility
                              Testing
                            </span>
                          </li>
                        </ul>
                        <a
                          href="/desktop-applications-testing"
                          class="btn default-btn"
                        >
                          Desktop Applications Testing
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>Internet Of Things Testing</h2>
                        <p>
                          Our TestDel team helps organizations make a prudent
                          and assured step towards the version of innovative,
                          hands-on, and valuable Internet of Things solutions.
                          Our IoT Testing team taking into account complex
                          architecture, different data models, and accuracies of
                          IoT environments.{" "}
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" /> IoT Integration
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> IoT Security
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Usability Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> IoT Performance
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> IoT Compatibility
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> IoT Reliability
                              Testing
                            </span>
                          </li>
                        </ul>
                        <a
                          href="/internet-of-things-testing"
                          class="btn default-btn"
                        >
                          IoT Testing
                        </a>
                      </div>
                    </div>
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/9.png`}
                          alt="Internet Of Things Testing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-image">
                      <div className="image">
                        <img
                          src={`assets/img/features-image/10.png`}
                          alt="Games Testing"
                        />
                      </div>
                    </div>
                    <div className="overview-content">
                      <div className="content">
                        <span className="sub-title">Define Your Choices</span>
                        <h2>Games Testing</h2>
                        <p>
                          TestDel is a team of certified, award-winning
                          specialists which counts extensive experience in
                          testing. We are serving businesses of any size to
                          accomplish success by truly flawless delivery. We are
                          extreme quality conscious and this drives us not to
                          pass any game without exhaustive testing.{" "}
                        </p>
                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Functional Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Usability Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Regression Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Compatibility
                              Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Security Testing
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick" /> Load Testing
                            </span>
                          </li>
                        </ul>
                        <a href="/games-testing" class="btn default-btn">
                          Games Testing
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-img7">
            <img src={`assets/img/shape/7.png`} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
        </section>
        {/* End Features Area */}
        <Counter />
        {/* End Fun Facts Area */}
        {/* Start Projects Area */}
        <section className="projects-area ptb-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Case Study</span>
              <h2>We’ve Done Lot’s Of Work, Let’s Check Some From Here</h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="projects-slides owl-carousel owl-theme">
              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/1.png`}
                  alt="Web application Testing"
                />
                <a href="case-web-application-testing" target="_blank">
                  <div className="projects-content">
                    <h3>Web application Testing</h3>
                    <span className="category">Application</span>
                  </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/2.png`}
                  alt="Website Testing"
                />
                <a href="case-website-testing" target="_blank">
                  <div className="projects-content">
                    <h3>Website Testing</h3>
                    <span className="category">Website</span>
                  </div>
                </a>
              </div>
              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/3.png`}
                  alt="Automated document editing method"
                />
                <a
                  href="case-automated-document-editing-method"
                  target="_blank"
                >
                  <div className="projects-content">
                    <h3>Automated document editing method</h3>
                    <span className="category">Automated</span>
                  </div>
                </a>
              </div>

              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/6.png`}
                  alt="Desktop Application Testing"
                />
                <a href="case-desktop-application-testing" target="_blank">
                  <div className="projects-content">
                    <h3>Desktop Application Testing</h3>
                    <span className="category">Desktop</span>
                  </div>
                </a>
              </div>

              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/4.png`}
                  alt="Mobile App Testing"
                />
                <a href="case-mobile-app-testing" target="_blank">
                  <div className="projects-content">
                    <h3>Mobile App Testing</h3>
                    <span className="category">Mobile App</span>
                  </div>
                </a>
              </div>

              <div className="single-projects-box">
                <img
                  src={`assets/img/projects-image/5.png`}
                  alt="Test Automation"
                />
                <a href="case-test-automation" target="_blank">
                  <div className="projects-content">
                    <h3>Test Automation</h3>
                    <span className="category">Automation</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Projects Area */}
        {/* Start Team Area */}
        <section className="team-area ptb-100 pt-0">
          <div className="container">
            <div className="section-title mb-2">
              <span className="sub-title">Our Team</span>
              <h2>Working for You</h2>
            </div>
            <p className="text-center">
              TestDel is a team of honest, hardworking people who come to work
              every day because they love what they do and take great pleasure
              in providing friendly and efficient client services, solving
              testing challenges and delivering faultless artwork.
            </p>

            <img src={`assets/img/working-for-you.png`} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" /> 
          </div>
          <div className="shape-img3">
            <img src={`assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img6">
            <img src={`assets/img/shape/6.png`} alt="image" />
          </div>
          <div className="shape-img9">
            <img src={`assets/img/shape/9.png`} alt="image" />
          </div>
          <div className="shape-img10">
            <img src={`assets/img/shape/10.png`} alt="image" />
          </div>
        </section>
        {/* End Team Area */}
        {/* Start Solution Area */}
        <section
          className="solution-area ptb-100 extra-pb jarallax"
          data-jarallax='{"speed": 0.3}'
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="solution-content">
                  <span className="sub-title text-center">
                    Let TestDel Solve Your Software Quality Problems
                  </span>
                </div>
                <div className="row video-content mt-4">
                  <div className="col-md-6 border-right text-white text-right">
                    <h3 className="text-white">Your challenges</h3>
                    <ul>
                      <li> Urgent product release</li>
                      <li>No specifications for software testing</li>
                      <li>
                        Launching a new product to the market, project with
                        limited budget
                      </li>
                      <li>
                        Lack of expertise in testing, no opportunity to hire
                        staff team
                      </li>
                      <li>
                        No infrastructure to execute the comprehensive testing
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 text-white">
                    <h3 className="text-white">Solution TestDel offer</h3>
                    <ul>
                      <li>Quick project starts and 73+ QA specialists</li>
                      <li>Testing with minimal or no documentation</li>
                      <li>
                        Reasonable rates and costs planning due to objective and
                        accurate estimations
                      </li>
                      <li>
                        5 year of company expertise and team of experienced QA
                        specialists
                      </li>
                      <li>
                        A wide range of devices, OSs, testing tools and
                        environments
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="solution-video">
                  <a
                    href="https://www.youtube.com/watch?v=o4v-zxM_d08"
                    aria-label="Click to watch video"
                    className="video-btn popup-youtube"
                  >
                    <i className="flaticon-play-button" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Solution Area */}
        {/* Start Feedback Area */}
        <section className="feedback-area ptb-100 bg-color">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Testimonials</span>
              <h2>Some Lovely Feedback From Our Clients</h2>
            </div>
          </div>
          <div className="feedback-slides owl-carousel owl-theme">
            <div className="single-feedback-item">
              <p>
                “I worked with Testdel team member while our company are
                managing a multi-Milion Euro Project for Europe largest aviation
                company. Testdel team members offered many thoughtful
                suggestions, developed the test Strategy for all different test
                phases, creation of all Test plans and testing.”
              </p>
              <div className="client-info">
                <img
                  src={`assets/img/client-image/2.png`}
                  alt="Testdel Client"
                />
                <h3>Project Manager</h3>
                <span>Europe Largest Aviation Company</span>
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                “I worked with testdel team closely during the test
                implementations in largest phone retailer chain. I found testdel
                team member to be an excellent detailed oriented test and QA
                team. The projects were delivered to time and budget. Team
                member combined technical and people skills to achieve.”
              </p>
              <div className="client-info">
                <img
                  src={`assets/img/client-image/3.png`}
                  alt="Testdel Client"
                />
                <h3>Senior Change Manager</h3>
                <span>Large Phone Retailer</span>
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                “During the time I worked with testdel team member at the Public
                sector in UK. Team quickly mastered the test aspects of a large
                and complex project that was already running. Team member also
                has excellent personal skills as demonstrated with other team
                peers and the supplier test team.”
              </p>
              <div className="client-info">
                <img
                  src={`assets/img/client-image/1.png`}
                  alt="Testdel Client"
                />
                <h3>Enterprise Architect</h3>
                <span>Public Sector</span>
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                “Testdel team is one of the most intuitive IT Test/Quality
                Assurance team I have come across in my professional life. No
                real bug or issue (interface, technical or functional) can sneak
                in the product under Testdel team. Team member pro-activeness is
                the key to their success.”
              </p>
              <div className="client-info">
                <img
                  src={`assets/img/client-image/4.png`}
                  alt="Testdel Client"
                />
                <h3>Programme Manager</h3>
                <span>Leading European IT Consultancy</span>
              </div>
            </div>
            <div className="single-feedback-item">
              <p>
                “Testdel team member engaged in one of the projects I managed at
                largest Oil and Gas company in UK. During our time
                together,Testdel team put together the test approach and plan
                covering the full end to end test life cycle and successfully
                managed these phases.”
              </p>
              <div className="client-info">
                <img
                  src={`assets/img/client-image/5.png`}
                  alt="Testdel Client"
                />
                <h3>Project Manager</h3>
                <span>Largest Oil and Gas Utility Firm</span>
              </div>
            </div>
          </div>
        </section>
        {/* End Feedback Area */}
        {/* Start Pricing Area */}
        <section className="pricing-area ptb-100 pt-0">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">OUR PROCESS</span>
              <h2>Working with testdel</h2>
              <p>
                We draw on experience for every project we undertake, which has
                seen us deliver hundreds of productive projects across Europe.
                See our simplest way of working.
              </p>
            </div>
            <img src={`assets/img/work-process.png`} alt="Test Work Process" />
          </div>
          <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img6">
            <img src={`assets/img/shape/6.png`} alt="image" />
          </div>
          <div className="shape-img9">
            <img src={`assets/img/shape/9.png`} alt="image" />
          </div>
          <div className="shape-img10">
            <img src={`assets/img/shape/10.png`} alt="image" />
          </div>
        </section>
        {/* End Pricing Area */}
        {/* Start Subscribe Area */}
        <section className="subscribe-area bg-F4F7FC">
          <div className="container-fluid p-0">
            <div
              className="subscribe-inner-area jarallax"
              data-jarallax='{"speed": 0.3}'
            >
              <div className="subscribe-content">
                <span className="sub-title">Join our mailing List.</span>
                <h2>
                  Sign up to get the latest in software testing news directly in
                  your inbox !
                </h2>
                <form className="newsletter-form" data-toggle="validator">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">Subscribe</button>
                  <div id="validator-newsletter" className="form-result" />
                </form>
              </div>
            </div>
          </div>
          <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`assets/img/shape/3.svg`} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="image" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="image" />
          </div>
          <div className="shape-img6">
            <img src={`assets/img/shape/6.png`} alt="image" />
          </div>
        </section>
        {/* End Subscribe Area */}
        {/* Start Blog Area */}
        <section className="blog-area ptb-100 bg-F4F7FC">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Blog</span>
              <h2>Our Latest Articles And News For Our Clients</h2>
            </div>
            <div className="row">
            {Array.isArray(this.state.products) && this.state.products.length > 0 && this.state.products.slice(4).map((product) => {
                      if (product && product.better_featured_image) {
                        return (
                          <>
                            <div className="col-lg-4 col-md-6">
                              <div className="single-blog-post">
                                <div className="post-image">
                                  <Link
                                    state={{
                                      product,
                                      allPost: this.state.products.slice(4),
                                    }}
                                    to={{ pathname: `/blog/${product.slug}` }}
                                  >
                                    <img
                                      src={
                                        "https://wpblog.testdel.com/wp-content/uploads/" +
                                        product.better_featured_image
                                          .media_details.file
                                      }
                                      alt={product.title.rendered}
                                    />
                                  </Link>
                                </div>
                                <div className="post-content">
                                  <h3>
                                    <Link
                                      state={{
                                        product,
                                        allPost: this.state.products.slice(4),
                                      }}
                                      to={{ pathname: `/blog/${product.slug}` }}
                                    >
                                      {product.title.rendered.substr(0, 28)}..
                                    </Link>
                                  </h3>
                                  <p>
                                    {product.content.rendered
                                      .replace(/(<([^>]+)>)/gi, "")
                                      .substr(0, 150)}
                                    ...
                                  </p>
                                  <Link
                                    state={{
                                      product,
                                      allPost: this.state.products.slice(4),
                                    }}
                                    to={{ pathname: `/blog/${product.slug}` }}
                                    className="read-more-btn"
                                  >
                                    Read More{" "}
                                    <i className="flaticon-right-arrow" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
              })}
            </div>
          </div>
          <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={`assets/img/shape/3.svg`} alt="image" />
          </div>
        </section>
        {/* End Blog Area */}
      </div>
    );
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  UNSAFE_componentWillMount() {
    axios
      .get(
        `https://wpblog.testdel.com/wp-json/wp/v2/posts/?post_status=publish`
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            products: res && res.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


}

export default Home;
