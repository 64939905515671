import React from 'react';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Desktop Application Testing";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function DesktopApplicationTesting() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
       {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Desktop Application Testing</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="case-study">Use Cases</a>
                  </li>
                  <li>Desktop Application Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`} alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </div>
      {/* End Page Title Area */}
         {/* Start Project Details Area */}
         <section className="project-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work6.png`} alt="image" />
               </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work6_1.png`} alt="image" />
               </div>
            </div>
            
           
            <div className="col-lg-12 col-md-12">
              <div className="projects-details-desc">
                <h2>Client </h2>
                <p>Our Customer provides their customers with a full and secure contact centre solution for customer service interactions based on voice and non-voice. TestDel tester has engaged clients and acts as their in-house QA team for their project on their social interaction website. For their web apps, desktops apps, APIs along with load testing for various social media connectors, we handle manual and automation testing. 
                </p>
                <p>Our Customer Social Engagement is the latest extension of the media range eServices can process. The expanded system tracks a social media platform((Facebook , twitter and apple business chat), gathers items such as public messages, private messages, tweets and direct messages according to the customer's configured profile, and transforms them into communications to be sent to contact centre agents.
</p>
<h2>Key Challenges </h2>
<p>Each project has its own challenges and we are always happy with each challenge because it helps us build our strength and makes us better. Here are just a few challenges we addressed: </p>
<ul>
    <li>	Coordination with geographically dispersed teams around EMEA, APAC and America, from various time zones. </li>
<li>	Implement and maintain functional automation check and automated Facebook and twitter integration test. </li>
<li>	Most of the components on-premise are desktop application. The biggest challenge was to identify a reliable, open source automation tool & framework that could deliver the desired results. After having carried out multiple POCs and tests, we successfully introduced automation using the Robot platform Sikuli and Jython.</li>
<li>Facebook does not allow the execution of automated tests on their site, and their intelligent spybots may capture automated test scripts that would result in blocked accounts instantly. By firing up our own Facebook mock that could create fake Facebook interactions; we overcome the challenge and helped us test our framework. </li>
<li>Initially, having very little information about system design and lack of sufficient documentation, we faced a challenge to properly understand and check the tasks. But with our dedication and analytical skills we managed to work this out.</li>
<li>	Managing client problems and their testing that included at least 10 different versions of various components and desktop applications along with backward compatibility checks on all versions and components.</li>
</ul>
<div className="text-center mb-5 p-3 border gray-bg ">
<h3 >Got a project in mind?</h3>
<p>There is no better place than TestDel to get a QA solution. 
Find out what our team can do for you and drop us a line. 
</p>
<a href="get-a-free-quote" className="btn mt-0 default-btn">Get Quote</a>
</div>
<br/>
<h3>How TestDel Assisted the Client</h3>
<p>In order to assist our client meet their quality needs, Tetdel team followed these steps:</p>
<ul >
    <li>  Requirement analysis.</li>
    <li>	Test planning and test case scripting for social networking application testing.</li>
    <li>  We used Agile methodology to cover all the client requirements. </li>
    <li> Both manual and automated testing methods were performed, including functional, regression, smoke, sanity and usability testing types.</li>
<li> During the project realization, our team used JavaScript, Java and C# languages.</li>
<li> To ensure the accurate identifying of all gaps, errors and missing requirements, our team applied different tools. </li>
<li> We worked in Jira to create a reliable and balanced testing process.</li>
<li> Test data creation.</li>
<li> Test case execution in all supported browser and devices.</li>
<li> Responsiveness test of application in devices mentioned in “Testing Environment” section.</li>
<li> Validating access permissions for Admin and User portals.</li>
<li> Security Testing by manipulating user access as per defined role.</li>
<li> Smoke, System and Regression testing.</li>
<li> UAT support </li>
</ul>
<br/>
<h2>Results Achieved </h2>
<p>We have successfully tested the required functionalities of contact centre which allowed to release its first version to the customers. We continue to perform the tests to refine the product.</p>
              
                <div className="project-details-info">
                  <div className="single-info-box">
                    <h4>Client</h4>
                    <span>Telephone Directory          </span>
                  </div>
                  <div className="single-info-box">
                    <h4>Category</h4>
                    <span>Marketing</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Date</h4>
                    <span>2016</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Share</h4>
                    <ul className="social">
                      <li><a href="#" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Project Details Area */}
</div>
  );
}

export default DesktopApplicationTesting;
