import React from "react";
import Counter from "../components/Counter";
import Team from "../components/Team";
import Partners from "../components/Partners";
import MetaTags from "../components/MetaTags";
const Title = "Methodology";
const Description =
  "Testdel.com customizes solutions for each project, optimizing quality assessment while minimizing risks and enhancing efficiency. Unique approaches for unique projects.";
const Methodology = () => {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Methodology</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Methodology</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      {/* About Us */}
      <section className="choose-area-two">
        <div className="container-fluid">
          <div className="row" Style="align-items:center">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/methodology.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div
                  className="section-title text-left"
                  Style="margin: 0 0 30px"
                >
                  <h2 className="text-left">Methodology</h2>
                </div>
                <p Style="max-width:600px">
                  At Testdel.com, we understand that every project is unique,
                  with its own set of challenges and requirements. That's why we
                  tailor our approach to suit your specific needs, project
                  dynamics, and software development methodology. Our goal is to
                  deliver effective quality assessment while minimizing risks
                  and maximizing efficiency
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End About Us */}
      <section className="gray-bg pt-5 pb-5">
        <div className="container">
          <div>
            <h4>Kanban</h4>
            <p>
              For large-scale and complex projects characterized by rapidly
              evolving requirements, we excel in providing a seamless workflow.
              Through close collaboration with your development team, we
              prioritize tasks effectively and ensure smooth progress towards
              project milestones.{" "}
            </p>
          </div>
          <div>
            <h4>Scrum</h4>
            <p>
              We believe in delivering results without unnecessary bureaucracy.
              Our approach to Scrum emphasizes efficiency and clarity. By
              focusing on verifying ready features or modules, we provide you
              with detailed test results that offer a clear understanding of
              software quality and the readiness of each sprint.
            </p>
          </div>
          <div>
            <h4>DevOps Deployment</h4>
            <p>
              In today's fast-paced environment, collaboration between
              departments is essential for success. Our DevOps Deployment
              methodology emphasizes teamwork and continuous improvement. By
              verifying software quality throughout the development process, we
              help reduce potential failures, improve release times, and enhance
              product reliability.
            </p>
          </div>
          <div>
            <h4>eXtreme Programming (XP)</h4>
            <p>
              In situations where rapid and frequent releases are the norm, our
              eXtreme Programming methodology shines. We adapt to changing
              demands and evolving user requirements, providing quality
              assessment that aligns with the pace of your iterations. By
              focusing on user stories, we ensure that each iteration is
              effective and delivers value to your users.
            </p>
          </div>
          <div>
            <h4>Waterfall Model</h4>
            <p>
              In cases where a more traditional approach is preferred, our
              Waterfall Model methodology ensures objective and timely software
              testing. With its linear nature, the Waterfall Model allows for
              well-defined objectives and stable requirements, enabling us to
              deliver comprehensive testing services that meet your project
              goals.
            </p>
            <p>
              At Testdel.com, we are committed to delivering quality assurance
              services that align with your chosen methodology, ensuring the
              success of your projects. Contact us today to learn more about how
              we can support your software development efforts.
            </p>
          </div>
        </div>
      </section>
      <Counter />
      <div className="pt-5 pb-5">
        <h2 className="text-center">Our Team Served</h2>
        <Partners />
      </div>
    </div>
  );
};

export default Methodology;
