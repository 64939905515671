/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Pagination } from "./Pagination";
import MetaTags from "./MetaTags";
const Title = "Blogs - TestDel";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
const Loader = () => <div>Loading...</div>;
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";

export const Blogs = (props) => {
  const pageId = parseInt(props.pageId);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errMessage, setError] = useState("");
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://wpblog.testdel.com/wp-json/wp/v2/posts/?post_status=publish&per_page=12&page=${currentPage}`
      )
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setPosts(res.data);
          setTotalPages(res.headers["x-wp-totalpages"]);
        } else {
          setError("No posts found");
        }
      })
      .catch((err) => {
        //setError( err.response.data.message );
      });
  }, [currentPage]);

  const getPosts = (posts) => {
    if (!Array.isArray(posts)) {
      return null; // or handle appropriately if posts is not an array
    }
  
    return posts.map((product) => {
      if (product && product.better_featured_image) {
        return (
          <div className="col-lg-4 col-md-6" key={product.id}>
            <div className="single-blog-post">
              <div className="post-image">
                <Link
                  state={{ product, allPost: posts }}
                  to={{ pathname: `/blog/${product.slug}` }}
                >
                  <img
                    src={
                      "https://wpblog.testdel.com/wp-content/uploads/" +
                      (product.better_featured_image.media_details.file || '')
                    }
                    alt={product.title.rendered}
                  />
                </Link>
              </div>
              <div className="post-content">
                <h3>
                  <Link
                    state={{ product, allPost: posts }}
                    to={{ pathname: `/blog/${product.slug}` }}
                  >
                    {product.title.rendered}
                  </Link>
                </h3>
                <p>
                  {product.excerpt.rendered
                    .replace(/(<([^>]+)>)/gi, "")
                    .substr(0, 150)}
                  ...
                </p>
                <Link
                  state={{ product, allPost: posts }}
                  to={{ pathname: `/blog/${product.slug}` }}
                  className="read-more-btn"
                >
                  Read More <i className="flaticon-right-arrow" />
                </Link>
              </div>
            </div>
          </div>
        );
      } else {
        return null; // or handle the case where product.better_featured_image is missing
      }
    });
  };
  

  return (
    <>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>TestDel Blogs</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
            <img src={`assets/img/shape/2.svg`} alt="image" />
          </div>
        <div className="shape-img3">
          <img src={`assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/3.svg`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      {/* Start Blog Area */}
      <section className="blog-area pt-5 pb-4">
        <div className="container">
          <h2 className="text-center mb-5">
            Outsource Software Testing / QA news blogs
          </h2>

          <React.Fragment>
            <h2 className="text-center center">
              {" "}
              {loading ? <Loader /> : ""}{" "}
            </h2>

            {!loading && null !== posts && posts.length ? (
              <React.Fragment>
                <div className="row">{getPosts(posts)}</div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                />
              </React.Fragment>
            ) : (
              <div>{errMessage}</div>
            )}
          </React.Fragment>
        </div>
        <div className="shape-img2">
          <img src={`assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`assets/img/shape/3.svg`} alt="image" />
        </div>
      </section>
      {/* End Blog Area */}
    </>
  );
};

export default Blogs;
