  
import React from 'react';
import {  Link } from "react-router-dom";
import { createPaginationArray } from "../utils/functions";

export const Pagination = ( props ) => {

	const { currentPage, totalPages, setCurrentPage } = props;

	const isThereNextPage     = currentPage < totalPages;
	const isTherePreviousPage = currentPage > 1;
	const paginationArray     = createPaginationArray( currentPage, totalPages );

	const getPageLink = ( pageNo ) => {
		return `/page/${pageNo}`;
	};

	return (
	         <div className="pagination-area">
  <nav aria-label="Page navigation">
    <ul className="pagination justify-content-center">
			{ isTherePreviousPage &&  <li className="page-item"> <Link className="prev page-link" to={ getPageLink( currentPage - 1 ) } onClick={ () => setCurrentPage( currentPage - 1 ) }>Previous</Link> </li> }

			{ paginationArray &&
			paginationArray.map( ( item, index ) => {
				// If item is not equal to '...' and the item value is not equal to current page.
				if ( '...' !== item && currentPage !== item  ) {

					return (

                        <>
                       
                       <li className="page-item">
   
                      
							<React.Fragment key={ `${ item }-${ index }` }>
							<Link className="page-link"
								to={ getPageLink( item ) }
								onClick={ () => setCurrentPage( item ) }
							>
								<span className="page-no">{ item }</span>
							</Link>
						</React.Fragment>
                        </li>
    
                        </>
					);
				} else {
					return (
						// if its '...' or the current page, it should not be clickable ( should not be a link )
                        <li className="page-item active">
						<span className="page-link" key={ `${ item }-${ index }` }>
							<span className={ currentPage === item ? 'page-no active' : '' }>{ item }</span>
						</span>
                        </li>
					);
				}
			} ) }
			{ isThereNextPage &&  <li className="page-item"> <Link className="next page-link" to={ getPageLink( currentPage + 1 ) } onClick={ () => setCurrentPage( currentPage + 1 ) } >Next</Link></li> }
            </ul>
  </nav>
</div>
    
	)
};