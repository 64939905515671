import React from "react";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";
import Counter from "../components/Counter";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Services";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function Services() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>TestDel QA Services </h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`../assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`../assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`../assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`../assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`../assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`../assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="choose-area-two  pt-5 pb-5">
        <div className="container">
          <div className="overview-box">
            <div className="overview-content p-0">
              <div className="content p-0">
                <h2>
                  Get access to a dedicated team of 80 + engineers, experienced
                  experts with domain experience and a state-of art research
                  facility{" "}
                </h2>
                <p>
                  By providing a wide range of QA and testing services, we
                  ensure your product meets the market requirements, satisfies
                  international quality standards, and attracts new users. Our
                  services include, but are not limited to:{" "}
                </p>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img src={require(`../assets/img/services/it-service2.png`)} alt="image" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services-area pt-5 pb-5 bg-F4F7FC">
        <div class="container">
          <div className="section-title">
            <h2>Software Testing Services</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon">
                  <i class="flaticon-digital-marketing"></i>
                </div>
                <h3>
                  <a href="/website-testing">Website Testing </a>
                </h3>
                <p>
                  We make sure your website gets tested to perfection. TestDel
                  delivers high quality QA services for your web-platform's
                  impeccable performance, usability, security, budget and
                  business goals.
                </p>
                <a href="/website-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-c679e3">
                  <i class="flaticon-mail"></i>
                </div>
                <h3>
                  <a href="/web-applications-testing">Web Application Testing</a>
                </h3>
                <p>
                  We guarantee the excellent performance and scalability of any
                  application we check during the process of web applications
                  testing. Our team can complete tests within your requirements
                  on time.
                </p>
                <a href="/web-applications-testing" class="read-more-btn">
                  Read More<i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-00aeff">
                  <i class="flaticon-research"></i>
                </div>
                <h3>
                  <a href="/desktop-applications-testing">
                    Desktop Application Testing
                  </a>
                </h3>
                <p>
                  TestDel enables impeccable quality for your desktop
                  application. We offer full-cycle testing service to make your
                  software safe, fast, attractive and bug-free.
                </p>
                <a href="/desktop-applications-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-f78acb">
                  <i class="flaticon-analytics"></i>
                </div>
                <h3>
                  <a href="/internet-of-things-testing">
                    Internet of Things Testing{" "}
                  </a>
                </h3>
                <p>
                  Our dedicated QA team tests and implements tailor-made IoT
                  solutions. We help businesses push intelligent products up to
                  the next level and render the application as expected.
                </p>
                <a href="/internet-of-things-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-cdf1d8">
                  <i class="flaticon-analysis"></i>
                </div>
                <h3>
                  <a href="/games-testing">Game Testing </a>
                </h3>
                <p>
                  Enable your gamers full pleasure. Our QA experts are happy to
                  give you high quality game testing to ensure a smooth,
                  engaging, and unforgettable experience for your gamers.
                </p>
                <a href="/games-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="flaticon-seo"></i>
                </div>
                <h3>
                  <a href="/cloud-solutions-testing">Cloud Solutions Testing</a>
                </h3>
                <p>
                  We test in the cloud to get an implacable quality of
                  application along with effective business rewards. Due to
                  lower costs, 24×7 access to cloud testing tools, and quick
                  accessibility.
                </p>
                <a href="/cloud-solutions-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fas fa-mobile-alt"></i>
                </div>
                <h3>
                  <a href="/mobile-applications-testing">
                    Mobile Application Testing
                  </a>
                </h3>
                <p>
                  We test mobile apps for flawless performance. Our experts
                  analyze functionality, security, and UI to provide detailed
                  reports and improve app quality. Contact us to learn more
                </p>
                <a href="/mobile-applications-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fas fa-universal-access"></i>
                </div>
                <h3>
                  <a href="/accessibility-testing">Accessibility Testing</a>
                </h3>
                <p>
                  We offer Accessibility Testing services to ensure digital
                  content is usable by people with disabilities, promoting
                  inclusivity and compliance with accessibility standards
                </p>
                <a href="/accessibility-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fas fa-tachometer-alt"></i>
                </div>
                <h3>
                  <a href="/performance-testing">Performance Testing</a>
                </h3>
                <p>
                  We offer performance testing services to optimize application
                  performance. Our expertise ensures systems perform under
                  various stress conditions and workloads for optimal user
                  experience
                </p>
                <a href="/performance-testing" class="read-more-btn">
                  Read More <i class="flaticon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Counter />
      {/* Start Projects Area */}
      <section className="projects-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Case Study</span>
            <h2>We’ve Done Lot’s Of Work, Let’s Check Some From Here</h2>
          </div>
        </div>
        <div className="container-fluid">
          <div className="projects-slides owl-carousel owl-theme">
            <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/1.png`}
                alt="Web application Testing"
              />
              <a href="/web-applications-testing" target="_blank">
                <div className="projects-content">
                  <h3>Web application Testing</h3>
                  <span className="category">Application</span>
                </div>
              </a>
            </div>
            <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/2.png`}
                alt="Website Testing"
              />
              <a href="/website-testing" target="_blank">
                <div className="projects-content">
                  <h3>Website Testing</h3>
                  <span className="category">Website</span>
                </div>
              </a>
            </div>
            {/* <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/3.png`}
                alt="Automated document editing method"
              />
              <a href="/automated-document-editing-method" target="_blank">
                <div className="projects-content">
                  <h3>Automated document editing method</h3>
                  <span className="category">Automated</span>
                </div>
              </a>
            </div> */}

            <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/6.png`}
                alt="Desktop Application Testing"
              />
              <a href="/desktop-application-testing" target="_blank">
                <div className="projects-content">
                  <h3>Desktop Application Testing</h3>
                  <span className="category">Desktop</span>
                </div>
              </a>
            </div>

            <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/4.png`}
                alt="Mobile App Testing"
              />
              <a href="/mobile-applications-testing" target="_blank">
                <div className="projects-content">
                  <h3>Mobile App Testing</h3>
                  <span className="category">Mobile App</span>
                </div>
              </a>
            </div>

            <div className="single-projects-box">
              <img
                src={`../assets/img/projects-image/5.png`}
                alt="Test Automation"
              />
              <a href="/test-automation-services" target="_blank">
                <div className="projects-content">
                  <h3>Test Automation</h3>
                  <span className="category">Automation</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* End Projects Area */}

      <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default Services;
