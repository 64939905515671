import React from "react";
import Counter from "../components/Counter";
import Team from "../components/Team";
import Partners from "../components/Partners";
import MetaTags from "../components/MetaTags";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";
const Title = "Who We Are";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function AboutUs() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>About TestDel</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Who We Are</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      {/* About Us */}
      <section className="choose-area-two pt-5 pb-5">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/about.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2 className="text-left">Software Testing Company</h2>
                </div>
                <p>
                  TestDel is a leading quality assurance company with over 5
                  years of experience in providing all testing related services
                  to ensure successful delivery of programmes. Our principal
                  focus is to provide end-to-end quality assurance services
                  around the world to Software Development firms and end
                  clients. Our test teams work with organizations across all
                  industries to meet all testing challenges effectively.
                </p>
                <p>
                  We are the expert in manual and automated testing for web,
                  desktop, mobile, cloud, and cross-platform software solutions.
                  We’re here to make sure platforms tested thoroughly on time
                  and within budget to improve how the software works for the
                  people who use it. You can trust our team of experienced and
                  highly qualified professionals from reputed technology and
                  management institutions who are domain experts from various
                  industries including IT, Telecom Retail, Aviation, Utility,
                  and Banking.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End About Us */}
      {/* Start Solution Area */}
      <section
        className="vision-area ptb-100 extra-pb jarallax"
        data-jarallax='{"speed": 0.3}'
        id="ourvision"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="solution-content text-center">
                <h2>Our Vision</h2>
                <p>
                  To contribute positively in addressing and resolving the
                  quality assurance challenges, we are a team that balances
                  experience, expertise, creativity, and innovation. By
                  providing professional QA services, we help businesses and
                  organisations to earn reputation and product reliability.
                </p>
                <a href="contact-us" className="default-btn">
                  Contact Us <span />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Solution Area */}
      <Team id="our-team" />
      <Counter />

      <section className=" pt-5 pb-5" id="our-approach">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/testing-aproch.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2>Quality is the most important factor for us </h2>
                </div>
                <p>
                  With our vast and long enough experience in providing software
                  testing, we offer an extensive range of fully managed test
                  services from our USA, UK and India based test labs. TestDel
                  has the expertise to verify and report all blockers so you
                  don’t have to worry before end users quality assurance and
                  validation process starts.
                </p>
                <p>
                  Our experienced QA testing team will plan, manage and execute
                  your testing delivery so you achieve the quality assurance you
                  desire. Our specialist consultants can identify the
                  requirements, technological challenges and suggest a fixed
                  cost, dedicated team or time and material cost solutions as
                  per the nature of your project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gray-bg pt-5 pb-5">
        <div className="container">
          <div className="text-center">
            <h2>Everything you need for QA, in one place.</h2>
          </div>
          <div className="row m-4 align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item ">
                    <div
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-check" />
                      Work better, together
                    </div>
                    <p
                      className="accordion-content active"
                      Style="display:block;"
                    >
                      Collaborate effectively with our dedicated testing team
                      and resolve issues faster.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-check" />
                      Prioritize the issues that matter
                    </div>
                    <p
                      className="accordion-content active"
                      Style="display:block;"
                    >
                      Receive detailed reports, so you can immediately fix
                      what’s important to your users.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="choose-image">
                <marquee
                  direction="up"
                  scrollamount="8"
                  scrolldelay="150"
                  Style="height: 200px;"
                >
                  <img
                    src={require(`../assets/img/company-logo/1.png`)}
                    alt="image"
                  />
                  <img
                    src={require(`../assets/img/company-logo/2.png`)}
                    alt="image"
                  />
                  <img
                    src={require(`../assets/img/company-logo/3.png`)}
                    alt="image"
                  />
                  <img
                    src={require(`../assets/img/company-logo/4.png`)}
                    alt="image"
                  />
                </marquee>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item ">
                    <div
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-check" />
                      Don't change the way you work
                    </div>
                    <p
                      className="accordion-content active"
                      Style="display:block;"
                    >
                      Seamlessly integrates with your favorite QA and project
                      management tools.
                    </p>
                  </li>
                  <li className="accordion-item ">
                    <div
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="fas fa-check" />
                      Have full visibility
                    </div>
                    <p
                      className="accordion-content active"
                      Style="display:block;"
                    >
                      See your QA strategy in action and stay up-to-date in
                      execution.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-5 pb-5">
        <h2 className="text-center">Our Team Served</h2>
        <Partners />
      </div>
    </div>
  );
}

export default AboutUs;
