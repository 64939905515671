import React from 'react';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'

function Team() {
  return (
    <div>
               {/* Start Team Area */}
      <section className="team-area pt-5 pb-5 gray-bg">
        <div className="container">
        <div class="section-title">
                    <h2>Management Team</h2>
                </div>
                <div className="row">
                <div className="col-md-12">
          <div className="tab join-list-tab">
            <ul className="tabs">
              <li className="col-md-3">
                <a href="#">
                <div className="single-team-box">
                <div className="image">
                  <img src={`assets/img/team-image/sanjiv-joshi.png`}  alt="team" />
                </div>
                <div className="content">
                  <h3>Sanjiv Joshi</h3>
                  <span>CEO And Founder</span>
                  <p>Sanjiv is a Serial Entrepreneur with year after year attainment at reaching business growth in various..</p>
                  <a href="#" className="team-btn">Read More</a>
                </div>
              </div>
                </a>
              </li>
              <li className="col-md-3">
                <a href="#">
                <div className="single-team-box">
                <div className="image">
                  <img src={`assets/img/team-image/ambika-singhal.png`}  alt="team" />
                </div>
                <div className="content">
                  <h3>Ambika Bajaj</h3>
                  <span>Strategist And Chief Evangelist</span>
                  <p>Technical Leader leveraging a time-tested career working on all phases of product development..</p>
                  <a href="#" className="team-btn">Read More</a>
                </div>
              </div>
                </a>
              </li>
              <li className="col-md-3">
                <a href="#">
                <div className="single-team-box">
                <div className="image">
                  <img src={`assets/img/team-image/ajay-sharma.png`}  alt="team" />
                </div>
                <div className="content">
                  <h3>Dr. Ajay Sharma</h3>
                  <span>Chief Operating Officer
                </span>
                  <p>As COO at TestDel, Ajay is responsible for leading the offshore operations in India. He is a result-oriented..</p>
                  <a href="#" className="team-btn">Read More</a>
                </div>
              </div>
                </a>
              </li>  
              <li className="col-md-3">
                <a href="#">
                <div className="single-team-box">
                <div className="image">
                  <img src= {`assets/img/team-image/anjana-sharma.png`}  alt="team" />
                </div>
                <div className="content">
                  <h3>Anjana Sharma</h3>
                  <span>Head Of Customer Services</span>
                  <p>With over 15 years of customer service experience, Anjana is a strategic thinker expert at.. </p>
                <a href="#" className="team-btn">Read More</a>
                </div>
              </div>
                </a>
              </li>            
            </ul>
            <div className="tab_content">
              <div className="tabs_item" Style="display:none;">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                    <h2>Sanjiv Joshi</h2>
                    <span>CEO and Founder</span>
                     <p>Sanjiv is a Serial Entrepreneur with year after year attainment at reaching business growth in various ventures globally over the last 20 years.
Sanjiv is Customer-Intensive and Performance-Driven and has led and motivated worldwide teams and managed P&L for organizations globally. With broad understanding in the IT consulting province, he strives to add value for clients by providing them with eminence QA and Software Testing services and resolutions, leveraging onsite-offshore methodology, Global Delivery Model through best of variety professionals.</p>
<p>
His vision is to create the world’s leading and most trustworthy QA and Software Testing organization with a focus on quality, innovation and
satisfied employees & clients.</p>                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                    <h2>Ambika Bajaj</h2>
                    <span>Strategist and Chief Evangelist</span>
                    <p>Technical Leader leveraging a time-tested career working on all phases of product development from ideation to commercialisation, advancing the business mission, and enhancing stakeholder value leading to bottom-line profits and organisational excellence.</p>
                    <p> Master of Science (MS Information Technology) from a reputed university in the USA. A tenacious leader delivers project leadership with high-quality, customer-focused results. Process, tooling and automation champion. People person with the ability to coordinate cross-team projects, delivering under budget and on time, and meeting all KPIs.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
                    <h2>Dr. Ajay Sharma</h2>
                    <span>Chief Operating Officer</span>
                    <p>As COO at TestDel, Ajay is responsible for leading the offshore operations in India. He is a result-oriented IT and Consulting executive with 21 years of professional experience in IT, web, education, and Media industry. He has successfully created, managed and grew several start-ups.
</p>
                    <p>Ajay is a seasoned leader with a track record of developing and managing large accounts and projects. His consultative leadership, team-building, communication, and cross-functional skills consistently result in “win-win” relationships and outstanding client satisfaction. His team is in charge of the designing, implementation, and reporting of testing strategies, he is also in charge of our test automation organization.</p>
                    <p>Ajay’s widespread experience with outsourcing and global delivery allows to successfully recognize client business issues, to collaborate with the TestDel Delivery Team and expand the value that we deliver.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs_item">
                <div className="features-overview">
                  <div className="overview-content">
                    <div className="content">
        
                      <h2>Anjana Sharma</h2>
                    <span>Head Of Customer Services</span>
                    <p>With over 15 years of customer service experience, Anjana is a strategic thinker expert at understanding customer needs and providing them with suitable testing solutions. She is an Expert in the process of re-engineering, change management, and contact deflection.</p>
                    <p>Anjana comes from a rich Media, Education, Financial and IT services background, and has held several leadership positions in the past.
<br/>Her hard-headed views on the value proposition of the outsourcing model have managed to win enterprise-level strategic deals for TestDel.</p>
                    <p>Her hard-headed views on the value proposition of the outsourcing model have won enterprise-level strategic deals for TestDel.
</p>
                    </div>
                  </div>
                </div>
              </div>
                          </div>
          </div>
          </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`}  alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img6"><img src={`assets/img/shape/6.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </section>
      {/* End Team Area */}
     </div>
  );
}

export default Team;
