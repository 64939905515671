import React from "react";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";

import ServicesCardBottom from "../components/ServicesCardBottom";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Website Testing Services in Uk | Website Testing Company in UK ";
const Description =
  "Website Testing Services in Uk .Testdel.co.uk provides full testing services for ecommerce, websites and mobile apps & based in the UK.";
function WebsiteTestingService() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Website Testing Service</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a  href="/services/software-testing-services">Services</a>
                  </li>
                  <li>Website Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="choose-area-two  pt-5 pb-5">
        <div className="container">
          <div className="overview-box">
            <div className="overview-content">
              <div className="content">
                <h2>Get Your Web Solution Tested by Experts!</h2>
                <p>
                Our passionate testers verify and validate your website, so you can release bug-free website on time. Every user seeks and appreciates fully functioning, fast interface, and secure website.
                </p>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Manual and Automated  
                  </h4>
                  <p>
                  We will test your website to achieve fast responsive, intuitive and security goals to increase your organization revenue.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Functional and Non-Functional  
                  </h4>
                  <p>
                  TestDel will handle all components of testing, ensuring that your website is free from all major defects, and can handle the predicted traffic volume.
                  </p>
                </div>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
              <img
                  src={`assets/img/services/Website-Testing-Service.png`}
                  alt="image"
                />
          </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services-area ptb-100 bg-F4F7FC">
        <div class="container">
          <div class="section-title">
            <h2>Types of Website Testing</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon">
                  <i class="flaticon-research"></i>
                </div>
                <h3>
                  <a href="#">Usability Testing</a>
                </h3>
                <p>
                  Website usability testing will define whether the interface is
                  easy to use, convenient, and enjoyable for your potential
                  user, and what to fix if it's not.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-00aeff">
                  <i class="fa fa-user-astronaut"></i>
                </div>
                <h3>
                  <a href="#">Functionality Testing</a>
                </h3>
                <p>
                  All Database connections, internal and external links, cookies
                  and input forms of your website will be thoroughly checked
                  with this testing type.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-f78acb">
                  <i class="fa fa-database"></i>
                </div>
                <h3>
                  <a href="#">Database Testing</a>
                </h3>
                <p>
                  All connections between the server and the website will be
                  checked to allow users to get expected results after they
                  perform some actions, if any.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-cdf1d8">
                  <i class="fa fa-lock"></i>
                </div>
                <h3>
                  <a href="#">Security Testing</a>
                </h3>
                <p>
                  We perform network level penetration testing, network sniffing
                  and web applications vulnerability testing from front and to
                  back end. This testing type signifies how secure your
                  website is for users by a thorough check of password and data
                  encryption, log reviews.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-c679e3">
                  <i class="flaticon-seo"></i>
                </div>
                <h3>
                  <a href="#">Compatibility Testing</a>
                </h3>
                <p>
                  We will test your website on all possible devices, browsers,
                  Mobile IOS. We will also test printing or PDF conversion
                  functionality. We test localization to ensure your website has
                  a local feel with translation, proofreading, and usability testing.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fa fa-chart-bar"></i>
                </div>
                <h3>
                  <a href="#">Performance Testing</a>
                </h3>
                <p>
                  We test how your website will perform under normal deluge of
                  user transactions and what is the breaking point and what
                  happens when the website is down. We also give recommendations on what needs to be improved to make it highly performant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-facts-area  pt-5 pb-5">
        <div className="container">
          <div className="section-title">
            <h2 className="mb-3">Benefits of Website Testing</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
              <div className="col-md-1">
             
                <div className="icon">
                  <img src={`assets/img/services-icon/Benefits-of-Website-Testing--User-Satisfaction.png`} />
                </div>
                </div>
                <div className="col-md-11">
             
                <h3>
                  <a href="#">User Satisfaction</a>
                </h3>
                <p>
                  When visitors to your website like what they see, they stay
                  longer on your website, hence there’s a much higher chance you
                  achieve results that what you wanted.
                </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
              <div className="col-md-1">
             
                <div className="icon">
                  <img src={`assets/img/services-icon/Benefits-of-Website-Testing-High-Competitiveness.png`} />
                </div>
                </div>
                <div className="col-md-11">
             
                <h3>
                  <a href="#">High Competitiveness</a>
                </h3>
                <p>
                  When your website is with no glitches or bugs, it will
                  boost your company team members to represent with confidence
                  and achieve bigger results.
                </p>
              </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
              <div className="col-md-1">
             
                <div className="icon">
                  <img src={`assets/img/services-icon/Benefits-of-Website-Testing--High-Conversion-Rates.png`} />
                </div>
                </div>
                <div className="col-md-11">
             
                <h3>
                  <a href="#">High Conversion Rates</a>
                </h3>
                <p>
                  The enhanced quality of your website will attract more
                  traffic. More traffic means bigger popularity, thereby more
                  potential customers.
                </p>
              </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one  row bg-white">
              <div className="col-md-1">
             
                <div className="icon">
                  <img src={`assets/img/services-icon/Benefits-of-Website-Testing-Guaranteed-Security.png`} />
                </div>
                </div>
                <div className="col-md-11">
             
                <h3>
                  <a href="#">Guaranteed Security</a>
                </h3>
                <p>
                Every corporate or end user wants they protect their sensitive information for any leaks which we thoroughly test during testing.
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServicesCardBottom />
      <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default WebsiteTestingService;
