import React from "react";
import ContactForm from "../components/ContactForm";
import MetaTags from "../components/MetaTags";
import ServicesCardBottom from "../components/ServicesCardBottom";
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title = "Games Testing";
const Description = "Games Testing";
function GamesTesting() {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Games Testing Service</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                  <li>Games Testing Service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="choose-area-two  pt-5 pb-5">
        <div className="container">
          <div className="overview-box">
            <div className="overview-content p-0">
              <div className="content p-0">
                <h2> TestDel is a major game QA testing agency that specialises in console, PC, and mobile games</h2>
                <p>
                Our testers are ready to deliver you with high-quality game testing service to guarantee your players a flawless, interactive, and cherished experience. Our teams of testers pay great attention to detail, and test games like incredible game players!.
                </p>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                     The latest game testing practices
                  </h4>
                  <p>
                  We assure your end game will be well functioning, intuitive, and amusing for your target audience by applying the latest game testing practices.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Permanent testers
                  </h4>
                  <p>
                  Our experienced full-time gaming testing specialists can test the functionality and device compatibility of your games.
                  </p>
                </div>
                <div className="features-text">
                  <h4>
                    <i className="flaticon-tick" />
                    Test Lab 
                  </h4>
                  <p>
                  We have all types of mobile devices (over 200) in our lab inventory because we don’t prefer to test with emulators.   </p>
                </div>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
              <img
                  src={`assets/img/services/it-service2.png`}
                  alt="image"
                /> </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services-area ptb-100 bg-F4F7FC">
        <div class="container">
          <div class="section-title">
            <h2>Types of Games Testing</h2>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon">
                  <i class="flaticon-digital-marketing"></i>
                </div>
                <h3>
                  <a href="#">Functional Testing</a>
                </h3>
                <p>
                  Games developers want to deliver the finest product which will
                  not only impress the users but one which will also stand out
                  among the competitors. Our testers test the games thoroughly
                  before its released in the market..
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-00aeff">
                  <i class="flaticon-research"></i>
                </div>
                <h3>
                  <a href="#">Usability Testing</a>
                </h3>
                <p>
                  Developers want a game to be intuitive and interesting.
                  Usability testing will help to outline whether it is enjoyable
                  for your users, whether all functions, buttons, or settings
                  are placed conveniently.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-f78acb">
                  <i class="flaticon-analytics"></i>
                </div>
                <h3>
                  <a href="#">Regression Testing</a>
                </h3>
                <p>
                  In most cases, the game testing process involves improving a
                  couple of times to ensure the game brings maximum pleasure.
                  During upgrades and creation of the next versions of a video
                  game, our testers assess the current version.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-cdf1d8">
                  <i class="flaticon-analysis"></i>
                </div>
                <h3>
                  <a href="#">Compatibility Testing</a>
                </h3>
                <p>
                  Whether your game is a full-pack desktop software, a mobile
                  app, or a native PlayStation/Xbox. TestDel testers evaluate
                  performance and results as per game's advertised minimum
                  system requirements. Compatibility or performance is tested on
                  most advanced or legacy hardware.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-c679e3">
                  <i class="fa fa-lock"></i>
                </div>
                <h3>
                  <a href="#">Security Testing</a>
                </h3>
                <p>
                Every user specifies sensitive data, including financial payment details to the game server during registration. This information has been encoded and kept safely. TestDel testers apply white hat ethical hacking procedures to test the weak points of your game.

                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="single-services-box">
                <div class="icon bg-eb6b3d">
                  <i class="fa fa-tasks"></i>
                </div>
                <h3>
                  <a href="#">Load Testing</a>
                </h3>
                <p>
                  TestDel tests the limits of a system, such as the number of
                  players on the server, the number of sprites active on the
                  screen, or the number of threads running in a particular
                  program. We use automation tool that emulates heavy activity
                  to measures the capability of a game to function correctly
                  under load.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-facts-area  pt-5 pb-5">
        <div className="container">
          <div className="section-title">
            <h2 className="mb-3">Benefits of Game Testing</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
                <div className="col-md-1"><div className="icon">
                  <img src={`assets/img/services-icon/Delighted-Gamer--gt.png`} />
                </div></div>  <div className="col-md-11">
                <h3>
                  <a href="#">Delighted Gamer</a>
                </h3>
                <p>
                  When a client came up with an idea and developed a game. After
                  the game is thoroughly tested and released, the client expects
                  thousands of hooked and satisfied gamers.{" "}
                </p>
              </div>
            </div>
            </div> 
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
                <div className="col-md-1"><div className="icon">
                  <img src={`assets/img/services-icon/Objectives-Achieved--gt.png`} />
                </div></div>  <div className="col-md-11">
                <h3>
                  <a href="#">Objectives Achieved</a>
                </h3>
                <p>
                After thorough testing, companies can save money, and maintain their reputation that would be lost to a broken game if the game is of high quality.
                </p>
              </div>
            </div>
            </div> 
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
                <div className="col-md-1"><div className="icon">
                  <img src={`assets/img/services-icon/Confident-about-Security--gt.png`} />
                </div></div>  <div className="col-md-11">
                <h3>
                  <a href="#">Confident about Security</a>
                </h3>
                <p>
                  After successful security testing, user's data will be
                  protected, including passwords, credit card numbers or IDs,
                  and other sensitive information.
                </p>
                </div> 
              </div>
            </div>
            <div className="col-lg-12 col-sm-6">
              <div className="service-card-one row bg-white">
                <div className="col-md-1"><div className="icon">
                  <img src={`assets/img/services-icon/Long-standing-Stability--0-sg.png`} />
                </div></div>  <div className="col-md-11">
                <h3>
                  <a href="#">Long-standing Stability</a>
                </h3>
                <p>
                  A well-tested game will perform great for years or till our
                  clients want to present an updated version to the world. it
                  will not only bring money but also create a larger room for
                  business growth.{" "}
                </p>
              </div>
              </div> 
            </div>
          </div>
        </div>
      </section>

      <ServicesCardBottom />
      <div className="gray-bg">
        <ContactForm />
      </div>
    </div>
  );
}

export default GamesTesting;
