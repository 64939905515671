import React from 'react';
import Axios from 'axios';
import MetaTags from '../components/MetaTags';
import { ValidateBussinessEmail } from '../utils/functions';
import Select from 'react-select';
import { getHostUrl } from '../config';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'

const Title = 'Get A Free Quote';

const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
class RequestQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      organisation: '',
      email: '',
      number: '',
      project: {
        label: 'New - Initiation stage',
        value: 'New - Initiation stage',
      },
      file: '',
      msg: '',
      countryName: '',
      countryCode: '',
      isSubmitted: false,
      errors: {
        first: '',
        last: '',
        org: '',
        number: '',
        msg: '',
      },
    };
  }
  getGeoInfo = () => {
    Axios.get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getGeoInfo();
  }
  render() {
    return (
      <div>
        <MetaTags title={Title} description={Description} />
        {/* Start Page Title Area */}
        <div className="page-title-area " Style="height:200px">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2 className="text-white">Get A Free Quote</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-img2">
            <img src={`../assets/img/shape/2.svg`} alt="photo" />
          </div>
          <div className="shape-img3">
            <img src={`../assets/img/shape/3.svg`} alt="photo" />
          </div>
          <div className="shape-img4">
            <img src={`assets/img/shape/4.png`} alt="photo" />
          </div>
          <div className="shape-img5">
            <img src={`assets/img/shape/5.png`} alt="photo" />
          </div>
          <div className="shape-img7">
            <img src={`assets/img/shape/7.png`} alt="photo" />
          </div>
          <div className="shape-img8">
            <img src={`assets/img/shape/8.png`} alt="photo" />
          </div>
          <div className="shape-img9">
            <img src={`assets/img/shape/9.png`} alt="photo" />
          </div>
          <div className="shape-img10">
            <img src={`assets/img/shape/10.png`} alt="photo" />
          </div>
        </div>
        {/* End Page Title Area */}

        {/* Contact Form Section  */}
        <div className="contact-form-section white-bg pt-1 pb-70 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <div className="analysis-img"></div>
              </div>
              <div className="col-lg-6 gray-bg1 pt-4 pb-4">
                <div className="analysis-form">
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={this.state.firstname}
                            onChange={this.onFNameChange.bind(this)}
                            placeholder="First Name"
                          />
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.first}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={this.state.lastname}
                            onChange={this.onLNameChange.bind(this)}
                            placeholder="Last Name"
                          />
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.last}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            required
                            value={this.state.email}
                            onChange={this.onEmailChange.bind(this)}
                            placeholder="Business Email"
                          />
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.email}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={this.state.organisation}
                            onChange={this.onOrganisationChange.bind(this)}
                            placeholder="Organisation"
                          />
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.org}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={this.state.number}
                            onChange={this.onNumberChange.bind(this)}
                            placeholder="Phone Number"
                          />
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.number}
                          </small>
                        </div>
                        <div className="form-group">
                          Project Status
                          <Select
                            value={this.state.project}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#9d9fad",
                              }),
                              control: (provided) => ({
                                ...provided,
                                boxShadow: "1 1 1 1px",
                                borderWidth: "1px",
                                borderColor: "#eeeeee",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderWidth: 0.2,
                              color: "red",
                              colors: {
                                ...theme.colors,
                                text: "#9d9fad",
                                primary25: "#9d9fad",
                                primary75: "#9d9fad",
                                primary: "blue",
                                primary50: "#9d9fad",
                              },
                            })}
                            onChange={(value) => this.onProjectChange(value)}
                            options={[
                              {
                                value: "New - Initiation stage",
                                label: "New - Initiation stage",
                              },
                              {
                                value: "Existing - On going Development",
                                label: "Existing - On going Development",
                              },
                              {
                                value: "Operation - Maintenance and Support",
                                label: "Operation - Maintenance and Support",
                              },
                              {
                                value: "Others",
                                label: "Others",
                              },
                            ]}
                          ></Select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            value={this.state.msg}
                            required
                            onChange={this.onMsgChange.bind(this)}
                          >
                            How we can help you?
                          </textarea>
                          <small className="text-danger">
                            {" "}
                            {this.state.errors.msg}
                          </small>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <input type="file"  onChange={this.onFileChange.bind(this)} />
                      <small className="text-danger"> {this.state.isError6}</small>
                            <p> <small>{"(pdf, docx file types less than 4 MB)"}</small></p>
                    </div> */}
                    <p style={{ fontSize: "13px" }}>
                      {" "}
                      {/* <small> */}
                      TestDel will treat any information you submit with us (via
                      this form) as private and confidential. We will not share
                      your personal information with any third party, except
                      where disclosure is made at your request or with your
                      consent or where required by law.
                      {/* </small> */}
                    </p>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="default-btn col-md-4"
                        value="Send"
                        onClick={this.handleSubmit.bind(this)}
                      >
                        Send
                        <span />
                      </button>
                    </div>
                  </div>
                  <br />
                  {this.state.isSubmitted && (
                    <div id="msgSubmit" class="alert alert-success text-center">
                      Thank you !
                      <br />
                      Our specialist will contact you soon.
                    </div>
                  )}
                  <div className="form_policy">
                    *Once we receive your requirement enquiry, our qualified
                    business analysts will analyze and prepare a free report
                    that covers your goals, cost and time estimates and has a
                    recommended set of technology options. An initial 30 minute
                    free call with our business analyst will follow, ensuring
                    that you can ask questions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Contact Form Section */}
      </div>
    );
  }

  onFNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.first = '';
      this.setState({ firstname: event.target.value, errors: errors });
    } else {
      errors.first = 'Enter Only Character';
      this.setState({ errors });
    }
  }
  onLNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.last = '';
      this.setState({ lastname: event.target.value, errors: errors });
    } else {
      errors.last = 'Enter Only Character';
      this.setState({ errors });
    }
  }
  onEmailChange(event) {
    let errors = this.state.errors;
    errors.email = ValidateBussinessEmail(event.target.value)
      ? ''
      : 'Please enter your business email address';
    this.setState({
      email: event.target.value,
      errors,
    });
  }

  onOrganisationChange(event) {
    let errors = this.state.errors;
    if (!event.target.value) {
      errors.org = 'Organisation is mandatory';
    } else {
      errors.org = '';
    }
    this.setState({ organisation: event.target.value, errors });
  }

  onProjectChange(event) {
    this.setState({ project: event });
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == 'application/pdf' ||
      event.target.files[0].type ==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      this.setState({ file: event.target.files[0] });
      this.setState({ isError6: '' });
    } else {
      this.setState({ isError6: 'Allow only PDF and DOC File' });
    }

    if (event.target.files[0].size <= 4083842) {
      this.setState({ file: event.target.files[0] });
      this.setState({ isError6: '' });
    } else {
      this.setState({ isError6: 'Maximum file size 4mb' });
    }
  }
  onNumberChange(event) {
    const re = /^[0-9\b]+$/;
    const number = event.target.value;
    let errors = this.state.errors;
    if (event.target.value === '') {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    }
    if (event.target.value === '' || re.test(event.target.value)) {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    } else {
      errors.number = 'Enter Only Integer Number';
      this.setState({ errors });
    }

    if (event.target.value.length > 0) {
      if(event.target.value.length >= 10){
        errors.number = '';
        this.setState({ errors });
        this.setState({ number: event.target.value, errors: errors });
      }else {
      errors.number = 'Enter Min 10 Digit Number';
      this.setState({ errors: errors });
    }
  }
  }
  onMsgChange(event) {
    let errors = this.state.errors;
    if (event.target.value) {
      errors.msg = '';
      this.setState({ msg: event.target.value, errors: errors });
    } else {
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({ errors: errors, msg: event.target.value });
    }
  }
  resetForm() {
    this.setState({
      name: '',
      email: '',
      number: '',
      msg: '',
      errors: {
        first: '',
        last: '',
        org: '',
        number: '',
        msg: '',
      },
    });
  }

  validateForm = () => {
    let valid = true;
    let errors = this.state.errors;
    if (!this.state.firstname) {
      valid = false;
      errors.first = 'First name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.lastname) {
      valid = false;
      errors.last = 'Last name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.email) {
      valid = false;
      errors.email = 'Email is mandatory';
      this.setState({
        errors,
      });
    } else {
      if (!ValidateBussinessEmail(this.state.email)) {
        valid = false;
        errors.email = 'Please valid business email address';
        this.setState({
          errors,
        });
      }
    }
    if (!this.state.msg) {
      valid = false;
      errors.msg = 'Message is mandatory';
      this.setState({
        errors,
      });
    } else if (this.state.msg && this.state.msg.length <= 15) {
      valid = false;
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({
        errors,
      });
    }
    if (!this.state.organisation) {
      valid = false;
      errors.org = 'Organisation is mandatory';
      this.setState({
        errors,
      });
    }
    return valid;
  };

  handleSubmit(e) {
    if (this.validateForm()) {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('firstname', this.state.firstname);
      formData.append('lastname', this.state.lastname);
      formData.append('email', this.state.email);
      formData.append('number', this.state.number);
      formData.append('organisation', this.state.organisation);
      formData.append('project', this.state.project.value);
      formData.append('msg', this.state.msg);
      formData.append('countryname', this.state.countryName);
      formData.append('countrycode', this.state.countryCode);
      formData.append('enquiry_type', 'quote');
      let country = localStorage.getItem("country_code");
      formData.append("country", country);
      Axios.post(getHostUrl('/api/user/sendquote'), formData, { // receive two parameter endpoint url ,form data
        }).then((response)=>{
          console.log(response.success);
          if (response.status ==200){
            this.setState({isSubmitted: true});
              this.resetForm();
          }else{
            alert("Message failed to send.")
          }
        })
    }
  }
}

export default RequestQuote;
