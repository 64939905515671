import React from 'react';

function Counter() {
  return (
    <div>
      {/* Start Company Widget Area */}
      <section className="fun-facts-area pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <h3>
                  {" "}
                  <i className="flaticon-clock" />{" "}
                  <span className="odometer" data-count={7}>
                    00
                  </span>
                  <span className="sign-icon">+</span>
                </h3>
                <p>Years of experience</p>
                <div className="back-text">E</div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <h3>
                  <span className="odometer" data-count={42}>
                    00
                  </span>
                  <span className="sign-icon">+</span>
                </h3>
                <p>Projects completed</p>
                <div className="back-text">P</div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <h3>
                  <span className="odometer" data-count={115}>
                    00
                  </span>
                  <span className="sign-icon">+</span>
                </h3>
                <p>Dedicated QA professionals</p>
                <div className="back-text">QA</div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-fun-facts">
                <h3>
                  <span className="odometer" data-count={10}>
                    00
                  </span>
                  <span className="sign-icon">+</span>
                </h3>
                <p>Countries active involvement</p>
                <div className="back-text">C</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Company Widget Area */}
    </div>
  );
}

export default Counter;
