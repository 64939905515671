import React from "react";

import { Link } from "react-router-dom";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";
function Banner() {
  return (
    <div>
      {/* Start Main Banner Area */}
      <div className="hero-banner banner-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="hero-main-banner-content">
                    <span className="sub-title">
                      Quality From Start to Finish and Beyond
                    </span>
                    <h1>Empower Software Quality with Reliable Testing</h1>
                    <p>
                      TestDel is a software testing company that helps
                      businesses outsource their quality assurance activities.
                      Our independent QA and research services remove obstacles
                      to releasing great software, ensuring it meets required
                      quality standards. With expertise in automation testing,
                      we deliver fast, reliable results that allow clients to
                      focus on other aspects of their business.{" "}
                    </p>
                    <a href="contact-us" className="btn default-btn btn-radius" Style="margin-bottom:10px">
                      Contact Us
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <img src={`assets/img/Banner.png`} className="wow" Style="margin-top: 85px;" alt="man"/>
                  <div className="hero-main-banner-image">
                    {/*<img src={`assets/img/banner-image/man.png`} className="wow fadeInDown" data-wow-delay="0.6s" alt="man" />
                      <img src={`assets/img/banner-image/code.png`} className="wow fadeInUp" data-wow-delay="0.6s" alt="code" />
                      <img src={`assets/img/banner-image/carpet.png`} className="wow fadeInLeft" data-wow-delay="0.6s" alt="carpet" />
                      <img src={`assets/img/banner-image/bin.png`} className="wow zoomIn" data-wow-delay="0.6s" alt="bin" />
                      <img src={`assets/img/banner-image/book.png`} className="wow bounceIn" data-wow-delay="0.6s" alt="book" />
                      <img src={`assets/img/banner-image/desktop.png`} className="wow fadeInDown" data-wow-delay="0.6s" alt="desktop" />
                      <img src={`assets/img/banner-image/dot.png`} className="wow zoomIn" data-wow-delay="0.6s" alt="dot" />
                      <img src={`assets/img/banner-image/flower-top-big.png`} className="wow fadeInUp" data-wow-delay="0.6s" alt="flower-top-big" />
                      <img src={`assets/img/banner-image/flower-top.png`} className="wow rotateIn" data-wow-delay="0.6s" alt="flower-top" />
                      <img src={`assets/img/banner-image/keyboard.png`} className="wow fadeInUp" data-wow-delay="0.6s" alt="keyboard" />
                      <img src={`assets/img/banner-image/pen.png`} className="wow zoomIn" data-wow-delay="0.6s" alt="pen" />
                      <img src={`assets/img/banner-image/table.png`} className="wow zoomIn" data-wow-delay="0.6s" alt="table" />
                      <img src={`assets/img/banner-image/tea-cup.png`} className="wow fadeInLeft" data-wow-delay="0.6s" alt="tea-cup" />
                      <img src={`assets/img/banner-image/headphone.png`} className="wow rollIn" data-wow-delay="0.6s" alt="headphone" />
  <img src={`assets/img/banner-image/main-pic.png`} className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img1">
          <img
            src={`assets/img/shape/1.png`}
            className="wow fadeInUp"
            data-wow-delay=".9s"
            alt="image"
          />
        </div>
        <div className="shape-img2">
          <img src="assets/img/shape/2.svg" alt="image" />
        </div>
        <div className="shape-img3">
          <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img6">
          <img src={`assets/img/shape/6.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Main Banner Area */}
    </div>
  );
}

export default Banner;
