import React from 'react';

import MetaTags from '../components/MetaTags';
import Counter from '../components/Counter';
import ContactForm from '../components/ContactForm';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Testing Delivery and Consultancy Service";
const Description="Testing Delivery and Consultancy Service";
function QAConsulting() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
        {/* Start Page Title Area */}
        <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>QA Consulting</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li> <a href="what-we-offer">What We Offer</a></li>
                  <li>QA Consulting</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <section className="overview-area ptb-100">
        <div className="container">
          <div className="overview-box it-overview">
            <div className="overview-content">
              <div className="content">
                <h2>Software QA Consultancy that Specialises In Functional ,Performance And Automated Testing</h2>
                <p>
                TestDel offers QA consulting services by reviewing research and development processes, assessing team readiness to test and identifying product defects to ensure that the chosen QA approach meets quality criteria and meets the requirements of industry.
                </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      	Testing Process Assessment
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Enhance competence
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      	General Consulting	
                                          </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Well-formed documentation
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Coaching
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="bx bxs-badge-check" />
                      Apply new QA methodologies
                    </span>
                  </li>
                </ul>
                <a
                  className="default-btn"
                  data-toggle="collapse"
                  href="#morecontent"
                >
                  Read More <span />
                </a>
              </div>
            </div>
            <div className="overview-image">
              <div className="image">
                <img
                  src={`assets/img/what-we-offer/qa-consulting.png`}
                  alt="image"
                />
              </div>
            </div>
            <div class="collapse" id="morecontent">
              <div class="card card-body">
                <p>
                We offer flexible professional software testing services that can be combined and configured to meet big programmes or small projects demands. These services can be delivered in a number of ways, from consultancy to managed service, on demand to the tool or test environment provision.  </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-area ptb-100 gray-bg">
        <div className="container">
          <div className="section-title">
            <h2>We Work With You To:</h2>
            <p>A systematic approach with new ways of operating, including using automated test procedures, shift-left strategies for earlier discovering bugs and tools for automating and virtualizing test execution.</p>
            </div>
        <div className="row mt-5">
                  <div className="col-lg-4 col-md-6">
                  <div className="content-box">
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Conduct a confidential process
                   </h4>
                    <p className="accordion-content">Involving two or three clients and TestDel senior leaders to understand your “As is and To be”
future state approach to transforming strategies.</p>
                  </div>
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Provide senior leadership POC
                   </h4>
                    <p className="accordion-content">Covering process, coverage, deliverables, executions plan, critical gaps, and road map with recommended action items to increase your test capability in line with industry.</p>
                  </div>
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Test management service
                   </h4>
                    <p className="accordion-content">Providing a dedicated expert to lead and deliver all aspects of test management on your programme from initiation till Go Live.</p>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6">
          
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Delivery of test cases
                   </h4>
                    <p className="accordion-content">We design test cases aimed to verify particular system aspects by following certain steps. On the basis of actual and expected results.</p>
                  </div>
                 
              
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Key geographical location
                      </h4>
                    <p className="accordion-content">Ensure TestDel QA team is deployed to support most locations globally.</p>
                  </div>
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Ensure TestDel QA team is deployed to support most locations
                   </h4>
                    <p className="accordion-content">Which supports change programme and the safe migration of new functionality through to live operations.</p>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
           
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Risk-based test strategies
                   </h4>
                    <p className="accordion-content">Will be designed as per risk factors, dependencies, business processes complexity, priority and impact.</p>
                  </div>
                  <div className="content-box">
                  
                      <h4> <i class="bx bxs-badge-check"></i> 
                      Report overall performance
                   </h4>
                    <p className="accordion-content">Against KPI, defects/change requests reports and daily test summary reports.</p>
                  </div>
            </div>
          </div>
        </div>
      </section>
     
    <section className="choose-area-two pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/what-we-offer/Functional-Test-Delivery.png`}  alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2>Functional Testing</h2>
                </div>
                <p>Functional test delivery ensures that an application is tested against its requirements specifications. It ensures a degree of quality of the application to enable informed decisions on the sustainability of its release to production.</p>
                  <p>Six out of every ten IT projects fail initially, largely due to poor testing during development – Gartner</p>
                  <a href="functional-testing" class="default-btn">Read More</a>
              
               </div>
            </div>
            
          </div>
        </div>
      </section>
     
      <section className="choose-area-two gray-bg pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
         
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2>Non Functional Testing</h2>
                </div>
              <p>We offer designing, engineering and delivering cutting edge shared services to implement a common standard and reusable framework leveraging industry best practices for performance engineering and testing. Non-functional tests are performed to verifies the attributes of the system such as memory leaks, performance or robustness of the system. TestDel provides services that proactively ensure IT solutions are robust, scalable, predictable and meet future business usage and throughout demand.</p>
                  <a href="non-functional-testing" class="default-btn">Read More</a>
              
               </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/what-we-offer/Non-Functional-Testing.png`}  alt="image" />
              </div>
            </div>
            
          </div>
        </div>
      </section>
     <Counter/>
     <ContactForm/>
       
     </div>
  );
}

export default QAConsulting;
