import React from 'react';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Test Automation";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function TestAutomation() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
       {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Test Automation</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="case-study">Use Cases</a>
                  </li>
                  <li>Test Automation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`} alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </div>
      {/* End Page Title Area */}
         {/* Start Project Details Area */}
         <section className="project-details-area ptb-100">
        <div className="container">
          <div className="row">
          
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work5.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work5_1.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="projects-details-desc">
                <h2>Client </h2>
              <p>We have been approached by a software house based in Italy to engage software testing of their client resource management tool.</p>
<p>The application helps to track resources skills, utilization, available capacity and the demands being made upon resources.</p>
<p>This resource planning software lets build and share resource plans which enable to forecast accurately resource requirements, identify shortfalls and drive recruitment plan. The language used in the application was JavaScript, Java and C# languages.</p>
<p>The application needed to be tested in multi-language adopting multiple distributed team practices to assure effective team communication worldwide.</p>
<div className="text-center mb-5 p-3 border gray-bg ">
<h3 >Got a project in mind?</h3>
<p>There is no better place than TestDel to get a QA solution. 
Find out what our team can do for you and drop us a line. 
</p>
<a href="get-a-free-quote" className="btn mt-0 default-btn">Get Quote</a>
</div>

<p>When there are defects in the Sharepoint, the business process can’t be executed. That’s why SharePoint testing  is critically important.</p>
<h2>Technical challenges with implementation of the test procedure.</h2>
<p>The main objective of the project to ensure complex HR software works as planned. The application comes with three module. </p>
<ul  Style="list-style:decimal;">
  <p><li> Dynamic Hiring and Onboarding, is a cloud-based service designed to simplify hiring and onboarding processes.</li>
<li> Time & Attendance, is an insightful time management and scheduling device designed to construct and control employee schedules, shifts and overtime expenses. </li>
<li> Compensation Management, is a system that helps businesses handle the Incentive and Performance Rewards effectively.</li>
</p></ul>
<p>End client also wanted us to verify and validate growing IT landscape by mitigating functional risk, increasing operational efficiency, and enabling 99% system reliability.</p>
<p><strong>We identify three major challenges in the project </strong></p>

                <div className="features-text">
                  <p><i className="flaticon-tick" />	Fast changes to custom product / newer versions / specified end-user solutions.  </p>
                </div>
                <div className="features-text">
                  <p><i className="flaticon-tick" />	Post-production customer satisfaction: Error-free solutions; better user interface (UX). </p>
                </div>
                <div className="features-text">
                  <p><i className="flaticon-tick" />	Present compatibility. The only way to getting solutions to any of these problems is automated research. </p>
                </div>

                <h2>Testdel  Solution</h2>
                <p>TestDel extensive experience in automated testing helped our team to develop innovative solutions for certain complex tasks We chose to use Agile as the technique best suited to this situation. Manual and automated testing methods were conducted to cover all customer requirements including practical, regression, smoke, rationality and usability test forms. </p>

               <p>TestDel performs localization testing to verify that the solution content and user interface meet specified users’ language and location. We evaluate application from linguistic and cultural perspectives to make sure that they are suitable for the target region or country.</p>
             <p>By having localization testing conducted, customers receive the international visibility of solutions and are able to provide efficiency to end users from all over the world .</p>
              <p>When clients approach  us, they have large manual regression tests (i.e., 1,200 manual UI regression tests that would take a couple of weeks). We help them reduce cycle time while increasing test coverage by replacing manual testing with automated.</p>
            <p>Our team used the languages JavaScript, Java and C # during the realisation of the project. Our team applied various tools like Kali , Selenium,HP mobile centre and Google NoGoToFail services to ensure correct detection of all the holes, defects and missing specifications. We were also working on creating a reliable and balanced testing process in Jira. We created requirement traceability metrics to prove that full test coverage is done by implementing maping between Bug>test case> requirements. </p>
            <h5>Types of testing performed:</h5>
            <p><ul>
              <li>	Manual Functional Testing </li>
              <li>	Automated testing for Load and performance</li>
              <li>	Security Testing</li>
              <li>	Regression Testing</li>
              <li>	Localization Testing</li>
              <li>	Usability testing </li>

            </ul>
            </p>

            <h2>Results Achieved </h2>
             <p>We give clients confidence through our customer-specific testing function. We also helped them significantly reduce the time to deliver new features and improvements. We successfully checked the necessary HR software functionalities that allowed the customers to release their first version. We are continuing the testing to refine the product. Our Automation strategy Increase the ability for time-to-market and market proficiency. We also tested interfaces, which was not possible to verify manually. </p>
                <div className="project-details-info">
                  <div className="single-info-box">
                    <h4>Client</h4>
                    <span>Software house      </span>
                  </div>
                  <div className="single-info-box">
                    <h4>Category</h4>
                    <span>HR</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Date</h4>
                    <span>2019</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Share</h4>
                    <ul className="social">
                      <li><a href="#" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Project Details Area */}
</div>
  );
}

export default TestAutomation;
