import React from 'react';

import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Test Data Management";
const Description="Test Data Management";
function TestDataManagement() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
         {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>Test Data Management</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`}  alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </div>
      {/* End Page Title Area */}
      <section className="choose-area-two  gray-bg  pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/what-we-offer/data-managment.png`}  alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2>Testdel  test data management (tdm) service addresses this key challenge of right data prepration </h2>
                </div>
              <p>The growth of new software development models like the agile development process has given software development companies powerful tools for responding to events quickly and has helped projects evolve through collaboration between self-organizing, cross-functional teams. To make the most of agile processes, program need an effective and efficient testing approach for governing test data. Many developments, testing, and quality assurance (QA) teams struggle to identify, create and maintain the required test data. Projects often lack confidence in test data preparation and data usage within the testing domain, and it may not be clear who will create the data and how to use, administer it efficiently. That is where TestDel data management services come in.</p>
               </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className="offer overview-area pb-5 pt-5">
        <div className="container">
        <div className="overview-box">
        <div className="text-center">
        <h2>Data Management</h2>
                <p>Test data management is the process of creating realistic test data for non-production purposes such as development, testing, training or QA.
Research shows that projects are not successful due to a lack of data quality. We design test data management strategy not only to ensure greater development and testing efficiencies but helps projects to identify and correct defects early in the development process when they are cheapest and easiest to fix.</p>
       </div>
            <div className="overview-content1 pt-3">
              <div className="content right-content">
                <ul class="features-list">
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                       
Test data preparation
                                    
                                    <p>Involves the identification and production of data by cloning or subsetting data from the live environment or by developing test data generation scripts and provisioning them for multiple testing environments.</p>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                        Discovering
                                        <p>We know that Data is scattered across systems and sits in different formats. In addition, different rules may be applied to data depending on its type and location.</p>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                        Subset production data
                                        <p>We design subsetting to ensure realistic, referentially intact test data from across a distributed data landscape without added costs or administrative burden.</p>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                        Mask or de-identify
                                        <p>We help the project in masking production data to secure sensitive data especially, client and employee information. We de-identifying confidential data to ensure a realistic look and feel to complete business objects, such as customer orders, across test systems.</p>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                        Refresh test data
                                        <p>During the testing process, test data often diverge from the baseline, resulting in a less than optimal test environment but we recommend refreshing test data which can improve testing efficiencies. We help the projects in preparing Synthetic Data generation for testing new functionality.</p>
                                    </span>
                                   
                                </li>
                                <li>
                                    <span>
                                        <i class="bx bxs-badge-check"></i>
                                        Automate test data
                                        <p>The ability to identify data anomalies and inconsistencies during testing is essential to the overall quality of the application. The only way to truly achieve this goal is to deploy an automated capability for comparing the baseline test data against results from successive test runs.</p>
                                    </span>
                                    
                                </li>
                            </ul>

              </div>
            </div>
            
          
          </div>
          
        </div>
      </section>
     
     </div>
  );
}

export default TestDataManagement;
