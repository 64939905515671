import axios from "axios"
import React, { useState } from "react"
import { ValidateEmail } from "../utils/functions"
import { useNavigate } from "react-router-dom";
import { getHostUrl } from "../config";

export default function (props) {
    const[email,setEmail] = useState('')
    const[password,setPassword] = useState('')
    const navigate = useNavigate();
    const[errors,setErrors] = useState({
        email:'',
        password:''
    })

    const onEmailChange =(event) =>{ 
        errors.email = ValidateEmail(event.target.value)
          ? ''
          : 'Please enter your valid email address';
          if(event.target.value){
            errors.email = ''
        }
        setErrors(errors)
        setEmail(event.target.value)
    }
    
    const onPassChange =(event)=> {
        if(event.target.value){
            errors.password = ''
        }
        setErrors(errors)
        setPassword(event.target.value)
    }
    const validateForm = () => {
        let error= {}
        let valid = true;
        if (!email) {
          valid = false;
          error.email = 'Email is mandatory';
          setErrors(error)
        } else {
          if (!ValidateEmail(email)) {
            valid = false;
            error.email = 'Enter valid email';
            setErrors(error)
          }
        }
        if (!password) {
            valid = false;
            error.password = 'Password is mandatory';
            setErrors(error)
          } 
        
      
        return valid;
      };
    
     const handleSubmit =() => {
        if (validateForm()) {
        //   e.preventDefault();
          const formData ={
            email:email,
            password:password
          }
    
          axios.post(getHostUrl('/api/user/user-login'), formData, {
          }).then((response)=>{
          console.log(response.data.success);
    
          if (response.status ==200 && response.data.success){
            localStorage.setItem('admin', JSON.stringify(response.data.token));
            navigate("/admin/home");
            window.location.reload(true);
          }else{
            alert(response.data.error)
          }
          })
        }
      }    

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={(e)=>onEmailChange(e)}
            />
            <small className="text-danger">
            {errors.email}
            </small>
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={(e)=>onPassChange(e)}
            />
         <small className="text-danger">
            {errors.password}
            </small>   
          </div>
          <div className="d-grid gap-2 mt-3">
            <div
                className="default-btn company-logo-color col-md-4"
                onClick={()=>handleSubmit()}
                >
                Send
                <span />
              </div>
          </div>
        </div>
      </form>
    </div>
  )
}