import React from 'react';
import MetaTags from '../components/MetaTags';
const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Web Application";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
function WebapplicationTesting() {
  return (
    <div>
        <MetaTags title={Title} description={Description}/>
       {/* Start Page Title Area */}
       <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Web Application Testing</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="case-study">Use Cases</a>
                  </li>
                  <li>Web Application Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2"><img src={`assets/img/shape/2.svg`} alt="image" /></div>
        <div className="shape-img3"><img src={`assets/img/shape/3.svg`}  alt="image" /></div>
        <div className="shape-img4"><img src={`assets/img/shape/4.png`}  alt="image" /></div>
        <div className="shape-img5"><img src={`assets/img/shape/5.png`}  alt="image" /></div>
        <div className="shape-img7"><img src={`assets/img/shape/7.png`}  alt="image" /></div>
        <div className="shape-img8"><img src={`assets/img/shape/8.png`}  alt="image" /></div>
        <div className="shape-img9"><img src={`assets/img/shape/9.png`}  alt="image" /></div>
        <div className="shape-img10"><img src={`assets/img/shape/10.png`}  alt="image" /></div>
      </div>
      {/* End Page Title Area */}
   {/* Start Project Details Area */}
   <section className="project-details-area ptb-100">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work1.png`} alt="image" />
               
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="project-details-image">
              <img src={`assets/img/works/work1_1.png`} alt="image" />
               
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="projects-details-desc">
                <h2>Client </h2>
                <p>The Client is the largest Mutual fund partner in India to provide Investment options online. The client has a web application that allows users to create a user profile and to add financial details. The application suggests investment options to users on allocated money on savings.

The client came to TestDel for guidance on how to overtake the testing of a complex ERP that was being developed by a famous IT consultancy. The project was working to a tight deadline . The ERP system would be used by both in house teams and partner organizations. It is a crucial component to both the service offering and the client revenue collection. The project was partially through when the client approached TestDel , and the software had proven to be very complicated and they had experienced an unexpectedly high number of issues in the first two releases.
</p>
<p>The client was migrating from a thick client application to the Cloud application, a daunting task that had never been performed on such a scale by the client. Furthermore, <strong>the client had very little expertise in testing </strong>this kind of project and integration.it is important for <strong>ERP to be reliable, scalable, maintainable, and highly secure,</strong> particularly as these systems are used globally, under changing business needs with budgets and aggressive schedules.</p>
<div className="text-center mb-5 p-3 border gray-bg ">
<h3 >Got a project in mind?</h3>
<p>There is no better place than TestDel to get a QA solution. 
Find out what our team can do for you and drop us a line. 
</p>
<a href="get-a-free-quote" className="btn mt-0 default-btn">Get Quote</a>
</div>
<h2>Technical challenges with implementation of the test procedure.</h2>
                  <p>In recent year management company is experiencing rapid international expansion for which they decided to  renewing its current e-commerce platform. Various new features were also introduced in new portal .This combination has increased the size and scope of the network to keep up with the changing demands of customers and the latest technological developments. Through a mix of modern integrated frameworks and services that communicate with each other, the team agreed to go live before the season. Over the course of time, more and more functionalities have been introduced into the modern e-commerce platform and testing has become a challenge.
There was not enough resources available in the organisation to perform comprehensive qualitative end-user analysis. There were number of specialists and resources needed in the research area to coordinate high-quality testing structurally for such a large-scale project. Requirements were constituently changing and developers were struggling to test impact due to growing scope and tight timeline. Organisation also wanted to preserve and guarantee that level of quality by introduction of our new product to give the best solution to end-users.
</p>
<h2>Testdel Solution</h2>
               
                <div className="features-text">
                  <p><strong>The client was looking for a well diverse testing team that can thoroughly validate the functionality and usability of the application involving many third-party APIs and integrations.  </strong></p>
               <p>The application has a real-time feed generation and notification setup. Also, the process to validate the user’s personal and financial information not compromised off was a profound requirement. Suggesting an investment plan and return calculation on the basis of amount and time was a core testing aspect. 
The client was looking for a testing team that understands the application quickly and that can work closely with the development team on a regular basis. They wanted to run the integration tests on different environments (Operating Systems - Browsers) and report the bugs within a given timeline. 
</p>
                </div>
                  
                <div className="features-text">
                  <p>Testing an ERP application offers many difficulties, such as complex interfaces, data transformation, configurations, and peripheral issues. To address these concerns, TestDel quickly mobilised resources, led by a Senior Test Manager and formed a dedicated on-shore team and a large off-shore team. In order to achieve the goals within the given time frame, it was important that a clear and pragmatic test strategy was created.</p>
             <p>The solution has been incorporated as ongoing managed testing service, with constant effort as additional business needs change for the client. Including back and front office integration with the ERP system, TestDel team also planned and executed automated and performance testing, with support installation and rollout. Along with an experienced on-shore Test Manager, Testdel off-shore team were engaged on the project to meet test volume requirements and budget restrictions.</p>
             <p><strong>The solution comprised of deliverables from the TestDel on-shore and off-shore teams including:</strong></p>
     <ul>
       <li>	Test Stretegy</li> 
       <li>Test plans</li>
       <li>	Test entry and exit criteria</li>
       <li>	Defect Triage and management</li>
       <li>	Exploratory testing</li>
       <li>Scripted testing</li>
       <li>Daily reports</li>
       <li>Test exit reports</li>
       </ul>
     
       <p>The test team worked closely with the on-site Business Analysts and Technical Architects as well as the development team to ensure clear communication and efficiency in delivery.</p>
<p>Following a fast and efficient test process, team planned to meet with Government guidelines .
The testing was carried in an agile-scrum development methodology with regular stand-up. The test environment was created with different combinations of OS’s: Windows and Mac; browsers: Google Chrome, IE, Mozilla Firefox. Testing was also undertaken on smartphones and iPad with different screen resolutions.</p>
      <ul Style="list-style:none; padding:0;">
        <li><p>1.	Creating detailed test cases of all positive and negative scenarios; based on screens and documented requirements</p></li>
        <li><p>2.	Create the required OS - browser test environment and database testbed.</p></li>
        <li><p>3.	Validate the builds on the test cases in different environments.</p></li>
        <li><p>4.	Validate the saved data integrity - database testing and api testing.</p></li>
        <li><p>5.	Logging defects and categories them on priority </p></li>
        <li><p>6.	Escalated the re-opened issues to the Product team immediately so that the quality of the product can be ensured at the development level</p></li>
        <li><p>7.	Sharing the test results on a weekly basis.</p></li>
      </ul>
       </div>
       <h2> Results Achieved </h2>
              
                <div className="features-text">
                     <h5><i className="flaticon-tick" /> How TestDel Assisted The Client</h5>
                  <p>In order to assist our client meet their quality needs, Tetdel team followed these steps:</p>
                <ul Style="list-style:none; padding:0;">
                    <li> <p>1. Our team created detailed test cases well in advance covering most of the scenarios.</p> </li>
            <li><p>2.	Our test engineers work very closely with the product owners to understand end-user requirements and deliver the product bug-free.</p></li>
            <li><p>3. We performed end-to-end testing for the web application and regressions testing on staging servers. </p></li>
            <li><p>4.	The project team created a customized bug tracking mechanism to provide the client with daily updates and project status.</p></li>
            <li><p>5.	We shared a consolidated test result summary which provided the overall coverage of test scenarios and compatibility matrix with test results.</p></li>
            <li><p>	6. We published the requirement traceability matrix before releasing a build on production going live. </p></li>
            <li><p>	7. We did the retrospective meeting after the release and improved upon the mishaps in the testing process, and plugged the gaps to improve on delivery.

</p></li>

            
                </ul>
                <p>The solution built allows one-click deployment of the automated test instrument and all necessary components from scratch. A customer side specialist selects the appropriate tests and test environment in the web interface, then they start the test run. All tests are built via the graphical user interface, and modified. The specific portion of each test data is automatically generated. Earlier the customer had to manually update the test data for each run which needed several hours of manual work. </p>
                </div>
                <div className="project-details-info">
                  <div className="single-info-box">
                    <h4>Client</h4>
                    <span>Mutual Fund     </span>
                  </div>
                  <div className="single-info-box">
                    <h4>Category</h4>
                    <span>Finance</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Date</h4>
                    <span>2017</span>
                  </div>
                  <div className="single-info-box">
                    <h4>Share</h4>
                    <ul className="social">
                      <li><a href="#" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Project Details Area */}
     </div>
  );
}

export default WebapplicationTesting;
