import React from "react";
import Counter from "../components/Counter";
import Team from "../components/Team";
import Partners from "../components/Partners";
import MetaTags from "../components/MetaTags";
import ContactForm from "../components/ContactForm";
import HowToContactForm from "../components/HowToContactForm";
const imageFormat =
  localStorage.getItem("avifSupported") === "YES" ? "avif" : "png";
const Title = "How It Work";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";

const HowItWork = () => {
  return (
    <div>
      <MetaTags title={Title} description={Description} />
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h1>How It Work</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>How It Work</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`../assets/img/shape/2.svg`} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`../assets/img/shape/3.svg`} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`} alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}

      {/* About Us */}
      <section className="choose-area-two pb-5">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image">
                <img src={`assets/img/How-It-Work.png`} alt="image" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="choose-content">
                <div className="section-title text-left">
                  <h2 className="text-left">How It Work</h2>
                </div>
                <p Style="max-width:600px">
                  Welcome to Testdel, where we specialize in comprehensive
                  software testing solutions tailored to meet the unique needs
                  of your projects. Our streamlined approach ensures that your
                  software applications are thoroughly tested for functionality,
                  performance, security, and usability, allowing you to deliver
                  superior products to your customers.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End About Us */}
      {/* Start Solution Area */}
      <section
        className="vision-area ptb-100 extra-pb jarallax"
        data-jarallax='{"speed": 0.3}'
        id="ourvision"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="solution-content text-center">
                <h2>Our Vision</h2>
                <p>
                  To contribute positively in addressing and resolving the
                  quality assurance challenges, we are a team that balances
                  experience, expertise, creativity, and innovation. By
                  providing professional QA services, we help businesses and
                  organisations to earn reputation and product reliability.
                </p>
                <a href="contact-us" className="default-btn">
                  Contact Us <span />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Solution Area */}
      <section className="overview-area pt-5 pb-5">
        <div className="container">
          <div Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:350px">
                <img src={`assets/img/Discover.png`} alt="image" />
              </div>
            </div>
            <div Style="max-width:600px">
              <h2>Discover </h2>
              <p>
                In the initial phase of our engagement, we work closely with you
                to understand your project requirements, objectives, and
                constraints. Whether you're launching a new software application
                or updating an existing one, our team collaborates with you to
                define the scope of testing and establish clear goals for the
                project.
              </p>
            </div>
          </div>
          <div
            Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px"
            className="flex-revers"
          >
            <div Style="max-width:620px">
              <h2>Plan</h2>
              <p>
                With a solid understanding of your project requirements, our
                experienced test managers develop a customized testing strategy
                tailored to your specific needs. We leverage our expertise in a
                wide range of testing methodologies and technologies to design a
                comprehensive testing plan that ensures thorough coverage and
                maximum effectiveness.
              </p>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:400px">
                <img src={`assets/img/Plan.png`} alt="image" />
              </div>
            </div>
          </div>
          <div Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:300px">
                <img
                  src={`assets/img/Execute.png`}
                  alt="image"
                  Style="object-fit: contain;"
                />
              </div>
            </div>
            <div Style="max-width:600px">
              <h2>Execute</h2>
              <p>
                Once the testing plan is in place, our dedicated team of testers
                springs into action, executing test cases, scripts, and
                scenarios meticulously. Whether it's automated testing, manual
                testing, or a combination of both, we strive for accuracy,
                efficiency, and thoroughness in every testing phase.
              </p>
            </div>
          </div>
          <div
            Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px"
            className="flex-revers"
          >
            <div Style="max-width:600px">
              <h2>Analyze</h2>
              <p>
                As testing progresses, we monitor results closely, identifying
                defects, anomalies, and areas for improvement. Our team conducts
                in-depth analysis and root cause investigations to understand
                the underlying issues and provide actionable insights for
                remediation.
              </p>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:350px">
                <img src={`assets/img/Analyze.png`} alt="image" />
              </div>
            </div>
          </div>
          <div Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:400px">
                <img src={`assets/img/Report.png`} alt="image" />
              </div>
            </div>
            <div Style="max-width:600px">
              <h2>Report</h2>
              <p>
                Transparency and communication are key pillars of our approach.
                Throughout the testing process, we provide detailed reports and
                updates, keeping you informed of progress, findings, and
                recommendations. Our reports are clear, concise, and actionable,
                empowering you to make informed decisions about your software
                applications.
              </p>
            </div>
          </div>
          <div
            Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px"
            className="flex-revers"
          >
            <div Style="max-width:600px">
              <h2>Optimize</h2>
              <p>
                Continuous improvement is at the heart of what we do. After
                completing testing cycles, we collaborate with you to implement
                corrective actions, optimize testing processes, and enhance
                overall software quality. Our goal is not just to find defects
                but to help you build better software products that delight your
                customers.
              </p>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:350px">
                <img src={`assets/img/Optimize.png`} alt="image" />
              </div>
            </div>
          </div>
          <div Style="display:flex; flex-wrap:wrap;justify-content:space-between;margin-top:20px">
            <div className="col-lg-5 col-md-12">
              <div className="choose-image" Style="max-width:400px">
                <img
                  src={`assets/img/Deliver.png`}
                  alt="image"
                  Style="object-fit: contain;"
                />
              </div>
            </div>
            <div Style="max-width:600px">
              <h2>Deliver</h2>
              <p>
                With testing complete and issues resolved, we deliver your
                software applications with confidence. Whether it's a web
                application, mobile app, IoT device, or blockchain solution, you
                can rest assured that your software has undergone rigorous
                testing and is ready to meet the demands of your users.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Counter />

      <section className="service-facts-area  pt-5 pb-5">
        <div className="container">
          <div className="section-title">
            <h2 className="mb-3">Why Testdel?</h2>
          </div>
          <div
            className="row"
            Style="display: flex; flex-direction: column; align-items: center; justify-content: center"
          >
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">1</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Expertise</a>
                  </h3>
                  <p>
                    Our team comprises experienced testers with deep domain
                    knowledge across various industries and technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">2</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Customization</a>
                  </h3>
                  <p>
                    We tailor our testing solutions to fit your project
                    requirements, ensuring maximum value and effectiveness.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">3</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Quality Assurance</a>
                  </h3>
                  <p>
                    With a focus on quality, we help you deliver software
                    applications that are robust, reliable, and user-friendly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">4</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Communication</a>
                  </h3>
                  <p>
                    We believe in open and transparent communication, keeping
                    you informed at every step of the testing process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">5</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">Continuous Improvement</a>
                  </h3>
                  <p>
                    We are committed to continuous learning and improvement,
                    staying abreast of the latest trends and technologies in
                    software testing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-6" Style="max-width:1000px">
              <div className="service-card-one row bg-white">
                <div className="col-md-1">
                  <h1 Style="color:#00f">6</h1>
                </div>
                <div className="col-md-11">
                  <h3>
                    <a href="#">At Testdel</a>
                  </h3>
                  <p>
                    we are dedicated to helping you achieve your software
                    testing goals. Contact us today to learn more about how our
                    comprehensive testing services can drive the success of your
                    projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="section-title">
            <h2 className="mb-3">Your 9 Steps to Success with Testdel</h2>
            <p>
              At Testdel, we believe in efficiency and effectiveness. Our
              streamlined process ensures that you quickly transition from
              scoping your project to achieving success with your software.{" "}
            </p>
          </div>
          <h3>Scoping </h3>
          <div Style="max-width:900px;padding-top:10px;padding-left:20px">
            <div Style="max-width:800px;">
              <div Style="display:flex; width:100%">
                <h5 Style="margin-right:20px">1 Intro and Review Call</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Review"
                >
                  Read more
                  <span />
                </a>
              </div>

              <ul class="collapse" id="Review">
                <li>
                  <p>Duration: Up to 1 hour</p>
                </li>
                <li>
                  <p>
                    We begin with an introductory call where we discuss your
                    project requirements and identify potential solutions.
                  </p>
                </li>
              </ul>
              <div Style="display:flex; width:100%">
                <h5 Style="margin-right:20px">2 Service Proposal</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Service"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Service">
                <li>
                  <p>Delivered within 24 hours</p>
                </li>
                <li>
                  <p>
                    Within just one day, we provide you with a comprehensive
                    service proposal and a quote for our services.
                  </p>
                </li>
              </ul>
              <div Style="display:flex; width:100%">
                <h5 Style="margin-right:20px">3 Statement of Work (SoW)</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Statement"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Statement">
                <li>
                  <p>Delivered within 24 hours</p>
                </li>
                <li>
                  <p>
                    We draft a detailed Statement of Work outlining the
                    agreed-upon services and pricing.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <h3>Delivery</h3>
          <div Style="max-width:900px;padding-top:10px;padding-left:20px">
            <div Style="max-width:800px">
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">4 Scope Call</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Scope"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Scope">
                <li>
                  <p>Duration: 1 hour</p>
                </li>
                <li>
                  <p>
                    You meet with a Testdel QA Manager to finalize the details
                    using a Test Plan. Testing is then executed by our skilled
                    tester community.
                  </p>
                </li>
              </ul>
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">5 Project Set-up</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Project"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Project">
                <li>
                  <p>Delivered: 24 hours</p>
                </li>
                <li>
                  <p>
                    We configure the project within the Testdel Portal based on
                    the agreed Test Plan.
                  </p>
                </li>
              </ul>
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">6 Results</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Results"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Results">
                <li>
                  <p>Delivered within 24 hours</p>
                </li>
                <li>
                  <p>
                    Once testing is complete, typically within 2 hours, we
                    provide you with high-level test results and detailed
                    reporting within the Testdel Portal.
                  </p>
                </li>
              </ul>
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">7 Triage Call</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Triage"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Triage">
                <li>
                  <p>Delivered: 1 hours</p>
                </li>
                <li>
                  <p>
                    We schedule a meeting to discuss the testing results and
                    provide guidance on addressing identified issues.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <h3>Fixes</h3>
          <div Style="max-width:900px;padding-top:10px;padding-left:20px">
            <div Style="max-width:800px">
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">8 Fix the Issues</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Fix"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Fix">
                <li>
                  <p>
                    Your team addresses the identified issues based on severity,
                    prioritizing fixes using Testdel's detailed reporting.
                  </p>
                </li>
              </ul>
              <div Style="display:flex;aline-items:center">
                <h5 Style="margin-right:20px">9 Re-test Fixes</h5>
                <a
                  className="text-primary"
                  data-toggle="collapse"
                  href="#Fixes"
                >
                  Read more
                  <span />
                </a>
              </div>
              <ul class="collapse" id="Fixes">
                <li>
                  <p>Duration: 2 hours</p>
                </li>
                <li>
                  <p>
                    Once fixes are implemented, they're reported back to Testdel
                    for retesting, ensuring resolution and closure.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <p Style="display:flex; justify-content:center">
            <p Style="text-align:center; padding:10px 0; max-width:800px">
              With Testdel's efficient process and support, you'll quickly move
              from scoping your project to delivering high-quality software that
              delights your users.{" "}
            </p>
          </p>
        </div>
      </section>
      <div className="pt-5 pb-5">
        <h2 className="text-center">Our Team Served</h2>
        <Partners />
      </div>
      <div className="gray-bg">
        <HowToContactForm />
      </div>
    </div>
  );
};

export default HowItWork;
